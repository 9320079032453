import React from 'react'

import './ApiTimeoutPage.scss'

import noSignalImage from '../../../Pictures/gif/nosignal.gif'
import { Trans } from 'react-i18next'

function ApiTimeoutPage() {
  return (
    <div className="ApiTimeoutPage">
      <div className="ApiTimeoutPage__logo clickable" onClick={_ => window.location = '/'}/>
      <div className="ApiTimeoutPage__image">
        <img alt="api timeout" src={noSignalImage}/>
      </div>
      <div className="ApiTimeoutPage__text l-top">
        <Trans ns="errors">Votre connexion n'est pas stable</Trans>
      </div>
      <button className="blue l-top" onClick={_ => window.location.reload()}><Trans>Rafraîchir</Trans></button>
    </div>
  )
}

export default ApiTimeoutPage