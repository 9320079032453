import React, { Component, Fragment } from 'react';
import { NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import './NavBar.scss';

import { actions } from '../../../redux';

import LoginOrchesterModalContent from '../../Components/Modals/contents/Login/LoginOrchesterModalContent';
import { Trans } from 'react-i18next';

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            home: props.home
        }
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        if (this.state.home)
            window.addEventListener('scroll', this.handleScroll);
        if (this.props.showLogin)
            this.connectModal()
    }

    componentWillUnmount() {
        if (this.state.home)
            window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        this.setState({lastScrollY: window.scrollY});
    }

    isHidden() {
        return (!this.state.home ? false : (this.state.lastScrollY > 570) ? false : true)
    }

    connectModal() {
        if (this.props.isLoggued)
            return this.props.history.push('/profile')
        this.props.showModal(
            <LoginOrchesterModalContent 
                onSubmit={_ => this.props.history.push('/profile')}
                noSignUp
            />
        )
    }

    render() {
        return (
            <div id="NavBar" className={"row " + (this.isHidden() ? "close" : "")}>
                <div className="NavBar_logoContainer">
                    <NavLink exact to="/" className="NavBar_logoContainer__logoWrapper"><div className={`NavBar_logoContainer__logoWrapper__logo ${this.props.isHome ? "home" : ""}`}/></NavLink>
                    <NavLink exact to="/" className="NavBar_logoContainer__mixlabWrapper"><div className="NavBar_logoContainer__mixlabWrapper__mixlab">Mixlab</div></NavLink>
                </div>
                {this.props.isHome && (
                    <Fragment>
                        {/* <NavLink to="/pricing" className="NavBar__pricingLink">
                            Tarifs
                        </NavLink> */}
                        <div className="NavBar__buttons">
                            <NavLink to="/pricing" className="NavBar__buttons__buttonLink newMixlab">
                                <button className="blue NavBar__buttons__buttonLink__button NavBar__buttons__buttonLink__button__trial"><Trans>Essai gratuit</Trans></button>
                            </NavLink>
                            <button className="NavBar__buttons__buttonLink__button NavBar__buttons__buttonLink__button__login" onClick={this.connectModal.bind(this)}><Trans>Connexion</Trans></button>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default connect((state) => ({
    isLoggued: state.user && state.user.isLoggued
}), (dispatch) => ({
    showModal: actions.modal.show.bind(null, dispatch)
}))(withRouter(NavBar));
