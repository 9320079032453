import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import './PasswordForm.scss'

import PasswordForceHelper from '../../../Helpers/Password/PasswordForceHelper'

import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import PasswordForceIndicator from '../../Components/Elements/Password/PasswordForceIndicator'
import { Textfit } from 'react-textfit';
import Translation from '../../../Helpers/Translation'

function PasswordForm(props) {
  const {register, handleSubmit} = useForm()
  const [passwordForce, setPasswordForce] = useState()

  function onChangePassword(element) {
    let force = PasswordForceHelper(element.target.value)
    setPasswordForce(force)
    props.onChange("password", force)
  }

  let userEmail = props.userEmail 
  if (userEmail) {
    userEmail = `${userEmail.slice(0, 30)}${(userEmail[31] && ("...")) || ""}`
  }

  return (
    <form 
      className="PasswordForm"
      onSubmit={handleSubmit((data) => props.onSubmit(data, passwordForce))}
    >
      {(userEmail) && (
        <div className="PasswordForm__userEmail s-bottom">
          <Textfit
           max={14}
          >
            {userEmail}
          </Textfit>
        </div>
      )}
      <input
        name="password"
        ref={register}
        type="password"
        autoComplete="none"
        className="PasswordForm__input"
        placeholder={Translation.shared.t("login_component|Mot de passe")}
        onChange={onChangePassword}
      />
      <PasswordForceIndicator 
        className="s-top l-bottom"
        force={passwordForce}
      />
      <div className={`LoginForm__errors ${props.hasErrors ? "open" : ""}`}>
        {props.errorNode}
      </div>
      {props.isLoading ? (
        <LoadingSpinner 
          size="47px"
          small
        />
      ) : (
        <button className="PasswordForm__button green">{props.isCreate ? Translation.shared.t("login_component|Créer") : Translation.shared.t("Valider")}</button>
      )}
    </form>
  )
}

PasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  isCreate: PropTypes.bool,
  userEmail: PropTypes.string
}

PasswordForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {})
}

export default PasswordForm