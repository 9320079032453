import React from 'react';
import App from './App';
import { hydrate, render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { loadComponents, getState } from "loadable-components";
import moment from 'moment';
import Translation from './Helpers/Translation';

window.snapSaveState = () => getState();

const rootElement = document.getElementById('root');

moment.locale(Translation.getLanguage())

loadComponents()
    .then(() => hydrate(<App />, rootElement))
    .catch(() => render(<App />, rootElement));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
