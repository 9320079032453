import React from 'react'
import {
  Route
} from "react-router-dom";

import ChallengePage from './ChallengePage'
import CatchParam from '../../../Helpers/CatchParam';

const basePath = '/e'

export default [
  <Route key={"event"} exact path={`${basePath}/:id`} render={(route) => 
    <ChallengePage 
      challengeId={route.match && route.match.params && route.match.params.id}
      signup={CatchParam(route, "signup")}
      forgot={CatchParam(route, "forgot")}
      cancel={CatchParam(route, "cancel")}
    />
  }/>
]