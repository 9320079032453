export const actions = {
  show(dispatch, content, width, onHide = (()=>{})) {
      dispatch({
          type: "DISPLAY_DEFAULT_MODAL",
          content,
          width,
          onHide
      });
  },
  hide(dispatch) {
      dispatch({
          type: "HIDE_DEFAULT_MODAL"
      });
  }  
}

export const reducer = (state = {}, action) => {
  let newState = {...state}
  switch (action.type) {
      case "DISPLAY_DEFAULT_MODAL":
          newState.display = true
          newState.content = action.content
          newState.width = action.width
          newState.onHide = action.onHide
          break;
      case "HIDE_DEFAULT_MODAL":
          newState.display = false
          newState.content = null
          newState.width = null
          newState.onHide = null
          break;
      default:
          return newState;
  }
  return newState;
}

export default {
  reducer,
  actions
}