import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import './LoginSocialEvent.scss'

import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import NavBar from '../CreateCommunityForm/NavBar'

import travoltaImage from '../../../Pictures/gif/noPlan.gif'
import { actions } from '../../../redux'

import AdminManager from '../../../Manager/AdminManager'

function LoginSocialEvent(props) {
  const [error, setError] = useState()

  async function exchangingCode(code, state) {
    try {
      let response = await AdminManager.exchangeCode(code, state)
      window.location.href = response.data.redirectURL
    } catch (err) {
      setError(true)
    }
  }

  useEffect(() => {
    exchangingCode(props.code, props.state)
  }, [])

  return (
    <div className="LoginSocialEvent">
      { error ? (
        <Fragment>
          <NavBar />
          <img src={travoltaImage} className="LoginSocialEvent__gif" alt="notfound mixlab"/>
          <div className="LoginSocialEvent__subtext LoginSocialEvent__error l-top">
            Ce lien d'association de réseau social n'est plus valide.
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <LoadingSpinner />
          <div className="LoginSocialEvent__subtext m-top">
            Veuillez patienter pendant l'association du réseau social à votre communauté...
          </div>
        </Fragment>
      )}
    </div>
  )
}

LoginSocialEvent.propTypes = {
  code: PropTypes.string,
  state: PropTypes.string
}

export default connect(null, (dispatch) => ({
  setToken: actions.user.login.bind(null, dispatch)
}))(withRouter(LoginSocialEvent))