import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { actions } from '../../../../../redux'

import RegisterTopModalContent from './RegisterTopModalContent'
import AttendingConfirmedModalContent from './AttendingConfirmedModalContent'
import AgendaModalContent from './AgendaModalContent'

function AttendingOrchesterModalContent(props) {
  const [pageState, setPage] = useState({page: props.page})

  const topElement = <RegisterTopModalContent 
                        challenge={props.challenge} 
                        isValid={!pageState.page || pageState.page === "confirm"}
                      />

  return (
    <div className="AttendingOrchesterModalContent">
      { (pageState.page === "agenda") ? (
        <AgendaModalContent
          challenge={props.challenge}
          topElement={topElement} 
          onGoBack={error => setPage({page:"confirm", error})}
          onCancelAttending={props.onCancelAttending}
          canGoBack
        />
      ) : (
        <AttendingConfirmedModalContent
          topElement={topElement}
          challenge={props.challenge}
          onAddToAgenda={error => setPage({page:"agenda", error})}
          onCancelAttending={props.onCancelAttending}
        />
      )}
    </div>
  )
}

AttendingOrchesterModalContent.propTypes = {
  onCancelAttending: PropTypes.func,
  page: PropTypes.oneOf(["agenda", "cancel", "confirm", undefined]),
  challenge: PropTypes.shape({
    address: PropTypes.shape({
      address: PropTypes.string
    }),
    secretInfos: PropTypes.shape({
      details: PropTypes.string
    }),
    options: PropTypes.shape({
      public: PropTypes.shape({
        id: PropTypes.string
      })
    })
  })
}

AttendingOrchesterModalContent.defaultProps = {
  onCancelAttending: (() => {}),
  challenge: {
    address: {
      location: {}
    },
    secretInfos: {},
    options: {
      public: {}
    }
  }
}

export default connect(null, dispatch => ({
  hideModal: actions.modal.hide.bind(null, dispatch)
}))(AttendingOrchesterModalContent)