import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import './LoginForm.scss'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import Translation from '../../../Helpers/Translation'
import { Trans } from 'react-i18next'

function LoginForm(props) {
  const {register, handleSubmit} = useForm()
  return (
    <form 
      className="LoginForm"
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <input
        name="email"
        ref={register}
        type="text"
        autoComplete="email"
        className="LoginForm__input"
        placeholder={Translation.shared.t("login_component|Email")}
        onChange={props.onChange.bind(null, "email")}
      />
      <input 
        name="password"
        ref={register}
        type="password"
        autoComplete="current-password"
        placeholder={Translation.shared.t("login_component|Mot de passe")}
        className="LoginForm__input s-top m-bottom"
        onChange={props.onChange.bind(null, "password")}
      />
      <div className={`LoginForm__errors ${props.hasErrors ? "open" : ""}`}>
        {props.errorNode}
      </div>
      {props.isLoading ? (
        <LoadingSpinner 
          size="47px"
          small
        />
      ) : (
        <button className="LoginForm__button green">
          <Trans ns="login_component">Me connecter</Trans>
        </button>
      )}
    </form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool
}

LoginForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {}),
}

export default LoginForm