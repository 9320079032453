import React, { Component } from 'react';
import './LoadingSpinner.scss';
import LoadingGif from '../../../Pictures/gif/loading.gif'
import LoadingMiniGif from '../../../Pictures/gif/loaderMini.gif'

class LoadingSpinner extends Component {

  render() {
    return (
        <div className={"loading-spinner " + this.props.className} >
            <img 
              alt="" 
              src={this.props.mini || this.props.small ? LoadingMiniGif : LoadingGif}
              onClick={(typeof this.props.onClick === 'function') ? this.props.onClick.bind(this) : undefined}
              style={{
                width: this.props.size,
                marginTop: this.props.top
              }}
            />
        </div>
    );
  }
}

export default LoadingSpinner;
