import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { CommunityAction } from '../../../redux/actions'
import './UserInformations.scss';

import TextField from '../../Components/Forms/TextForm'
import FormChecker from '../../../Helpers/FormChecker'

import RainbowEmoji from '../../../Pictures/Emojis/emoji_rainbow.png'
import NoPlanGif from "../../../Pictures/gif/noPlan.gif";
import LoadingImage from '../../../Pictures/gif/loading.gif'
import NavBar from './NavBar'
import PaimentManager from '../../../Manager/PaymentManager'
import ScrollTo from '../../../Helpers/ScrollTo';

class UserInformations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {
                email: props.userEmail,
                emailConfirm: props.userEmail
            },
            planId: props.planId
        }
        if (props.communityUrl)
            props.saveCommunity({name: props.communityUrl})
    }

    UNSAFE_componentWillMount() {
        ScrollTo()
    }

    componentDidMount() {
        this.getPlanInformation()
    }

    componentWillReceiveProps(props) {
        this.setState({
            email: props.userEmail,
            emailConfirm: props.userEmail
        })
    }

    getPlanInformation() {
        if (!this.state.planId && this.props.plan)
            return this.setState({isLoadingPlan: false})
        this.setState({isLoadingPlan: true})
        PaimentManager.getPlan(this.state.planId).then((res) => {
            this.props.savePlan(res.data)
            this.setState({isLoadingPlan: false})
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            this.setState({errorPlan: true, isLoadingPlan: false})
        })
    }

    changeEmail(value) {
        let user = this.state.user
        user.email = value
        this.setState({
            user:user,
            errorGlobal:undefined,
            errorEmail: undefined,
            emailErrorHighligth: undefined
        })
    }

    checkEmail() {
        let formChecker = FormChecker.check(this.state.user.email, {
            min:2,
            max: 125,
            type: "email"
        });

        console.log("Error :", formChecker.error)
        this.setState({
            errorGlobal:undefined,
            errorEmail: (!this.state.user.email) ? "Obligatoire !" : formChecker.error,
            infoEmail: formChecker.info,
            emailErrorHighligth: ((formChecker.error || !this.state.user.email) ? true : false)
        })
    }

    changeEmailConfirm(value) {
        let user = this.state.user
        user.emailConfirm = value
        this.setState({
            user:user,
            errorGlobal:undefined,
            errorEmailConfirm: undefined,
        })
    }

    saveEmail() {
        if (!this.isValid())
            return this.setState({errorGlobal: this.checkError()})
        this.props.saveUserEmail(this.state.user.email)
        this.props.history.push('/community')
    }

    highligthMissingFields() {
        this.setState({
            emailErrorHighligth: this.state.user.name && !this.state.errorEmail ? false : true,
        })
    }

    checkError() {
        if (this.state.errorEmail) {
            this.highligthMissingFields()
            return "Il y a une erreur dans le formulaire"
        } else if ((
                !this.state.user.name
            )) {
            this.highligthMissingFields()
            return "Tous les champs sont obligatoires"
        }
        return "Oups ! Il y a une erreur..."
    }

    isValid() {
        return (
            !(
                this.state.errorEmail
            )
            && (
                this.state.user.email
            )
        )
    }

    render() {
        if (this.state.isLoadingPlan)
            return (<div id="UserInformations">
                <NavBar />
                <div className="formBody">
                        <div className="formBody__loadingPlan">
                            <img src={LoadingImage} alt="" className="loading"/><br/>
                            Chargement de la souscription en cours
                        </div>
                </div>  
            </div>)
        if (this.state.errorPlan)
            return (<div id="UserInformations">
                    <NavBar />
                    <div className="formBody">
                        <div className="formBody__noPlan">
                            <img src={NoPlanGif} alt=""/><br/>
                            Impossible de trouver le plan sélectionné, contactez-nous via crisp si besoin dans le coin en bas à droite.<br/>
                            {/* <NavLink to='/pricing'>
                                <button className="formBody__button">Sélection du plan</button>
                            </NavLink> */}
                        </div>
                    </div>
            </div>)
        return (
            <div id="UserInformations">
                <NavBar />
                <div className="formBody">
                    <h2>Créez votre Mixlab <img className="emoji" src={RainbowEmoji} alt="Mixlab emoji"/></h2>
                    <h3>Remplissez tous les champs pour passer à l'étape suivante.</h3>
                    <div className="label l-top center">
                        Votre email professionnel
                    </div>
                    <div className="subLabel center">
                        Cet email vous permettra de vous connecter en tant qu'administrateur et ainsi pouvoir accéder aux options de votre communauté.
                    </div>
                    <span className="errorLabel">{this.state.errorEmail ? <span className="error">Le mail doit être valide</span> : ""}</span>
                    <TextField
                        isOneLine={true}
                        value={this.state.user.email}
                        onChange={this.changeEmail.bind(this)}
                        placeholder="email*"
                        onBlur={this.checkEmail.bind(this)}
                        className={`center ${this.state.emailErrorHighligth ? "error" : ""}`}
                        onSubmit={this.saveEmail.bind(this)}
                    />
                    <span className="errorGlobal">{this.state.errorGlobal ? this.state.errorGlobal : ""}</span>
                    <div className="navigation">
                        <button className="back" onClick={() => {this.props.history.push('/pricing')}} >Retour</button>
                        <button className="next" onClick={() => {this.saveEmail()}}>Suivant</button>
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        userEmail: state.community && state.community.userEmail,
        plan: state.community && state.community.plan
    }
}, (dispatch) => {
    return {
        saveUserEmail: CommunityAction.saveUserEmail.bind(null, dispatch),
        savePlan: CommunityAction.savePlan.bind(null, dispatch),
        saveCommunity: CommunityAction.saveCommunity.bind(null, dispatch)
    }
})(withRouter(UserInformations));
