/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'

class ChallengeManager extends ApiManager {

    static one(id){
      return this.requester('get', `/public/challenges/${id}`)
    }

    static getCalendarLink(id, type) {
      return this.requester('get', `/public/challenges/${id}/calendar/${type}`)
    }

    static register(id, paymentIntentId){
      return this.requester('get', `/public/challenges/${id}/register${paymentIntentId ? `?paymentIntentId=${paymentIntentId}` : ""}`)
    }

    static unregister(id) {
      return this.requester('get', `/public/challenges/${id}/unregister`)
    }

    static me() {
      return this.requester('get', `/public/challenges/me`)
    }

    static getPaymentIntent(id, ticketIndex) {
      return this.requester('get', `/public/challenges/${id}/ticket/${ticketIndex}/intent`)
    }
}

export default ChallengeManager;