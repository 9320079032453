export const actions = {
    setDownloaded(dispatch, isDownloaded, error) {
        dispatch({
            type: "TRANSLATION_DOWNLOADED",
            isDownloaded: isDownloaded,
            error: error
        });
    }
};
  
export const reducer = (state = {}, action) => {
    let newState = {...state}
    switch (action.type) {
        case "TRANSLATION_DOWNLOADED":
            newState.isDownloaded = action.isDownloaded;
            newState.Error = action.error;
            break;
        default:
            return newState;
    }
    return newState
}
  
export default {
    reducer,
    actions
}