import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import SignUpModalContent from '../Login/SignUpModalContent'
import LoginModalContent from '../Login/LoginModalContent'
import LostPasswordModalContent from '../Login/LostPasswordModalContent'
import { actions } from '../../../../../redux'
import MagicLinkEmailSentModalContent from './MagicLinkEmailSentModalContent'
import Translation from '../../../../../Helpers/Translation'

function LoginOrchesterModalContent(props) {
  const [page, setPage] = useState("login")

  function onSubmit(page) {
    switch (page) {
      case "login":
        props.onSubmit(page);
        props.hideModal();
        break;
      case "register":
      case "lostPassword":
        setPage("magicLink")
        break;
      default:
        break;
    }
  }

  return (
    <div>
      { page === "login" ? (
        <LoginModalContent
          onSubmit={() => onSubmit(page)} 
          onRegister={() => setPage("signUp")}
          onLostPassword={() => setPage("lostPassword")}
          noSignUp={props.noSignUp}
        />
      ) : page === "signUp" ? (
        <SignUpModalContent 
          onSubmit={() => onSubmit(page)}
          onLogin={() => setPage("login")}
        />
      ) : page === "magicLink" ? (
        <MagicLinkEmailSentModalContent isPassword title={Translation.shared.t("login_component|Email envoyé")} />
      ) : (
        <LostPasswordModalContent
          onSubmit={() => onSubmit(page)}
          onLogin={() => setPage("login")}
        />
      )}
    </div>
  )
}

LoginOrchesterModalContent.propTypes = {
  onSubmit: PropTypes.func,
  noSignUp: PropTypes.bool
}

LoginOrchesterModalContent.defaultProps = {
  onSubmit: (() => {})
}

export default connect(null, dispatch => ({
  hideModal: actions.modal.hide.bind(null, dispatch)
}))(LoginOrchesterModalContent)