import React from 'react'
import PropTypes from 'prop-types'

import './PasswordForceIndicator.scss'

function passwordForceColor(force) {
  if (force > 90)
      return "green"
  if (force > 50)
      return "blue"
  if (force > 25)
      return "orange"
  if (force > 0)
      return "red"
  return ""
}

function PasswordForceIndicator(props) {
  return (
    <div className={`PasswordForceIndicator ${passwordForceColor(props.force)} ${props.className}`}>
        <div className="PasswordForceIndicator__dotContainer">
            <div className={`PasswordForceIndicator__dotContainer__dot red ${props.force > 0 ? "actif" : ""}`}/>
        </div>
        <div className="PasswordForceIndicator__dotContainer">
            <div className={`PasswordForceIndicator__dotContainer__dot orange ${props.force > 25 ? "actif" : ""}`}/>
        </div>
        <div className="PasswordForceIndicator__dotContainer">
            <div className={`PasswordForceIndicator__dotContainer__dot blue ${props.force > 50 ? "actif" : ""}`}/>
        </div>
        <div className="PasswordForceIndicator__dotContainer">
            <div className={`PasswordForceIndicator__dotContainer__dot green ${props.force > 90 ? "actif" : ""}`}/>
        </div>
    </div>
  )
}

PasswordForceIndicator.propTypes = {
  force: PropTypes.number
}

PasswordForceIndicator.defaultProps = {
  force: 0
}

export default PasswordForceIndicator