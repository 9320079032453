import React, { Component } from 'react';
import './NotFoundPage.scss';
import NotFoundImg from '../../Pictures/illustrations/404.svg'
import { withRouter } from 'react-router-dom'
import NavBar from './CreateCommunityForm/NavBar'

class NotFoundPage extends Component {

    constructor(props){
        super(props)
        this.state = {
            text:props.text ? props.text : "Oups ! Aucun contenu n'a été trouvé."
        }
    }

  render() {
    return (
        <div id="NotFoundPage">
            <NavBar />
            <div className="col-sm-4 field">
                <img alt="mixlab notfound" src={NotFoundImg}/>
                <br/>
                <span className="text-404">404</span>
                <br/>
                <span className="text"> Oooops ! La page n'existe pas... </span>
                <br/>
                <br/>
                <button onClick={() => {
                    this.props.history.push('/')
                }}>Home</button>
            </div>
        </div>
    );
  }
}

export default withRouter(NotFoundPage);
