import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './Home.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
    NavLink,
    withRouter
} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import { Trans } from 'react-i18next';

import Translation from '../../Helpers/Translation'

import SideText from './Home/SideText'
import Dashboard from './Home/Dashboard'
import Customers from './Home/Customers'
import Introduction from './Home/Introduction'
import IntroductionMobile from './Home/IntroductionMobile'
import TabSelector from './Home/TabSelector';
import TestimonialSignature from './Home/TestimonialSignature';
import NavBar from './CreateCommunityForm/NavBar';
import FooterPage from './Home/FooterPage';

import AddImg from '../../Pictures/icons/AddButton-white.svg'

import imgVisuel from '../../Pictures/illustrations/visuel.png'
import imgWebinar from '../../Pictures/illustrations/webinar.png'
import imgFeatures from '../../Pictures/illustrations/illu2.png'
import imgLink from '../../Pictures/illustrations/illu3.png'
import thumbnailImage from '../../Pictures/illustrations/thumbnail.png'

import imgVisuelEng from '../../Pictures/illustrations/visuel_eng.png'
import imgWebinarEng from '../../Pictures/illustrations/webinar_eng.png'
import imgFeaturesEng from '../../Pictures/illustrations/illu2_eng.png'

import emojiThunderbolt from '../../Pictures/Emojis/emoji_thunderbolt.png'

import leaImage from '../../Pictures/testimonials/mixlab-lea.png'
import alexImage from '../../Pictures/testimonials/mixlab-alex.png'
import quitterieImage from '../../Pictures/testimonials/mixlab-quitterie.png'
import reineImage from '../../Pictures/testimonials/mixlab-reine.png'

import visuEvents from '../../Pictures/illustrations/TabSelector/events.png'
import visuTribes from '../../Pictures/illustrations/TabSelector/tribes.png'
import visuPoll from '../../Pictures/illustrations/TabSelector/poll.png'
import visuNetworking from '../../Pictures/illustrations/TabSelector/networking.png'
import visuDiscussions from '../../Pictures/illustrations/TabSelector/discussions.png'
import visuEventsEng from '../../Pictures/illustrations/TabSelector/events_eng.png'
import visuTribesEng from '../../Pictures/illustrations/TabSelector/tribes_eng.png'
import visuPollEng from '../../Pictures/illustrations/TabSelector/poll_eng.png'
// import visuNetworkingEng from '../../Pictures/illustrations/TabSelector/networking_eng.png'
import visuDiscussionsEng from '../../Pictures/illustrations/TabSelector/discussions_eng.png'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            noAOS: props.noAOS && (navigator.userAgent.indexOf('Mobile') !== -1)
        }
    }

    componentDidMount() {
        if (!this.state.noAOS) {
            AOS.init()
            setTimeout(() => {
                AOS.refresh();
            }, 500)
        }
        if (window.$crisp)
            window.$crisp.push(['do', 'chat:hide']);
    }

    componentWillUnmount() {
        if (window.$crisp)
            window.$crisp.push(['do', 'chat:show']);
    }

    render() {
        let title = this.props.showLogin ? Translation.shared.t("home_component|Connexion - Mixlab") : "Mixlab"
        let description = this.props.showLogin ? Translation.shared.t("home_component|Retrouve tes événements et toutes les communautés auxquelles ton compte est associé") : Translation.shared.t("home_component|Mixlab est la solution pour créer une communauté autour de vos événements présentiels et virtuels.")
        return (
            <div id="Home">
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title}/>
                    <meta name="description" content={description}/>

                    {/* <!-- Open Graph / Facebook --> */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content="Mixlab"/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={description}/>
                    <meta property="og:image" content={thumbnailImage}/>

                    {/* <!-- Twitter --> */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:title" content={title}/>
                    <meta property="twitter:description" content={description}/>
                    <meta property="twitter:image" content={thumbnailImage}/>
                </Helmet>
                <NavBar 
                    isHome
                    showLogin={this.props.showLogin}
                />
                <Introduction name={this.state.name}/>
                <IntroductionMobile name={this.state.name}/>
                <div className="content" id="Start">
                    <div className="contentPlace">
                        {/* <CenterText 
                            // top={40}
                            title=""
                            text="Mixlab est la meilleure solution pour engager votre audience autour de vos événements et de vos ateliers en visio"
                        /> */}
                        <h3 className="mission l-top"><Trans ns="home_component">Ils ont choisi Mixlab :</Trans></h3>
                        <Customers/>
                        <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? imgVisuel : imgVisuelEng}>
                            <h2>
                                <Trans ns="home_component">Rassemblez autour de vos événements</Trans>
                            </h2>
                            <div className="home__quoteText">
                                <Trans ns="home_component">Mixlab me permet d’animer ma communauté et de créer du lien en créant des événements selon mes besoins, du petit atelier privé à la grande conférence en ligne.</Trans>
                            </div>
                            <TestimonialSignature 
                                className="s-top"
                                image={leaImage}
                                name="Léa Fauché"
                                job="Community Builder @Lion"
                            />
                        </SideText>
                        <SideText textSide="left" illustration={Translation.getLanguage() === "fr" ? imgWebinar : imgWebinarEng}>
                            <h2>
                                <Trans ns="home_component">Connectez vos membres partout dans le monde</Trans>
                            </h2>
                            <div className="home__quoteText">
                                <Trans ns="home_component">Mixlab permet à nos membres de se rencontrer partout en France grâce aux ateliers en ligne ! Très pratique de pouvoir aussi intégrer des Facebook Live, des Google Meet, etc…</Trans>
                            </div>
                            <TestimonialSignature 
                                className="s-top"
                                image={alexImage}
                                name="Alexandre Louapre"
                                job="Community Builder @Crème"
                            />
                        </SideText>
                        <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? imgFeatures : imgFeaturesEng}>
                            <h2>
                                <Trans ns="home_component">Développez l'engagement de vos membres</Trans>
                            </h2>
                            <div className="home__quoteText">
                                <Trans ns="home_component">Cette plateforme est vraiment ludique, nos membres sont plus engagés et prennent du plaisir à créer des ateliers pour la communauté. Avant il y avait un événement par mois, aujourd'hui il y en a tous les jours !</Trans>
                            </div>
                            <TestimonialSignature 
                                className="s-top"
                                image={quitterieImage}
                                name="Quitterie Bourrut Lacouture "
                                job="Community Builder @Le Palace"
                            />
                        </SideText>
                        <SideText textSide="left" illustration={imgLink}>
                            <h2 className="s-bottom">
                                <Trans ns="home_component">Partagez automatiquement vos événements en un clic</Trans>
                            </h2>
                            <div className="home__quoteText">
                                <Trans ns="home_component">J’attire 3 fois plus participants et je gagne un temps fou depuis que Mixlab publie automatiquement mes événements sur Facebook, Linkedin, Eventbrite et Meetup à l’heure et à la date que j’ai choisie.</Trans>
                            </div>
                            <TestimonialSignature 
                                className="s-top"
                                image={reineImage}
                                name="Reine De Mereuil"
                                job="DG Adjointe @Matrice"
                            />
                        </SideText>
                        <TabSelector 
                            noAutoPlay
                            className="xxl-top"
                            title={
                                <Fragment>
                                    <Trans ns="home_component">Tout ce dont vous avez besoin réuni <span className="blue">dans un seul outil</span></Trans>
                                </Fragment>
                            }
                            menu={[
                                Translation.shared.t("home_component|Événements"),
                                Translation.shared.t("home_component|Tribus"),
                                Translation.shared.t("home_component|Networking"),
                                Translation.shared.t("home_component|Sondages"),
                                Translation.shared.t("home_component|Discussions")
                            ]}
                            content={[
                                (
                                    <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? visuEvents : visuEventsEng} noAOS>
                                        <h2 className="home__title__small">
                                            <Trans ns="home_component">Créez tous vos événements en fonction de vos besoins</Trans>
                                        </h2>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">De 10 à plus de 500 participants, choisissez la taille de vos événements publics et privés</Trans>
                                            </div>
                                        </div>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Hébergez tous les formats d'événements en présentiel ou en virtuel qui vous correspondent</Trans>
                                            </div>
                                        </div>
                                    </SideText>
                                ),
                                (
                                    <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? visuTribes : visuTribesEng} noAOS>
                                        <h2 className="home__title__small">
                                            <Trans ns="home_component">Rassemblez vos membres en sous communautés</Trans>
                                        </h2>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Regroupez vos membres par centres d’intérêt, compétences, villes, etc…</Trans>
                                            </div>
                                        </div>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Obtenez une vision claire des sujets les plus fédérateurs</Trans>
                                            </div>
                                        </div>
                                    </SideText>
                                ),
                                (
                                    <SideText textSide="right" illustration={visuNetworking} noAOS>
                                        <h2 className="home__title__small">
                                            <Trans ns="home_component">Offrez à vos membres un réseau professionnel interne</Trans>
                                        </h2>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Retrouvez un membre en fonction d’un nom, d’une compétence, d’une entreprise, etc…</Trans>
                                            </div>
                                        </div>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Créez des groupes aléatoires parmi vos membres pour accélérer les échanges</Trans>
                                            </div>
                                        </div>
                                    </SideText>
                                ),
                                (
                                    <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? visuPoll : visuPollEng} noAOS>
                                        <h2 className="home__title__small">
                                            <Trans ns="home_component">Posez facilement des questions à votre communauté</Trans>
                                        </h2>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Planifiez les prochaines dates d’événements ou posez des questions ouvertes</Trans>
                                            </div>
                                        </div>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Donnez la possibilité à vos membres de commenter sur les sondages</Trans>
                                            </div>
                                        </div>
                                    </SideText>
                                ),
                                (
                                    <SideText textSide="right" illustration={Translation.getLanguage() === "fr" ? visuDiscussions : visuDiscussionsEng} noAOS>
                                        <h2 className="home__title__small">
                                            <Trans ns="home_component">Laissez vos membres partager librement par écrit</Trans>
                                        </h2>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Créez des groupes de discussion pour connecter vos membres</Trans>
                                            </div>
                                        </div>
                                        <div className="tickRow l-top">
                                            <div className="tickPicture"/>
                                            <div className="tickText">
                                                <Trans ns="home_component">Fluidifiez les échanges et révélez toute la richesse de votre communauté</Trans>
                                            </div>
                                        </div>
                                    </SideText>
                                )
                            ]}
                        />
                        <HashLink to="/pricing#BasicFeatures" className="xl-top home__hashlink__feature">
                            <button className="blue big">
                                <Trans ns="home_component">Voir toutes les fonctionnalités</Trans>
                            </button>
                        </HashLink>
                        <Dashboard
                            noAOS={this.state.noAOS}
                            title={Translation.shared.t("home_component|Visualisez toutes vos données en un clin d’œil")}
                            text={Translation.shared.t("home_component|Analysez l’impact de vos événements et suivez l’engagement de vos participants")}
                            className="xl-top"
                        />
                        <br/>
                        <a href={Translation.getLanguage() === "fr" ? "https://mixlab.typeform.com/to/bmMzoE" : "https://mixlab.typeform.com/to/H6CAAL"} target="_blank" rel="noopener nofollow noreferrer" className="l-top home__hashlink__feature">
                            <button className="blue big">
                                <img alt="mixlab emoji" className="Introduction__content__insideContent__textContainer__button__emoji" src={emojiThunderbolt} /> <Trans>Demander une démo</Trans> <img src={emojiThunderbolt} className="Introduction__content__insideContent__textContainer__button__emoji"  alt="mixlab emoji"/>
                            </button>
                        </a>
                        <FooterPage />
                    </div>
                </div>
                <canvas id="canvasParticles"></canvas>
                <NavLink to="/pricing">
                    <div className="fixedButton particleButton">
                        <img src={AddImg} alt="Mixlab tick"/>
                    </div>
                </NavLink>
            </div>
        )
    }
}

export default withRouter(Home);
