import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './ChallengeUnregisterModalContent.scss'

import { Trans } from 'react-i18next'

import TopModalSquare from '../../../Elements/TopModalSquare'
import LoadingSpinner from '../../../Elements/LoadingSpinner'

function ChallengeUnregisterModalContent(props) {
  const [isLoading, setIsLoading] = useState()

  let challengeFee = props.challenge && props.challenge.attending && props.challenge.attending.fee && props.challenge.attending.fee.amount && parseFloat(props.challenge.attending.fee.amount / 100).toFixed(2)
  return (
    <div className="ChallengeUnregisterModalContent">
      <TopModalSquare>
        <div className="ChallengeUnregisterModalContent__TopModalSquare__title">
          <Trans ns="event_component">Annuler ma participation</Trans>
        </div>
      </TopModalSquare>
      <div className="ChallengeUnregisterModalContent__content">
        <div className="ChallengeUnregisterModalContent__content__title">
          <Trans ns="event_component">Êtes-vous sûr de vouloir annuler ta participation ?</Trans>
        </div>
        {challengeFee && (
          <div className="ChallengeUnregisterModalContent__content__subTitle s-top">
            <Trans ns="event_component">Le remboursement sera effectué dans 5 jours (moins les frais d'annulation de {{challengeFee}}€)</Trans>
          </div>
        )}
        <div className="ChallengeUnregisterModalContent__content__buttonWrapper l-top">
          {isLoading ? (
            <LoadingSpinner size="30px" mini/>
          ) : (
            <button className="pinkLight bordered" onClick={_=>{setIsLoading(true);props.onUnregister()}}><Trans ns="event_component">Annuler ma participation</Trans></button>
          )}
          <button className="grey text s-top" onClick={props.onClose}><Trans ns="event_component">Fermer</Trans></button>
        </div>
      </div>
    </div>
  )
}

ChallengeUnregisterModalContent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onUnregister: PropTypes.func,
  challenge: PropTypes.shape({
    attending: PropTypes.shape({
      fee: PropTypes.shape({
        amount: PropTypes.number
      })
    })
  }),
}

ChallengeUnregisterModalContent.defaultProps = {
  onClose: (() => {}),
  onUnregister: (() => {}),
  challenge: {
    attending: {
      fee: {} 
    }
  }
}

export default ChallengeUnregisterModalContent