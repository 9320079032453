/**
 * Created by larry on 05/03/2019.
 */

export default {

    saveCommunity(dispatch, community) {
        dispatch({
            type: "SAVE_COMMUNITY",
            community: community
        });
    },
    saveUserEmail(dispatch, userEmail) {
        dispatch({
            type: "SAVE_USER",
            userEmail: userEmail
        });
    },
    savePlan(dispatch, planObject) {
        dispatch({
            type: "SAVE_PLAN",
            plan: planObject
        });
    },
    saveCoupon(dispatch, couponObject) {
        dispatch({
            type: "SAVE_COUPON",
            coupon: couponObject
        });
    },
    removeUserEmail(dispatch) {
        dispatch({
            type: "REMOVE_USER"
        });
    },
    reducer(state, action) {
        const newState = Object.assign({}, state);
        switch (action.type) {
            case "SAVE_COMMUNITY":
                newState.community = action.community
                break;
            case "SAVE_USER":
                newState.userEmail = action.userEmail
                break;
            case "SAVE_PLAN":
                newState.plan = action.plan
                break;
            case "SAVE_COUPON":
                newState.coupon = action.coupon
                break;
            case "REMOVE_USER":
                newState.userEmail = undefined
                break;
            default:
                return newState;
        }
        return newState;
    }
}