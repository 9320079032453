import React, { Component, Fragment } from 'react';
import './SkillsForm.scss'
import LoadingSpinner from './../../Components/Elements/LoadingSpinner'
import SkillsManager from '../../../Manager/SkillsManager'
import Redux from '../../../redux'
import { connect } from 'react-redux'
import TransformErrorCode from "../../../Helpers/TransformErrorCode";
import { Trans } from 'react-i18next'
import skillImage from '../../../Pictures/gif/skill.gif'

class SkillsForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            onChange:props.onChange ? props.onChange : ((items) => {}),
            selectedSkills: props.skills ? props.skills : [],
            max:props.max ? props.max : 6,
            skills:null,
            isLoading:false,
            isLoadingNewSkill:false,
            errors:false,
            open:false,
            className:props.className,
            placeholder:props.placeholder
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            max:props.max,
            className:props.className
        })
    }

    async getSkills(search){
        if (this.timeout)
            clearTimeout(this.timeout)
        if (search)
            this.timeout = setTimeout(async () => {
                this.setState({isLoading:true, skills: []})
                try {
                    let skillRequest = await SkillsManager.searchSkill(search, 1, 5, this.state.selectedSkills.map((e) => e._id).join(','))
                    if (skillRequest.data)
                        this.setState({isLoading:false, skills: skillRequest.data.content, open:true})
                } catch (err) {
                    this.setState({isLoading:false, errors:true, open:true})
                }
                this.setState({isLoading:false})
            }, 300)
    }

    setInputActive(){
        this.refs.input.focus()
    }

    deleteStack(e, element){
        var selectedSkills = this.state.selectedSkills
        var index = selectedSkills.indexOf(element)
        if (index !== -1) {
            selectedSkills.splice(index, 1)
        }
        this.state.onChange(selectedSkills)
        this.setState({selectedSkills:selectedSkills, open:false})
        e.stopPropagation()
    }

    verifyKeyDown(e) {
        var keycode = e.keyCode;
        var value = this.refs.input.value;
        var selectedSkills = this.state.selectedSkills
        var skills = this.state.skills
        if (!value) {
            switch (keycode){
                case 8://Delete
                    if (selectedSkills.length <= 0) return true
                    selectedSkills.pop()
                    this.state.onChange(selectedSkills)
                    this.setState({value:"", selectedSkills:selectedSkills, open:false})
                    return true
                default:
                    return true
            }
        } else {
            switch (keycode){
                case 9://Tab
                    e.preventDefault();
                    var element = this.state.skills[0]
                    if (element && this.state.selectedSkills.length < this.state.max) {
                        selectedSkills.push(element)
                        var index = skills.indexOf(element)
                        if (index !== -1)
                            skills.splice(index, 1)
                        this.state.onChange(selectedSkills)
                        this.setState({value:"", selectedSkills:selectedSkills, skills, open:false})
                        this.refs.input.value = ""
                    }
                    return false
                default:
                    return true
            }
        }
    }

    onChangeText(e) {
        if (!e.target.value)
            this.setState({open: false})
    }

    verifyKeyUp(e) {
        var keycode = e.keyCode;
        var value = this.refs.input.value;
        var selectedSkills = this.state.selectedSkills
        var skills = this.state.skills

        if (e.target.style) {
            let fontSize = window.getComputedStyle(e.target, null).getPropertyValue('font-size');
            e.target.style.width = `${this.displayTextWidth(value, `${fontSize} Muli`) + 20}px`//Size + padding-left
        }

        if (value) {
            switch (keycode){
                case 13://Enter
                    var element = this.state.skills[0]
                    if (element && this.state.selectedSkills.length < this.state.max) {
                        selectedSkills.push(element)
                        var index = skills.indexOf(element)
                        if (index !== -1){
                            skills.splice(index, 1)
                        }
                        this.state.onChange(selectedSkills)
                        this.refs.input.value = ""
                        this.setState({value:"", selectedSkills:selectedSkills, open:false})
                    }
                    return true
                default:
                    this.getSkills(value)
                    this.setState({value:value, open: true})
                    return true
            }
        } else {
            this.setState({value:"", open:false})
        }

        return true
    }

    selectStack(e, element){
        if (this.state.selectedSkills.length >= this.state.max) {
            return
        }
        var skills = this.state.skills
        var selectedSkills = this.state.selectedSkills
        var index = this.state.skills.indexOf(element)
        if (index !== -1)
            skills.splice(index, 1)
        selectedSkills.push(element);
        this.refs.input.value = ""
        this.state.onChange(selectedSkills)
        this.setState({value:"", selectedSkills, open:false})
    }

    async postNewSkill(skillName){
        this.setState({isLoadingNewSkill:true}, this.showModal)
        try {
            let skillRequest = await SkillsManager.postNewSkill(skillName)
            if (skillRequest.data && skillRequest.data.element) {
                let newSkill = skillRequest.data.element
                let skills = this.state.skills
                let selectedSkills = this.state.selectedSkills.filter((e) => String(e._id) !== String(newSkill._id))
                selectedSkills.push(newSkill)
                this.refs.input.value = ""
                this.state.onChange(selectedSkills)
                this.setState({skills, selectedSkills})
            }
            this.props.hideModal()
        } catch (err) {
            console.log(err)
            const errorCode = err.response && err.response.data ? err.response.data.code : "UNKNOWN"
            this.setState({errorCode, success:false, isLoadingNewSkill:false}, () => {
                this.showModal()
                setTimeout(() => {
                    this.setState({errorCode: undefined})
                    this.showModal()
                }, 3000)
            })
        }
        this.setState({isLoadingNewSkill:false})
    }

    showModal() {
        let skillName = this.refs.input.value
        var content = (
            <div id="NewSkillModal">
                <div className="bigLogo">
                    <img src={skillImage} alt=""/>
                </div>
                <div className="NewSkillModal__text">
                    <Trans ns="skillform_component">Veux-tu ajouter la compétence <span className="blue">{{skillName}}</span> ?</Trans>
                </div>
                <div className="NewSkillModal__buttons m-top">
                    {(this.state.isLoadingNewSkill) ? (
                        <LoadingSpinner />
                    ): (this.state.errorCode) ? (
                        <div className="error">
                            {TransformErrorCode(this.state.errorCode)}
                        </div>
                    ) : (
                        <Fragment>
                            <button 
                                onClick={() => this.postNewSkill(this.refs.input.value)}
                                className="add"
                            >
                                <Trans>
                                    Ajouter
                                </Trans>
                            </button>
                            <button 
                                onClick={this.props.hideModal}
                                className="back"
                            >
                                <Trans>
                                    Retour
                                </Trans>
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
        )
        this.props.showModal(content)
    }

    displayTextWidth(text, font) {
        const myCanvas = document.createElement("canvas")
        const context = myCanvas.getContext("2d");
        context.font = font;

        const metrics = context.measureText(text);
        return metrics.width;
    };

  render() {
      let stackDiv = this.state.selectedSkills.map((element, index) => {
          return (<span key={index} onClick={(e) => this.deleteStack(e, element)} className="stackName clickable"> {element.name} <div className="cross clickable"/></span>)
      })

      let searchStackDiv = []
      if (Array.isArray(this.state.skills) && this.state.skills.length > 0) {
          searchStackDiv = this.state.skills.map((element, index) => {
              return (<div key={index} className="stackSearch row clickable" onClick={(e) => this.selectStack(e, element)}>
                  <div className="col-sm-8">
                      <span  className="stackName"> {element.name}</span>
                  </div>
              </div>)
          })
      } else if (this.refs.input && this.refs.input.value && !this.state.isLoading){
          searchStackDiv = [(
              <div key={0} className="stackSearch row clickable" onClick={this.showModal.bind(this)}>
                  <div className="col-sm-12 noSkillsRow">
                      <div
                          className="add"
                      >
                      </div>
                      <div className="no-skill">
                          <Trans ns="skillform_component">Ajoute une compétence introuvable !</Trans>
                      </div>
                  </div>
              </div>
          )]
      }

    return (
        <div id="SkillsForm">
            <div onClick={this.setInputActive.bind(this)}
                 className={"stackInput " + (this.state.className ? this.state.className : "")}
            >
                <span className="stackGroup">
                    {stackDiv}
                    {(this.state.placeholder && stackDiv.length <= 0 && !this.state.value) ? (
                        <p className="placeholder">{this.state.placeholder}</p>
                    ) : (
                        null
                    )}
                    <input 
                        ref="input" 
                        className={((this.state.placeholder && stackDiv.length <= 0 && !this.state.value) ? ("hiddenInput") : (""))}
                        style={{paddingLeft: stackDiv.length <= 0 ? "11px" : "0px"}}
                        onFocus={() => {this.setState({placeholder:""})}} 
                        onBlur={() => {this.setState({placeholder:this.props.placeholder})}} 
                        onKeyUp={this.verifyKeyUp.bind(this)} 
                        onKeyDown={this.verifyKeyDown.bind(this)} 
                        type="text"
                        onChange={this.onChangeText.bind(this)}
                    />
                    {this.state.isLoading && (
                        <LoadingSpinner 
                            mini 
                            size="17px" 
                            top="2px"
                            inline
                        />
                    )}
                </span>
            </div>
            <div className={((this.state.open) ? "open" : "close") + " stackSearchContainer"} style={{height:((this.state.open) ? searchStackDiv.length * 65 : 0) + "px" }}>
                {searchStackDiv}
            </div>
        </div>
    );
  }
}

export default connect(
state => {
    return {}
}, (dispatch) => {
    return {
        showModal: Redux.actions.modal.show.bind(null, dispatch),
        hideModal: Redux.actions.modal.hide.bind(null, dispatch),
    }
})(SkillsForm);
