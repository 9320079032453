import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Trans } from 'react-i18next'

import './InvoicesListElement.scss'

import LoadingSpinner from '../../../../Components/Elements/LoadingSpinner'
import UserManager from '../../../../../Manager/UserManager'
import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'
import Translation from '../../../../../Helpers/Translation'


function InvoicesListElement(props) {
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState()

  async function getInvoicePDF(chargeId) {
    setIsLoading(true)
    try {
      let request = await UserManager.getInvoicePDF(chargeId)
      if (request.data)
        window.location = request.data
    } catch (err) {
      setError(TransformErrorCode(err))
    }
    setIsLoading(false)
  }
  
  let challengeEnd = props.element.challenge && props.element.challenge.endDate && props.element.challenge.endDate && moment(props.element.challenge.endDate).format(Translation.shared.t("dateformat|DD[/]MM[/]YYYY"))

  return (
    <div className={`InvoicesListElement ${props.className || ""}`}>
      <div className="InvoicesListElement__textWrapper">
        <div className="InvoicesListElement__textWrapper__date">
          {moment(props.element.attending.createdAt).format(Translation.shared.t("dateformat|Do MMMM YYYY"))}
        </div>
        <div className="InvoicesListElement__textWrapper__title xxs-top">
          <a href={`/challenge/${props.element.challenge.id}`}>{props.element.challenge.title}</a>
        </div>
      </div>
      <div className="InvoicesListElement__priceWrapper">
        <div className="InvoicesListElement__priceWrapper__price">
          {parseFloat(props.element.attending.payed / 100).toFixed(2)}€
        </div>
        {props.element.attending.refund && (
          <div className="InvoicesListElement__priceWrapper__refund">
              <Trans ns="income_component">Remboursé </Trans>: {(props.element.attending.refund / 100).toFixed(2)}€
          </div>
        )}
        <div 
          className="InvoicesListElement__priceWrapper__pdfWrapper"
        >
          {(error) ? (
            <div 
              className="InvoicesListElement__priceWrapper__pdfWrapper__pdfError clickable xxs-top"
              onClick={_=>setError(null)}
            >
              {error}
            </div>
          ) : (isLoading) ? (
            <LoadingSpinner size="14px" mini className="xxs-top"/>
          ) : props.element.challenge.isEnded || props.element.attending.refund ? (
            <span 
              className="InvoicesListElement__priceWrapper__pdfWrapper__pdf clickable xxs-top"
              onClick={_=>getInvoicePDF(props.element.chargeId)}
            >
              <Trans ns="income_component">Reçu</Trans>
            </span>
          ) : (
            <span className="InvoicesListElement__priceWrapper__pdfWrapper__unavailable"><Trans ns="income_component">Disponible le {{challengeEnd}}</Trans></span>
          )}
        </div>
      </div>
    </div>
  )
}

InvoicesListElement.propTypes = {
  element: PropTypes.shape({
    challenge: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      isEnded: PropTypes.bool,
      endDate: PropTypes.string
    }),
    attending: PropTypes.shape({
      payed: PropTypes.number,
      createdAt: PropTypes.string,
      refundAmount: PropTypes.number
    }),
    chargeId: PropTypes.string
  })
}

InvoicesListElement.defaultProps = {
  element: {
    challenge: {},
    attending: {}
  }
}

export default InvoicesListElement