import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import './ProfileForm.scss'

import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import SkillsForm from './SkillsForm'
import TransformErrorCode from '../../../Helpers/TransformErrorCode'
import CompressPics from '../../../Helpers/CompressPics'
import circleLoader from '../../../Pictures/gif/loaderMini.gif'
import placeholderImage from '../../../Pictures/gif/placeholderImage.gif'
import Translation from '../../../Helpers/Translation'

function ProfileForm(props) {
  const {register, handleSubmit} = useForm()
  const [imageProfile, setImageProfile] = useState((props.user && props.user.imageUrl && {
    image: props.user.imageUrl
  }) || null)
  const [isCompressingImage, setIsCompressingImage] = useState()
  const [errorImage, setErrorImage] = useState()
  const [changeSkills, setChangeSkills] = useState()
  const [skillsUser, setSkillsUser] = useState()
  const imageInput = useRef()

  function onSubmit(formProps) {
    formProps.image = imageProfile
    formProps.changeSkills = changeSkills
    formProps.skills = skillsUser
    props.onSubmit(formProps)
  }

  async function checkImage(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      setIsCompressingImage(true)
      setErrorImage(undefined)
      try {
        let datas = await new CompressPics(1).exec(file, event.target.result)
        setImageProfile(datas)
      } catch (err) {
        setErrorImage(err.code)
      }
      setIsCompressingImage(false)
    }
  }

  return (
    <div className="ProfileForm l-top">
      <div className="ProfileForm__imageWrapper">
        <div className="ProfileForm__imageWrapper__image">
          <div className={"ProfileForm__imageWrapper__image__imageDiv " + (isCompressingImage ? "" : imageProfile ? "bordered" : "")}>
              <img alt="" className={"align-center ProfileForm__imageWrapper__image__imageDiv__profileImage clickable"} src={isCompressingImage ? circleLoader : (imageProfile && imageProfile.image) || (props.user && props.user.imageUrl) || placeholderImage} onClick={(e)=>{if (!props.isLoading) imageInput.current.click()}}/>
              <input
                  ref={imageInput}
                  type="file"
                  onChange={checkImage}
                  style={{display:"none"}}
                  accept=".png, .jpg, .jpeg, .gif, .webp"
              />
          </div>
        </div>
        <div className="ProfileForm__imageWrapper__errorWrapper">
          {(errorImage) ? (
              <p className="ProfileForm__imageWrapper__errorWrapper__errorImage">{TransformErrorCode(errorImage)}</p>
          ) : (
              <p className={`ProfileForm__imageWrapper__errorWrapper__infoImage ${(!props.isLoading && !isCompressingImage) ? "clickable" : ""}`} onClick={(e)=>{if (!props.isLoading && !isCompressingImage) imageInput.current.click()}}>{isCompressingImage ? "Compression en cours..." : imageProfile ? Translation.shared.t("user_component|Modifier l'image") : Translation.shared.t("user_component|Ajoute une photo !")}</p>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="surname"
          ref={register}
          type="text"
          autoComplete="first_name"
          className={`ProfileForm__input m-top ${props.errorForm.surname ? "error" : ""}`}
          placeholder={Translation.shared.t("user_component|Prénom")}
          maxLength="32"
          defaultValue={props.user && props.user.surname}
          onChange={props.onChange.bind(null, "surname")}
        />
        <input 
          name="name"
          ref={register}
          type="text"
          autoComplete="last_name"
          placeholder={Translation.shared.t("user_component|Nom")}
          maxLength="32"
          className={`ProfileForm__input m-top ${props.errorForm.name ? "error" : ""}`}
          defaultValue={props.user && props.user.name}
          onChange={props.onChange.bind(null, "name")}
        />
        <div className="ProfileForm__jobAndEnterprise">
          <input 
            name="job"
            ref={register}
            type="text"
            autoComplete="job"
            placeholder={Translation.shared.t("user_component|Poste")}
            maxLength="64"
            className={`ProfileForm__input m-top ${props.errorForm.job ? "error" : ""}`}
            defaultValue={props.user && props.user.job}
            onChange={props.onChange.bind(null, "job")}
          />
          <div className="ProfileForm__jobAndEnterprise__separator"/>
          <input
            name="enterprise"
            ref={register}
            type="text"
            maxLength="64"
            autoComplete="enterprise"
            placeholder={Translation.shared.t("user_component|Entreprise")}
            className={`ProfileForm__input m-top ${props.errorForm.enterprise ? "error" : ""}`}
            defaultValue={props.user && props.user.enterprise}
            onChange={props.onChange.bind(null, "enterprise")}
          />
        </div>
        <div className="ProfileForm__email m-top">
            {props.user && props.user.email}
        </div>
        <div className="ProfileForm__skills m-top">
          <div className="ProfileForm__skills__title"><Trans ns="user_component">tag tes compétences <span>(facultatif)</span></Trans></div>
          <SkillsForm 
            max={3}
            skills={props.user && props.user.skills}
            onChange={(skills) => {
              setSkillsUser(skills)
              setChangeSkills(true)
              props.onChange("skills")
            }}
            className="m-bottom s-top"
            placeholder="NodeJS, Marketing, UX Design"
          />
        </div>
        <div className={`ProfileForm__errors ${props.hasErrors || (props.hasErrors) ? "open" : ""}`}>
          {props.errorNode}
        </div>
        <div className="ProfileForm__buttons">
          {props.isLoading ? (
            <LoadingSpinner 
              size="40px"
              small
            />
          ) : (
            <button className="ProfileForm__buttons__button blue"><Trans>Enregistrer</Trans></button>
          )}
        </div>
      </form>
    </div>
  )
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  errorForm: PropTypes.object,
  user: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    job: PropTypes.string,
    enterprise: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string
    }))
  })
}

ProfileForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {}),
  errorForm: {}
}

export default ProfileForm