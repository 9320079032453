import ImageCompressor from "browser-image-compression";

/**
 * Permit to compress picture to specified size
 */
class CompressPics {

    constructor(maxSizeMB, onProgress = (() => {})) {
        this.maxSizeMB = maxSizeMB
        this.options = {
            maxSizeMB,
            maxIteration: 20,
            useWebWorker: true,
            onProgress: onProgress
        }
        this.fileReader = new FileReader()
    }

    error(code, description) {
        let error = new Error()
        error.code = code
        error.description = description
        return error
    }

    onError(reject, e) {
        console.log("Error compression :", e)
        reject(this.error("error_compression", e))
    }

    /**
     * Compress image and get new url and compressed data
     * @param file File to be compressed
     * @return {Promise} success: (url, imageData) reject: {Error}
     */
    urlAndData(file) {
        return new Promise(async (success, reject) => {
            try {
                let imageFile = await ImageCompressor(file, this.options)
                let image = await ImageCompressor.getDataUrlFromFile(imageFile)
                success({image:image, imageFile:imageFile})
            } catch (err) {
                reject(err)
            }
        })
    }

    /**
     *
     * @param file
     * @param file
     * @return {Promise.<Object, Error>} A Promise who returning Object with image and imageData, on reject
     * returning Error.
     *
     * @Errors:
     *  - UPLOAD001 when file is a gif and is too big
     *  - no_image_found when file is not specified in parameters
     *  - error_compression when an error occured during compression
     */
    exec(file) {
        return new Promise((success, reject) => {
            if (!file) {
                let error = new Error()
                error.code = 'no_image_found'
                error.message = "No file given in parameter"
                return reject(error)
            }
            this.fileReader.onload = async (e) => {
                try {
                    if (file.type !== "image/gif" && file.type !== "image/webp") {
                        let data = await this.urlAndData(file)
                        success({image: data.image, imageFile: data.imageFile})
                    } else if (file.size > (this.maxSizeMB * 1000000))
                        return reject(this.error("UPLOAD001", e))
                    else
                        success({image: window.URL.createObjectURL(file), imageFile: file})
                } catch (err) {
                    reject(err)
                }
            };
            this.fileReader.readAsDataURL(file);
        })
    }
}

export default CompressPics