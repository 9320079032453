import React from 'react';
import './FooterPage.scss';
import logoMixlab from '../../../Pictures/Logos/logo.png'
import { Trans } from 'react-i18next';
import Translation from '../../../Helpers/Translation';
import LanguageDetector from '../../Components/Elements/LanguageSelector';

function FooterPage() {
  return (
      <div className="FooterPage">
        <div className="FooterPage__grid">
          <div className="FooterPage__column__side">
            <img src={logoMixlab} alt="mixlab" className="FooterPage__column__logo"/>
          </div>
          <div className="FooterPage__columns">
            <div className="FooterPage__column">
              <div className="FooterPage__column__title">
                <Trans ns="footer_component">Contact</Trans>
              </div>
              <div className="FooterPage__column__content">
                <div className="FooterPage__column__content__element">
                  <Trans ns="footer_component">Pour toutes questions,<br/>contactez nous :<br/></Trans>
                  <a className="link" href="mailto:noemie@mixlab.fr">noemie@mixlab.fr</a>
                </div>
              </div>
            </div>
            <div className="FooterPage__column">
              <div className="FooterPage__column__title">
                <Trans ns="footer_component">Ressources</Trans>
              </div>
              <div className="FooterPage__column__content">
                <div className="FooterPage__column__content__element">
                  <a href="/pricing">
                    <Trans ns="footer_component">Tarifs</Trans>
                  </a>
                </div>
                <div className="FooterPage__column__content__element">
                  <a href="https://blog.mixlab.fr" target="_blank" rel="external noopener noreferrer">
                    <Trans ns="footer_component">Blog</Trans>
                  </a>
                </div>
                <div className="FooterPage__column__content__element">
                  <a href="https://cb.mixlab.fr" target="_blank" rel="external noopener noreferrer">
                    <Trans ns="footer_component">Communauté CB</Trans>
                  </a>
                </div>
              </div>
            </div>
            <div className="FooterPage__column">
              <div className="FooterPage__column__title">
                <Trans ns="footer_component">Produit</Trans>
              </div>
              <div className="FooterPage__column__content">
                <div className="FooterPage__column__content__element">
                  <a href="/pricing">
                    <Trans ns="footer_component">Créer un Mixlab</Trans>
                  </a>
                </div>
                <div className="FooterPage__column__content__element">
                  <a href={Translation.getLanguage() === "fr" ? "https://mixlab.typeform.com/to/bmMzoE" : "https://mixlab.typeform.com/to/H6CAAL"} target="_blank" rel="external nofollow noopener noreferrer">
                    <Trans>Demander une démo</Trans>
                  </a>
                </div>
                <div className="FooterPage__column__content__element">
                  <a href="/pricing#BasicFeatures">
                    <Trans ns="home_component">Voir toutes les fonctionnalités</Trans>
                  </a>
                </div>
              </div>
            </div>
            <div className="FooterPage__column">
              <div className="FooterPage__column__title">
                <Trans ns="footer_component">Légal</Trans>
              </div>
              <div className="FooterPage__column__content">
                <div className="FooterPage__column__content__element">
                  <a href="/cgu">
                    <Trans ns="footer_component">CGU</Trans>
                  </a>
                </div>
                <div className="FooterPage__column__content__element">
                  <a href="/cgv">
                    <Trans ns="footer_component">CGV</Trans>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LanguageDetector />
        <div className="FooterPage__copyright">
            <h2 className="FooterPage__copyright__title">Mixlab</h2>
            <div className="FooterPage__copyright__bottomCopyright">
              <Trans ns="home_component">Tous droits réservés © 2020</Trans>
            </div>
        </div>
      </div>
  )
}

export default FooterPage;
