import React from 'react';
import './LoadingPage.scss';
import LoadingSpinner from '../Components/Elements/LoadingSpinner';

function LoadingPage() {
  return (
    <div className="LoadingPage">
      <LoadingSpinner />
    </div>
  )
}

export default LoadingPage;
