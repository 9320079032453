import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import './LoginModalContent.scss'

import LoginForm from '../../../../Pages/User/LoginForm'
import TopModalSquare from '../../../Elements/TopModalSquare'

import FormChecker from '../../../../../Helpers/FormChecker'
import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'
import UserManager from '../../../../../Manager/UserManager'
import { actions } from '../../../../../redux'
import { Trans } from 'react-i18next'
import Translation from '../../../../../Helpers/Translation'

function checkErrors(data) {
  let errorsNode = []
  let emailChecker = FormChecker.check(data.email, {
    type: "email"
  })
  if (!data.email || emailChecker.error)
    errorsNode.push(<div key="emailError"><Trans ns="errors">L'email est obligatoire et doit être valide.</Trans></div>)
  if (!data.password)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Le mot de passe est requis.</Trans></div>)

  return errorsNode
}


function LoginModalContent(props) {
  const [errors, setErrors] = useState(props.error ? <div>{props.error}</div> : null)
  const [hasErrors, setHasErrors] = useState(!!(props.error))
  const [isLoading, setIsLoading] = useState()

  async function onSubmit(data) {
    let errorsNode = checkErrors(data)
    if (errorsNode.length > 0) {
      setHasErrors(true)
      return setErrors(errorsNode)
    }
    setIsLoading(true)
    try {
      let loginData = await UserManager.login(data.email, data.password)
      props.setToken(loginData.data)
      props.onSubmit()
    } catch (err) {
      setHasErrors(true)
      setErrors(TransformErrorCode(err))
    }
    setIsLoading(false)
  }

  function onChange() {
    setHasErrors(false)
  }

  return (
    <div className="LoginModalContent">
      <TopModalSquare>
        { (
          props.topElement
        ) || (
          <div className="LoginModalContent__TopModalSquare__title">
            <Trans>Connexion</Trans>
          </div>
        )}
      </TopModalSquare>
      <div className="LoginModalContent__content">
        <div className="LoginModalContent__content__sentence s-top">
          {props.noSignUp ? (
            Translation.shared.t("login_component|Connecte-toi avec ton compte Mixlab")
          ) : (
            Translation.shared.t("login_component|Connecte-toi ou crée ton compte sur Mixlab")
          )}
        </div>
        <div className="LoginModalContent__content__loginForm l-top">
          <LoginForm 
            isLoading={isLoading}
            onSubmit={onSubmit.bind(this)}
            hasErrors={hasErrors}
            errorNode={errors}
            onChange={onChange.bind(this)}
          />
        </div>
        <div className="LoginModalContent__content__actions l-top">
          <div 
            className="LoginModalContent__content__actions__passwordLost clickable"
            onClick={() => props.onLostPassword()}
          >
            <Trans ns="login_component">Mot de passe oublié ?</Trans>
          </div>
          {!props.noSignUp && (
            <div 
              className="LoginModalContent__content__actions__signUp s-top clickable"
              onClick={() => props.onRegister()}
            >
              <Trans ns="login_component">Créer un compte</Trans>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LoginModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onRegister: PropTypes.func,
  onLostPassword: PropTypes.func,
  topElement: PropTypes.element,
  error: PropTypes.string,
  noSignUp: PropTypes.bool
}

LoginModalContent.defaultProps = {
  onSubmit: (() => {}),
  onRegister: (() => {}),
  onLostPassword: (() => {})
}

export default connect(null, (dispatch) => ({
  setToken: actions.user.login.bind(null, dispatch)
}))(LoginModalContent)