import React from 'react'
import PropTypes from 'prop-types'

import './ChallengeErrorModal.scss'

import { Trans } from 'react-i18next'

import TopModalSquare from '../../../Elements/TopModalSquare'

function ChallengeErrorModal(props) {
  return (
    <div className="ChallengeErrorModal">
      <TopModalSquare>
        <div className="ChallengeErrorModal__TopModalSquare__title">
          Ooops !
        </div>
      </TopModalSquare>
      <div className="ChallengeErrorModal__content">
        <div className="ChallengeErrorModal__content__text">
          {props.error}
        </div>
        <button className="grey text s-top" onClick={props.onClose}><Trans ns="event_component">Fermer</Trans></button>
      </div>
    </div>
  )
}

ChallengeErrorModal.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func
}

ChallengeErrorModal.defaultProps = {
  onClose: (() => {})
}

export default ChallengeErrorModal