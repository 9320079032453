import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import './ChallengePageBottomBar.scss'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import whiteLogo from '../../../Pictures/Logos/logo_white.svg'
import ScrollTo from '../../../Helpers/ScrollTo'
import { Trans } from 'react-i18next'

function ChallengePageBottomBar(props) {

  function middleButton(status) {
    switch (status) {
      case "done": 
        return (
          <button className="ChallengePageBottomBar__button blue" onClick={() => {props.history.push('/');ScrollTo();}}>
              <img className="ChallengePageBottomBar__button__logo" src={whiteLogo} alt="logo Mixlab"/>&nbsp;&nbsp;<Trans ns="event_component">Créer mon Mixlab</Trans>
          </button>
        )
      case "organize":
      case "attending":
          if (props.challenge.isOrganizer)
            return (
              <button 
                className="ChallengePageBottomBar__button blue"
                onClick={props.onClickAgenda}
              >
                <Trans ns="event_component">Ajouter à mon calendrier</Trans>
              </button>
            )
          return (
            <button 
              className="ChallengePageBottomBar__button rainbow"
              onClick={props.onClickAgenda}
            >
              <Trans ns="event_component">Ta place est réservée !</Trans>
            </button>
          )
      case "inProgressNotAttending":
      case "inProgress":
        return (
          <button className="ChallengePageBottomBar__button pink" disabled>
            <Trans ns="event_component">En cours...</Trans>
          </button>
        )
      case "canceled": 
        return (
          <button className="ChallengePageBottomBar__button pinkLight" disabled>
            <Trans ns="event_component">Annulé</Trans>
          </button>
        )
      case "full": 
        return (
          <button className="ChallengePageBottomBar__button pinkLight" disabled>
            <Trans ns="event_component">Complet !</Trans>
          </button>
        )
      case "finished": 
        return (
          <button className="ChallengePageBottomBar__button blue" onClick={() => {props.history.push('/');ScrollTo();}}>
            <img className="ChallengePageBottomBar__button__logo" src={whiteLogo} alt="logo Mixlab"/>&nbsp;&nbsp;<Trans ns="event_component">Créer mon Mixlab</Trans>
          </button>
        )
      default: 
        return (
          <button className="ChallengePageBottomBar__button green" onClick={props.onRegister}>
            <Trans ns="event_component">M'inscrire !</Trans>
          </button>
        )
    }
  }

  // function sideText(status) {
  //   switch (status) {
  //     case "done": 
  //     case "finished": 
  //       return (
  //         <div className="ChallengePageBottomBar__sideText">
  //           Événement terminé
  //         </div>
  //       )
  //     default: 
  //       return null
  //   }
  // }

  const challengeStatus = props.challenge.status

  return (
    <div className="ChallengePageBottomBar">
      {(props.error) ? (
        <div className="ChallengePageBottomBar__error">
          {props.error}
        </div>
      ) : (props.isLoading) ? (
        <LoadingSpinner 
          mini
        />
      ) : (
        middleButton(challengeStatus)
      )}    
    </div>
  )
}

ChallengePageBottomBar.propTypes = {
  isLoading: PropTypes.bool,
  onClickAgenda: PropTypes.func,
  onRegister: PropTypes.func,
  challenge: PropTypes.shape({
    status: PropTypes.string
  }),
}

ChallengePageBottomBar.defaultProps = {
  onRegister: (() => {}),
  onClickAgenda: (() => {}),
  challenge: {}
}

export default withRouter(ChallengePageBottomBar)