import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import './LoginMagicPage.scss'

import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import NavBar from '../CreateCommunityForm/NavBar'

import UserManager from '../../../Manager/UserManager'

import travoltaImage from '../../../Pictures/gif/noPlan.gif'
import mailBoxImage from '../../../Pictures/gif/mailbox.gif'
import { actions } from '../../../redux'
import { Trans } from 'react-i18next'

function LoginMagicPage(props) {
  const [error, setError] = useState()
  const [newCodeSent, setNewCodeSent] = useState()
  const [isLoading, setIsLoading] = useState()

  async function exchangingCode(code, redirect) {
    try {
      let tokenResponse = await UserManager.exchangeCode(code)
      props.setToken(tokenResponse.data)
      props.history.push(redirect)
    } catch (err) {
      setError(true)
    }
  }

  async function resendCode(code, redirect) {
    setIsLoading(true)
    try {
      await UserManager.resendCode(code, redirect)
      setNewCodeSent(true)
    } catch (err) {
      setError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    exchangingCode(props.code, props.redirect)
  }, [])

  return (
    <div className="LoginMagicPage">
      {newCodeSent ? (
        <Fragment>
          <NavBar />
          <img src={mailBoxImage} className="LoginMagicPage__gif" alt="mailbox mixlab"/>
          <div className="LoginMagicPage__subtext valid l-top">
            <Trans ns="login_component">Consulte ta boite mail pour retrouver ton nouveau lien de connexion</Trans>
          </div>
        </Fragment>
      ) : error ? (
        <Fragment>
          <NavBar />
          <img src={travoltaImage} className="LoginMagicPage__gif" alt="notfound mixlab"/>
          <div className="LoginMagicPage__subtext LoginMagicPage__error l-top">
            <Trans ns="login_component">Ce lien de connexion n'est plus valide</Trans>
          </div>
          {(isLoading) ? (
            <LoadingSpinner 
              className="l-top"
              size="37px"
            />
          ) : (
            <button className="LoginMagicPage__button blue l-top" onClick={_ => resendCode(props.code, props.redirect)}>
              <Trans ns="login_component">Envoyer un nouveau lien</Trans>
            </button>
          ) }
        </Fragment>
      ) : (
        <Fragment>
          <LoadingSpinner />
          <div className="LoginMagicPage__subtext m-top">
            <Trans ns="login_component">Veuillez patienter pendant la connexion...</Trans>
          </div>
        </Fragment>
      )}
    </div>
  )
}

LoginMagicPage.propTypes = {
  code: PropTypes.string,
  redirect: PropTypes.string
}

LoginMagicPage.defaultProps = {
  redirect: "/profile"
}

export default connect(null, (dispatch) => ({
  setToken: actions.user.login.bind(null, dispatch)
}))(withRouter(LoginMagicPage))