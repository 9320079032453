import React, {useState} from 'react'
import PropTypes from 'prop-types'

import './PasswordUpdateModalContent.scss'

import PasswordForm from '../../../../Pages/User/PasswordForm'
import TopModalSquare from '../../../Elements/TopModalSquare'

import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'

import UserManager from '../../../../../Manager/UserManager'
import { Trans } from 'react-i18next'

function checkErrors(data, force) {
  let errorsNode = []

  if (!data.password)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Le mot de passe est obligatoire !</Trans></div>)
  else if (data.password && force < 25)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Ton mot de passe est trop court</Trans></div>)

  return errorsNode
}


function PasswordUpdateModalContent(props) {
  const [errors, setErrors] = useState(props.error ? <div>{props.error}</div> : null)
  const [hasErrors, setHasErrors] = useState(!!(props.error))
  const [isLoading, setIsLoading] = useState()

  async function onSubmit(data, force) {
    let errorsNode = checkErrors(data, force)
    if (errorsNode.length > 0) {
      setHasErrors(true)
      return setErrors(errorsNode)
    }
    setIsLoading(true)
    try {
      await UserManager.password(data.password)
      props.onSubmit()
    } catch (err) {
      setErrors(TransformErrorCode(err))
    }
    setIsLoading(false)
  }

  function onChange() {
    setHasErrors(false)
  }

  return (
    <div className="PasswordUpdateModalContent">
      <TopModalSquare>
        <div className="PasswordUpdateModalContent__TopModalSquare__title">
          <Trans ns="user_component">Mot de passe</Trans>
        </div>
      </TopModalSquare>
      <div className="PasswordUpdateModalContent__content">
        <div className="PasswordUpdateModalContent__content__sentence s-top">
          <Trans ns="user_component">Modifie ton mot de passe</Trans>
        </div>
        <div className="PasswordModalContent__content__loginForm l-top">
          <PasswordForm
            onSubmit={onSubmit.bind(this)}
            hasErrors={hasErrors}
            errorNode={errors}
            onChange={onChange.bind(this)}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}

PasswordUpdateModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onRegister: PropTypes.func,
  onLostPassword: PropTypes.func,
  topElement: PropTypes.element,
  error: PropTypes.string
}

PasswordUpdateModalContent.defaultProps = {
  onSubmit: (() => {}),
  onRegister: (() => {}),
  onLostPassword: (() => {})
}

export default PasswordUpdateModalContent