import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './ExternalWebSiteModalContent.scss'

import { actions } from '../../../../../redux';

import meetupLogo from '../../../../../Pictures/Logos/meetup.png'
import eventBriteLogo from '../../../../../Pictures/Logos/eventbrite.png'

import RegisterTopModalContent from './RegisterTopModalContent';
import TopModalSquare from '../../../Elements/TopModalSquare';

function ExternalWebSiteModalContent(props) {

    function hideModal() {
      props.hide()
    }

    function getWebsiteName(websiteType) {
      switch(websiteType) {
        case "meetup":
          return "Meetup"
        case "eventbrite":
          return "Eventbrite"
        default:
          return null
      }
    }

    function getWebsitePicture(websiteType) {
      switch(websiteType) {
        case "meetup":
          return meetupLogo
        case "eventbrite":
          return eventBriteLogo
        default:
          return undefined
      }
    }

    const hasWebsiteName = getWebsiteName(props.externalLink.typeName)
    const websiteName = hasWebsiteName || "un site externe"
    const websitePicture = getWebsitePicture(props.externalLink.typeName)

    return (
        <div id="ExternalWebSiteContent">
          <TopModalSquare>
            <RegisterTopModalContent challenge={props.challenge} />
          </TopModalSquare>
          <div className="ExternalWebSiteContent__contentRow">
            <div className="ExternalWebSiteContent__contentRow__title l-top">Tu dois t'inscrire sur {websiteName} pour participer à cet événement</div>
            {(websitePicture) ? (
              <div className="ExternalWebSiteContent__contentRow__imageRow">
                <img src={websitePicture} alt="" className="ExternalWebSiteContent__contentRow__imageRow__image"/>
              </div>
            ) : (null)}
            <div className="ExternalWebSiteContent__contentRow__buttons l-top">
                <button className="blue validation" onClick={props.onRegister}>
                  {(hasWebsiteName) ? (
                    `S'inscrire sur ${websiteName}`
                  ) : (
                    `S'inscrire sur le site`
                  )}
                </button>
                <button className="cancel" onClick={hideModal}>Annuler</button>
            </div>
          </div>
        </div>
    );
}

ExternalWebSiteModalContent.propTypes = {
  onRegister: PropTypes.func,
  externalLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired
  }).isRequired,
  challenge: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    community: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

ExternalWebSiteModalContent.defaultProps = {
  onRegister: (() => {}),
  challenge: {
    community: {}
  }
}

export default connect(null, (dispatch) => { return {
  show: actions.modal.show.bind(null, dispatch),
  hide: actions.modal.hide.bind(null, dispatch)
}})(ExternalWebSiteModalContent);
