import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import CommunityAction from './CommunityAction'

import user from './src/user'
import defaultModal from './src/defaultModal'
import api from './src/api'
import translation from './src/translation'

export const actions = {
    user: user.actions,
    modal: defaultModal.actions,
    api: api.actions,
    translation: translation.actions
}

export const rootPersistReducer = combineReducers({
    user: user.reducer,
    community: CommunityAction.reducer,
    modal: defaultModal.reducer,
    api: api.reducer,
    translation: translation.reducer
})

export const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    blacklist: [
        'community',
        'modal',
        'api',
        'translation'
    ]
}

export default {
    persistedReducer: persistReducer(persistConfig, rootPersistReducer),
    actions:actions
}