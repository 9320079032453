import React from 'react'
import PropTypes from 'prop-types'
import './SignUpModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'
import SignUpForm from '../../../../Pages/User/SignUpForm'
import { Trans } from 'react-i18next'

function SignUpModalContent(props) {

  return (
    <div className="SignUpModalContent">
      <TopModalSquare>
        <div className="SignUpModalContent__TopModalSquare__title">
          <Trans ns="login_component">Créer un compte</Trans>
        </div>
      </TopModalSquare>
      <div className="SignUpModalContent__content">
        <div className="SignUpModalContent__content__sentence s-top">
          <Trans ns="login_component">Participe à tous les événements sur Mixlab !</Trans>
        </div>
        <div className="SignUpModalContent__content__SignUp l-top">
          <SignUpForm 
            onSubmit={props.onSubmit}
          />
        </div>
        <div className="SignUpModalContent__content__actions l-top">
          <div 
            className="SignUpModalContent__content__actions__signIn s-top clickable"
            onClick={() => props.onLogin()}
          >
            <Trans ns="login_component">J'ai déja un compte</Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

SignUpModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onLogin: PropTypes.func
}

SignUpModalContent.defaultProps = {
  onSubmit: (() => {}),
  onLogin: (() => {})
}

export default SignUpModalContent