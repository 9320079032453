import React, {useState} from 'react'
import PropTypes from 'prop-types'

import './LostPasswordModalContent.scss'

import FormChecker from '../../../../../Helpers/FormChecker'
import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'

import UserManager from '../../../../../Manager/UserManager'

import TopModalSquare from '../../../Elements/TopModalSquare'
import LostPasswordForm from '../../../../Pages/User/LostPasswordForm'
import { Trans } from 'react-i18next'

function checkErrors(data) {
  let errorsNode = []
  let emailChecker = FormChecker.check(data.email, {
    type: "email"
  })
  if (!data.email || emailChecker.error)
    errorsNode.push(<div key="emailError"><Trans ns="errors">L'email est obligatoire et doit être valide.</Trans></div>)

  return errorsNode
}

function LostPasswordModalContent(props) {
  const [errors, setErrors] = useState(props.error ? <div>{props.error}</div> : null)
  const [hasErrors, setHasErrors] = useState(!!(props.error))
  const [isLoading, setIsLoading] = useState()

  async function onSubmit(data) {
    let errorsNode = checkErrors(data)
    if (errorsNode.length > 0) {
      setHasErrors(true)
      return setErrors(errorsNode)
    }
    setIsLoading(true)
    try {
      await UserManager.forgetPassword(data.email, props.challengeId)
      props.onSubmit()
    } catch (err) {
      setHasErrors(true)
      setErrors(TransformErrorCode(err))
    }
    setIsLoading(false)
  }

  function onChange() {
    setHasErrors(false)
  }

  return (
    <div className="LostPasswordModalContent">
      <TopModalSquare>
        <div className="LostPasswordModalContent__TopModalSquare__title">
          <Trans ns="login_component">Mot de passe oublié</Trans>
        </div>
      </TopModalSquare>
      <div className="LostPasswordModalContent__content">
        <div className="LostPasswordModalContent__content__sentence s-top">
          <Trans ns="login_component">Indique ton email afin de réinitialiser ton mot de passe</Trans>
        </div>
        <div className="LostPasswordModalContent__content__SignUp l-top">
          <LostPasswordForm 
            isLoading={isLoading}
            onSubmit={onSubmit}
            hasErrors={hasErrors}
            errorNode={errors}
            onChange={onChange.bind(this)}
          />
        </div>
        <div className="LostPasswordModalContent__content__actions l-top">
          <div 
            className="LostPasswordModalContent__content__actions__signIn s-top clickable"
            onClick={() => props.onLogin()}
          >
            <Trans>Retour</Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

LostPasswordModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onLogin: PropTypes.func,
  challengeId: PropTypes.string
}

LostPasswordModalContent.defaultProps = {
  onSubmit: (() => {}),
  onLogin: (() => {})
}

export default LostPasswordModalContent