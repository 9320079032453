import React, {useState, useEffect} from 'react'
import './ProfileSettingsPage.scss'
import { connect } from 'react-redux'
import {actions} from '../../../redux'
import {withRouter} from 'react-router-dom'

import TransformErrorCode from '../../../Helpers/TransformErrorCode'

import UserManager from '../../../Manager/UserManager'

import NavBar from '../../Components/Elements/ChallengeNavBar'
import PasswordUpdateModalContent from '../../Components/Modals/contents/User/PasswordUpdateModalContent'
import ProfileForm from './ProfileForm'
import ScrollTo from '../../../Helpers/ScrollTo'
import DeleteConfirmModalContent from '../../Components/Modals/contents/User/DeleteConfirmModalContent'
import { Trans } from 'react-i18next'
import ProfileNavBar from './ProfileNavBar'

function ProfileSettingsPage(props) {
  const [errorNode, setErrorNode] = useState()
  const [hasErrors, setHasErrors] = useState()
  const [isLoadingProfile, setIsLoadingProfile] = useState()
  const [errorForm, setErrorForm] = useState({})

  function checkError(form) {
    if (!form) return [(<div>Impossible de trouver le formulaire</div>)]
    let errorNode = []
    let errorFormObject = {...errorForm}
    if (!form.name) {
      errorFormObject = {...errorFormObject, name: true}
      errorNode.push(<div key="1">- Ton nom est obligatoire</div>)
    }
    if (!form.surname) {
      errorFormObject = {...errorFormObject, surname: true}
      errorNode.push(<div key="2">- Ton prénom est obligatoire</div>)
    }
    if (!form.job) {
      errorFormObject = {...errorFormObject, job: true}
      errorNode.push(<div key="3">- Ton job est obligatoire</div>)
    }
    if (!form.enterprise) {
      errorFormObject = {...errorFormObject, enterprise: true}
      errorNode.push(<div key="4">- Ton entreprise est obligatoire</div>)
    }
    setErrorForm(errorFormObject)
    return errorNode.length > 0 ? errorNode : null
  }

  async function onSubmit(form) {
    let errorNode = checkError(form)
    if (errorNode) {
      setHasErrors(true)
      return setErrorNode(errorNode)
    }

    setIsLoadingProfile(true)
    let user = {
      ...props.user,
      skills: form.skills,
      name: form.name,
      surname: form.surname,
      job: form.job,
      enterprise: form.enterprise
    }
    form.skills = Array.isArray(form.skills) && form.skills.map(e=>e._id) 
    form.image = form.image && form.image.imageFile
    try {
      await UserManager.patchMe(form)
      if (form.image) {
        let patchRequest = await UserManager.patchImage(form.image)
        if (patchRequest.data)
          user.imageUrl = patchRequest.data.url
      }
      props.setUser(user)
    } catch (err) {
      setHasErrors(true)
      setErrorNode(TransformErrorCode(err))
    }
    setIsLoadingProfile(false)
  }

  function changePasswordModal(){
    props.showModal(
        <PasswordUpdateModalContent 
          onSubmit={props.hideModal}
        />
    )
  }

  function logout() {
    props.redirectOn401(false)
    props.logout()
    ScrollTo()
    props.history.push('/')
  }

  function deleteAccount() {
    props.showModal(
      <DeleteConfirmModalContent onSubmit={logout} />
    )
  }

  useEffect(() => {
    if (props.forgetPassword)
      changePasswordModal()
    props.redirectOn401(true)
    return () => {
      props.redirectOn401(false)
    }
  }, [])

  return (
    <div className="ProfileSettingsPage">
      <NavBar />
      <div className="xxl-top ProfilePage__content">
        <ProfileNavBar pageName="profile" className="l-bottom"/>
        <div className="ProfilePage__content__title"><Trans ns="user_component">Modifier mon compte</Trans></div>
        <ProfileForm
          user={props.user}
          onSubmit={onSubmit}
          errorNode={errorNode}
          hasErrors={hasErrors}
          errorForm={errorForm}
          onChange={(name) => {
            setHasErrors(false); 
            errorForm[name] = false;
            setErrorForm(errorForm)
          }}
          isLoading={isLoadingProfile}
        />
        <div className="ProfileSettingsPage__back s-top">
          <button className="back" onClick={() => props.history.push('/profile')}><Trans>Retour</Trans></button>
        </div>
        <div className="ProfileSettingsPage__subButtons xl-top">
          <button
            className="ProfileSettingsPage__subButtons__button blue text s-top"
            onClick={changePasswordModal}
          >
            <Trans ns="user_component">Modifier mon mot de passe</Trans>
          </button>
          <button
            className="ProfileSettingsPage__subButtons__button blue text s-top"
            onClick={logout}
          >
            <Trans ns="user_component">Se déconnecter</Trans>
          </button>
          <button
            className="ProfileSettingsPage__subButtons__button destructive text s-top"
            onClick={deleteAccount}
          >
            <Trans ns="user_component">Supprimer mon compte</Trans>
          </button>
        </div>
        <br/>
        <br/>
      </div>
    </div>
  )
}

export default connect((state) => ({
  user: state.user.profile
}), (dispatch) => ({
  setUser: actions.user.setUser.bind(null, dispatch),
  logout: actions.user.logout.bind(null, dispatch),
  showModal: actions.modal.show.bind(null, dispatch),
  hideModal: actions.modal.hide.bind(null, dispatch),
  redirectOn401: actions.user.redirectOn401.bind(null, dispatch)
}))(withRouter(ProfileSettingsPage))