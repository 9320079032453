import React from 'react'
import './TopModalSquare.scss'

function TopModalSquare(props) {
  return (
    <div className="TopModalSquare">
        {props.children}
    </div>
  )
}

export default TopModalSquare