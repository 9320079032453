import React, { Component } from 'react';

class CountDown extends Component {

    constructor(props){
        super(props);
        this.state = {
            startDate: props.startDate ? props.startDate : new Date(),
            todayDate: (new Date()).getTime(),
            onEnd: props.onEnd ? props.onEnd : (() => {})
        };
        this.update()
    }

    componentWillReceiveProps(props){
        this.setState({
            startDate: props.startDate ? props.startDate : new Date(),
            todayDate: (new Date()).getTime(),
            onEnd: props.onEnd ? props.onEnd : (() => {})
        }, () => {
            this.update()
        });
    }

    update(){
        this.timeout = setInterval(() => {
            if (this.setState instanceof Function) {
                this.setState({todayDate: (new Date()).getTime()})
            }
        }, 1000)
    }

    countDown(startDateString){
        var startDate = new Date(startDateString)
        if (startDate) {
            var diff = diff = startDate.getTime() - this.state.todayDate
            if (startDate <= new Date()) {
                diff = this.state.todayDate - startDate.getTime()
                this.state.onEnd()
            }
            var years = Math.floor(diff / 31540000000)
            var yearsMod = diff % 31540000000
            var months = Math.floor(yearsMod / 2628000000)
            var monthsMod = yearsMod % 2628000000
            var days = Math.floor(monthsMod / 86400000)
            var daysMod = monthsMod % 86400000
            var hours = Math.floor(daysMod / 3600000)
            var hoursMod = daysMod % 3600000
            var minutes = Math.floor(hoursMod / 60000)
            var minutesMod = hoursMod % 60000
            var seconds = Math.floor(minutesMod / 1000)
            return (years > 0 ? years + "a " : "") + (months > 0 ? months + "M " : "") + (days ? days + "j " : "") + (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "") + (seconds + "s")
        }
        return "À vie"
    }

    componentWillUnmount(){
        if (this.timeout) {
            clearInterval(this.timeout)
        }
    }

  render() {
      var timeString = "À vie"
      let startDate = new Date(this.state.startDate)
      if (startDate) {
          let yearMore = new Date()
          yearMore.setFullYear(yearMore.getFullYear() + 1)
          if (startDate > yearMore) {
              timeString = "plus d'un an !"
          } else {
              timeString = this.countDown(startDate)
          }
      }
    return (<span>
        {timeString}
        </span>
    );
  }
}

export default CountDown;
