/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'

class CommunityManager extends ApiManager {

    static checkName(name, cancelCallback){
        return ApiManager.requester('post', '/public/community', {
            name:name
        }, cancelCallback)
    }

    static treedSecure(body) {
        return ApiManager.requester('post', '/public/community/charge', body)
    }

    static send(body) {
        return ApiManager.requester('post', '/public/community/create', body)
    }

    static me() {
        return ApiManager.requester('get', '/public/community/me')
    }
}

export default CommunityManager;