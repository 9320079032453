import "./ChallengePageContent.scss";
import config from "../../../config.json";
import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Linkifier from "react-linkifier";
import { Trans } from "react-i18next";
import {
  GoogleMap,
  Circle,
  withGoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { Helmet } from "react-helmet";

import Translation from "../../../Helpers/Translation";

import PinMapImage from "../../../Pictures/icons/icon_mapPin.svg";
import publicIllustration from "../../../Pictures/illustrations/illu_public.png";
import thumbnailImage from "../../../Pictures/illustrations/thumbnail.png";

import ChallengePageBottomBar from "./ChallengePageBottomBar";
import ChallengeNavBar from "../../Components/Elements/ChallengeNavBar";
import ChallengeInformations from "./ChallengeInformations";
import ChallengeHourRange from "./ChallengeHourRange";
import ChallengePriceLabel from "./ChallengePriceLabel";

const GoogleMapComp = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultZoom={15}
        options={{
          scrollwheel: false,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
        }}
        defaultCenter={{ lat: props.location.lat, lng: props.location.long }}
      >
        {props.pin ? (
          <Marker
            defaultIcon={PinMapImage}
            position={{ lat: props.location.lat, lng: props.location.long }}
          />
        ) : (
          <Circle
            options={{
              fillColor: "rgba(47,120,255,0.5)",
              strokeColor: "#2F78FF",
            }}
            radius={200}
            center={{ lat: props.location.lat, lng: props.location.long }}
          />
        )}
      </GoogleMap>
    );
  })
);

function extractImageForMeta(url) {
  if (!url) return thumbnailImage;
  let urlObject = new URL(url);
  let splitExtention = urlObject.pathname.split(".");
  let extension = splitExtention[splitExtention.length - 1];
  if (extension === "gif" || extension === "webp") {
    return thumbnailImage;
  }
  return url;
}

function ChallengePageContent(props) {
  const challenge = props.challenge;
  let fullDateString = moment(challenge.startDate).format(
    Translation.shared.t("dateformat|dddd Do MMMM YYYY")
  );

  function onLogin(page) {
    if (page === "login") props.triggerReload();
  }

  let title = (challenge && challenge.title) || "Mixlab";
  let description =
    (challenge && challenge.description) ||
    Translation.shared.t(
      "event_component|Simplifiez et automatisez la promotion de vos événements dans votre communauté"
    );
  let imageMeta = extractImageForMeta(challenge && challenge.coverUrl);

  return (
    <div className="ChallengePageContent">
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Mixlab" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageMeta} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageMeta} />
      </Helmet>
      <ChallengeNavBar onSubmit={onLogin} />
      <div className="ChallengePageContent__dateAndCommunity xxl-top">
        <div className="ChallengePageContent__dateAndCommunity__communityCircle">
          <div className="ChallengePageContent__dateAndCommunity__communityCircle__container">
            <img
              className="ChallengePageContent__dateAndCommunity__communityCircle__container__image"
              src={challenge.community && challenge.community.imageUrl}
              alt=""
            />
          </div>
        </div>
        <div className="ChallengePageContent__dateAndCommunity__fullDateStringRow">
          <div className="ChallengePageContent__dateAndCommunity__fullDateStringRow__dateStringRow">
            {fullDateString}
          </div>
          <div className="ChallengePageContent__dateAndCommunity__fullDateStringRow__hourStringRow">
            <ChallengeHourRange
              date={challenge.startDate}
              endDate={challenge.endDate}
              timezone={
                challenge.address && challenge.address.timezoneId
                  ? challenge.address.timezoneId
                  : null
              }
              className="ChallengePageContent__dateAndCommunity__fullDateStringRow__hourStringRow"
            />
          </div>
        </div>
      </div>
      {challenge.tickets && (
        <ChallengePriceLabel
          tickets={challenge.tickets}
          className="xs-top s-bottom"
        />
      )}
      <div className="ChallengePageContent__title">{challenge.title}</div>
      {challenge.coverUrl ? (
        <div className="ChallengePageContent__coverRow l-top">
          <img
            className="ChallengePageContent__coverRow__image"
            src={challenge.coverUrl}
            alt=""
          />
        </div>
      ) : null}
      <div className="ChallengePageContent__description l-top">
        <Linkifier
          onClick={(e) => {
            e.preventDefault();
            window.open(e.target.href, "_blank");
          }}
          className="link"
        >
          {challenge.description}
        </Linkifier>
      </div>
      {challenge.status !== "canceled" &&
        challenge.status !== "done" &&
        challenge.status !== "finished" && (
          <Fragment>
            {challenge.meetingPoint &&
              challenge.meetingPoint.address &&
              challenge.meetingPoint.address.location && (
                <div className="l-top">
                  <GoogleMapComp
                    location={challenge.meetingPoint.address.location}
                    pin={!challenge.meetingPoint.isRandom}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div
                        style={{
                          height: `180px`,
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                      />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.googleMapPublicKey}`}
                  />
                </div>
              )}
            <ChallengeInformations
              className="l-top"
              isWebinar={challenge.meetingPoint.isWebinar}
              address={challenge.meetingPoint.address}
              random={challenge.options.randomEvent}
              secretInfos={challenge.secretInfos}
            />
            {/* <div className="ChallengePageContent__attendeesTitle l-top">
            Participants 
            <span className="ChallengePageContent__attendeesTitle__dot">{challenge.attendeesCount}{challenge.maxPlaces !== -1 ? `/${challenge.maxPlaces}` : ""}</span>
          </div> */}
            <div className="ChallengePageContent__publicLinkContent l-top">
              <div className="ChallengePageContent__publicLinkContent__imageRow">
                <img
                  className="ChallengePageContent__publicLinkContent__imageRow__image"
                  src={publicIllustration}
                  alt="mixlab public"
                />
              </div>
              <div className="ChallengePageContent__publicLinkContent__text">
                <Trans ns="event_component">
                  Cet événement est public, partage ce lien sur tes réseaux !
                </Trans>
                <br />
                {challenge.options && challenge.options.public && (
                  <a
                    href={`https://mixlab.fr/e/${challenge.options.public.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`https://mixlab.fr/e/${challenge.options.public.id}`}</a>
                )}
              </div>
            </div>
          </Fragment>
        )}
      {!challenge.isOrganizer && (
        <div className="ChallengePageContent__referrer l-top">
          <div className="ChallengePageContent__referrer__logoWrapper">
            <a
              href="https://mixlab.fr"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="ChallengePageContent__referrer__logoWrapper__logoContainer" />
            </a>
          </div>
          <a
            href="https://mixlab.fr"
            rel="noopener noreferrer"
            target="_blank"
            className="ChallengePageContent__referrer__link invisible"
          >
            <div className="ChallengePageContent__referrer__text">
              <Trans ns="event_component">
                Besoin d'animer votre communauté en toute simplicité ?
              </Trans>
            </div>
          </a>
          <div className="ChallengePageContent__referrer__button">
            <a
              href="https://mixlab.fr"
              rel="noopener noreferrer"
              target="_blank"
            >
              <button className="blue">
                <Trans ns="event_component">En savoir plus</Trans>
              </button>
            </a>
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
      <ChallengePageBottomBar
        isLoading={props.isLoadingBottomBar}
        error={props.bottomBarError}
        onClickAgenda={props.onClickAgenda}
        onRegister={props.onRegister}
        challenge={challenge}
      />
    </div>
  );
}

ChallengePageContent.propTypes = {
  onClickAgenda: PropTypes.func,
  triggerReload: PropTypes.func,
  onRegister: PropTypes.func,
  isLoadingBottomBar: PropTypes.bool,
  bottomBarError: PropTypes.string,
};

ChallengePageContent.defaultProps = {
  triggerReload: () => {},
  onRegister: () => {},
  onClickAgenda: () => {},
};

export default ChallengePageContent;
