import React, {useState}from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import './SignUpRegisterModalContent.scss'

import ChallengeSignUpForm from '../../../../Pages/Challenge/ChallengeSignUpForm'
import TopModalSquare from '../../../Elements/TopModalSquare'

import { actions } from '../../../../../redux'

import UserManager from '../../../../../Manager/UserManager'

import FormChecker from '../../../../../Helpers/FormChecker'
import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'

function checkErrors(data, passwordForce) {
  let errorsNode = []
  let emailChecker = FormChecker.check(data.email, {
    type: "email"
  })
  if (!data.email || emailChecker.error)
    errorsNode.push(<div key="emailError"><Trans ns="errors">L'email est obligatoire et doit être valide</Trans></div>)
  if (!(data.name && String(data.name).trim()))
    errorsNode.push(<div key="nameError"><Trans ns="errors">Ton nom est requis</Trans></div>)
  if (!(data.surname && String(data.surname).trim()))
    errorsNode.push(<div key="surnameError"><Trans ns="errors">Ton prénom est requis</Trans></div>)
  if (!data.password)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Le mot de passe est obligatoire !</Trans></div>)
  else if (passwordForce < 25)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Ton mot de passe est trop court</Trans></div>)
  return errorsNode
}

function SignUpRegisterModalContent(props) {
  const [errors, setErrors] = useState()
  const [isLoading, setIsLoading] = useState()
  const [hasErrors, setHasErrors] = useState()

  async function onSubmit(data, passwordForce) {
    let errorsNode = checkErrors(data, passwordForce)
    if (errorsNode.length > 0) {
      setHasErrors(true)
      return setErrors(errorsNode)
    }
    setIsLoading(true)
    try {
      let request = await UserManager.signUp(data.name, data.surname, data.email, data.password, props.challengeId)
      if (request.data) {
        let emailSent = request.data.emailSent || false
        if (!emailSent)
          props.setToken(request.data)
        props.onSubmit(emailSent)
      }
    } catch (err) {
      let errorText = TransformErrorCode(err)
      if (err && err.response && err.response.data && err.response.data.code === "already_signup_user")
        return props.onLogin(errorText)
      setErrors(errorText)
    }
    setIsLoading(false)
  }

  function onChange() {
    setHasErrors(false)
  }

  return (
    <div className="SignUpRegisterModalContent">
      <TopModalSquare>
        {props.topElement}
      </TopModalSquare>
      <div className="SignUpRegisterModalContent__content">
        <div className="SignUpRegisterModalContent__content__sentence s-top">
          <Trans ns="login_component">Remplis tes informations pour participer à l'événement</Trans>
        </div>
        <div className="SignUpRegisterModalContent__content__SignUp l-top">
          <ChallengeSignUpForm 
            onSubmit={onSubmit}
            hasErrors={hasErrors}
            errorNode={errors}
            onChange={onChange}
            isLoading={isLoading}
          />
        </div>
        <div className="SignUpRegisterModalContent__content__actions s-top">
          <button 
            className="SignUpRegisterModalContent__content__actions__signIn grey text"
            onClick={() => props.onLogin()}
          >
            <Trans ns="login_component">J'ai un compte</Trans>
          </button>
        </div>
      </div>
    </div>
  )
}

SignUpRegisterModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onLogin: PropTypes.func,
  challengeId: PropTypes.string,
  topElement: PropTypes.element
}

SignUpRegisterModalContent.defaultProps = {
  onSubmit: (() => {}),
  onLogin: (() => {})
}

export default connect(null, (dispatch) => ({
  setToken: actions.user.login.bind(null, dispatch)
}))(SignUpRegisterModalContent)