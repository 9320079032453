/**
 * Created by larry on 02/04/2018.
 */
import PageSupport from '../Helpers/PageSupport'
import ApiManager from './ApiManager'

class UserManager extends ApiManager {

  static login(email, password){
      return ApiManager.requester('post', '/public/user/login', {
        email, password
      })
  }

  static forgetPassword(email, challengeId) {
    return ApiManager.requester('post', '/public/user/forget', {
      email,
      challengeId
    })
  }

  static signUp(name, surname, email, password, challengePublicId){
    return ApiManager.requester('post', '/public/user/signup', {
      name, surname, email, password, challengePublicId
    })
  }

  static exchangeCode(code){
    return ApiManager.requester('post', '/public/user/login/code', {
      code
    })
  }

  static resendCode(code, redirect){
    return ApiManager.requester('post', '/public/user/login/code/retry', {
      code, redirect
    })
  }

  static password(password) {
    return ApiManager.requester('post', '/public/user/password', {
      password
    })
  }

  static me(){
    return ApiManager.requester('get', '/public/user/me')
  }

  static patchMe(form) {
    return ApiManager.requester('patch', '/public/user/me', form)
  }

  static patchImage(image) {
    let formData = new FormData();
    formData.append("image", image);
    return ApiManager.requester('patch', '/public/user/image', formData, {
        'Content-Type': 'multipart/form-data'
    })
  }

  static deleteAccount() {
    return ApiManager.requester('delete', '/public/user/me')
  }

  static getInvoicePDF(chargeId) {
    return ApiManager.requester('get', `/public/user/invoices/${chargeId}`)
  }

  static getInvoices(page, size) {
    return ApiManager.requester('get', `/public/user/invoices${PageSupport(page, size)}`)
  }
}

export default UserManager;