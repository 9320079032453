/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'

class PaymentManager extends ApiManager {

    static getCoupon(coupon) {
        return ApiManager.requester('get', '/public/payment/coupon/' + coupon)
    }

    static notifyCoupon(coupon) {
        return ApiManager.requester('get', `/public/payment/coupon/${coupon}/used`)
    }

    static getPlan(code) {
        return ApiManager.requester('get', `/public/payment/plan/${code}`)
    }

    static subscribe(email) {
        return ApiManager.requester('post', `/public/payment/subscribe`, {email})
    }

    static intent(body) {
        return ApiManager.requester('post', '/public/community/intent', body)
    }

}

export default PaymentManager;