import React, { Component } from 'react';
import './SideText.scss';
import PropTypes from 'prop-types';

class SideText extends Component {
    render() {
        return (
            <div id="SideText" className={(this.props.background) ? "background" : ""}>
                {this.props.background ? (
                    <div className="circle top"></div>
                ) : (null)}
                <div className={"container " + ((this.props.textSide === "left") ? ("reversed") : (""))}>
                    <div className="right" data-aos={this.props.noAOS ? undefined : this.props.textSide === "left" ? "fade-left" : "fade-right"}>
                        <div className="illustration">
                            <img alt="illustration" src={this.props.illustration}/>
                        </div>
                    </div>
                    <div className="left" data-aos={this.props.noAOS ? undefined : this.props.textSide === "left" ? "fade-right" : "fade-left"}>
                        {this.props.children || (null)}
                    </div>
                </div>
                {this.props.background ? (
                    <div className="circle bottom"></div>
                ) : (null)}
            </div>
        )
    }
}

SideText.defaultProps = {
    textSide: 'right'
};

SideText.propTypes = {
    textSide: PropTypes.oneOf([
        'left', 'right'
    ]),
    background: PropTypes.bool,
    illustration: PropTypes.string,
    children: PropTypes.array.isRequired,
    noAOS: PropTypes.bool
}

export default SideText;
