import React from 'react';
import './PlaceholderImage.scss';
import PropTypes from 'prop-types'

function getColor(surname) {
  if (!surname) return "white"
  let lastLetter = String(surname).charCodeAt(surname.length - 1)
  if (lastLetter % 6 === 0)
    return "#6C00FF"
  if (lastLetter % 5 === 0)
    return "#00C353"
  if (lastLetter % 4 === 0)
    return "#D71862"
  if (lastLetter % 3 === 0)
    return "#2F78FF"
  if (lastLetter % 2 === 0)
    return "#FF8F00"
  return "#00E0C2"
}

function PlaceholderImage(props) {
  let color = getColor(props.surname)
  return (
    <div 
      className={`PlaceholderImage ${!props.surname ? "empty" : ""}`}
      style={{
        backgroundColor: color,
        textDecoration: `underline ${color}`
      }}
    >
      {(String(props.surname).charAt(0) || "P").toUpperCase()}
    </div>
  );
}

PlaceholderImage.propTypes = {
  surname: PropTypes.string
}

export default PlaceholderImage;
