import React from 'react';
import PropTypes from 'prop-types'

import './AgendaFiles.scss';

import iconGoogle from '../../../../Pictures/icons/Agenda/icon_Google.png'
import iconICal from '../../../../Pictures/icons/Agenda/icon_iCal.png'
import iconOutlook from '../../../../Pictures/icons/Agenda/icon_outlook.png'
import ChallengeManager from "../../../../Manager/ChallengeManager";

async function redirectTo(challengeId, type) {
    if (!challengeId) return
    try {
        let linkRequest = await ChallengeManager.getCalendarLink(challengeId, type)
        window.open(linkRequest.data, '_blank');
    } catch (err) {
        console.log("Error during getting calendar :", err)
    }
}

function AgendaFiles(props) {
    let challengePublicId = props.challenge && props.challenge.options && props.challenge.options.public && props.challenge.options.public.id
    return (
        <div className={`AgendaFiles ${props.className}`}>
            <div
                className="AgendaFiles__row"
            >
                <div className="AgendaFiles__row__type">
                    <div className="AgendaFiles__row__type__content" onClick={() => {
                        redirectTo(challengePublicId, 'google')
                    }}>
                        <img src={iconGoogle} alt="" />
                        <p>Google</p>
                    </div>
                </div>
                <div className="AgendaFiles__row__type">
                    <div className="AgendaFiles__row__type__content" onClick={() => {
                        redirectTo(challengePublicId, 'ical')
                    }}>
                        <img src={iconICal} alt="" />
                        <p>iCal</p>
                    </div>
                </div>
                <div className="AgendaFiles__row__type">
                    <div className="AgendaFiles__row__type__content" onClick={() => {
                        redirectTo(challengePublicId, 'outlook')
                    }}>
                        <img src={iconOutlook} alt="" />
                        <p>Outlook</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

AgendaFiles.propTypes = {
    challenge: PropTypes.shape({
        options: PropTypes.shape({
            public: PropTypes.shape({
                id: PropTypes.string
            })
        })
    })
}
  
AgendaFiles.defaultProps = {
challenge: {
    options: {
        public: {}
    }
}
}

export default AgendaFiles;
