import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import './RegisterTopModalContent.scss'

import ChallengeHourRange from '../../../../Pages/Challenge/ChallengeHourRange'

import validImage from '../../../../../Pictures/icons/icon_true_white.svg'
import Translation from '../../../../../Helpers/Translation'

function RegisterTopModalContent(props) {
  let startDate = moment(props.challenge.startDate).format(Translation.shared.t("dateformat|dddd Do MMMM YYYY"))

  return (
    <div className="RegisterTopModalContent">
      <div className="RegisterTopModalContent__startDate">
        {startDate}
      </div>
      <ChallengeHourRange
        date={props.challenge.startDate}
        endDate={props.challenge.endDate}
        noIco
      />
      <div className="RegisterTopModalContent__logoContainer">
        {props.isValid ? (
          <div className="RegisterTopModalContent__logoContainer__communityLogo">
            <div className="RegisterTopModalContent__logoContainer__communityLogo__backgroundValid">
              <img alt={`tick mixlab`} src={validImage} className="RegisterTopModalContent__logoContainer__communityLogo__backgroundValid__image"/>
            </div>
          </div>
        ) : (
          <div className="RegisterTopModalContent__logoContainer__communityLogo">
            <img alt={`${props.challenge.community.name} logo`} src={props.challenge.community.imageUrl} className="RegisterTopModalContent__communityLogo__image"/>
          </div>
        )}
        
      </div>
    </div>
  )
}

RegisterTopModalContent.propTypes = {
  isValid: PropTypes.bool,
  challenge: PropTypes.shape({
    startDate: PropTypes.string,
    options: PropTypes.shape({
      public: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    community: PropTypes.shape({
      imageUrl: PropTypes.string
    })
  })
}

RegisterTopModalContent.defaultProps = {
  challenge: {
    options: {
      public:{}
    },
    community: {}
  }
}

export default RegisterTopModalContent