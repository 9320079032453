import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import './SignUpForm.scss'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import Translation from '../../../Helpers/Translation'
import { Trans } from 'react-i18next'

function SignUpForm(props) {
  const {register, handleSubmit} = useForm()
  return (
    <form 
      className="SignUpForm"
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <input
        name="email"
        ref={register}
        type="text"
        autoComplete="email"
        className="SignUpForm__input"
        placeholder={Translation.shared.t("login_component|Email")}
        onChange={props.onChange.bind(null, "email")}
      />
      <div className={`SignUpForm__errors ${props.hasErrors ? "open" : ""}`}>
        {props.errorNode}
      </div>
      {props.isLoading ? (
        <LoadingSpinner 
          size="15px"
          className="s-top"
          small
        />
      ) : (
        <button className="SignUpForm__button green l-top">
          <Trans ns="login_component">Créer</Trans>
        </button>
      )}
    </form>
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool
}

SignUpForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {}),
}

export default SignUpForm