import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux'
import { CommunityAction } from '../../../redux/actions'
import './PlanSelection.scss';
import NavBar from './NavBar'
import ScrollTo from '../../../Helpers/ScrollTo';
import config from '../../../config.json';

import iconCrown from '../../../Pictures/icons/icon_premium.png'

import iconApi from '../../../Pictures/icons/plan/icon_api.png'
import iconIntern from '../../../Pictures/icons/plan/icon_intern.png'
import iconPublic from '../../../Pictures/icons/plan/icon_public.png'
import iconCheckIn from '../../../Pictures/icons/plan/icon_checkin.png'
import iconDashboard from '../../../Pictures/icons/plan/icon_dashboard.png'
import iconDebrief from '../../../Pictures/icons/plan/icon_debrief.png'
import iconExport from '../../../Pictures/icons/plan/icon_export.png'
import iconFeedback from '../../../Pictures/icons/plan/icon_feedback.png'
import iconInvitation from '../../../Pictures/icons/plan/icon_invitation.png'
import iconModeration from '../../../Pictures/icons/plan/icon_moderation.png'
import iconPersonnalisation from '../../../Pictures/icons/plan/icon_personnalisation.png'
import iconPoll from '../../../Pictures/icons/plan/icon_poll.png'
import iconPrivate from '../../../Pictures/icons/plan/icon_private.png'
import iconRandom from '../../../Pictures/icons/plan/icon_random.png'
import iconSearch from '../../../Pictures/icons/plan/icon_search.png'
import iconSSO from '../../../Pictures/icons/plan/icon_sso.png'
import iconTribe from '../../../Pictures/icons/plan/icon_tribe.png'
import iconIntegration from '../../../Pictures/icons/plan/icon_integration.png'
import iconTrombi from '../../../Pictures/icons/plan/icon_trombi.png'
import thumbnailImage from '../../../Pictures/illustrations/thumbnail.png'
import { actions } from '../../../redux';
import FooterPage from '../Home/FooterPage';
import Translation from '../../../Helpers/Translation';
import { Trans } from 'react-i18next';

class PlanSelection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            planName: props.planName
        }
    }

    UNSAFE_componentWillMount() {
        if(!this.props.keepScroll)
            ScrollTo()
        if (this.props.communityUrl)
            this.props.saveCommunity({
                name: this.props.communityUrl
            })
    }

    goToPlan(index) {
        this.props.savePlan(config.plans[index])
        this.props.history.push(`/community`)
    }

    render() {
        let title = Translation.shared.t("pricing_component|Tarifs - Mixlab")
        let description = Translation.shared.t("pricing_component|Simplifiez et automatisez la promotion de vos événements dans votre communauté")
        return (
            <div className="PlanSelection">
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title}/>
                    <meta name="description" content={description}/>

                    {/* <!-- Open Graph / Facebook --> */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content="Mixlab"/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={description}/>
                    <meta property="og:image" content={thumbnailImage}/>

                    {/* <!-- Twitter --> */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:title" content={title}/>
                    <meta property="twitter:description" content={description}/>
                    <meta property="twitter:image" content={thumbnailImage}/>
                </Helmet>
                <NavBar />
                <div className="PlanSelection__formBody">
                    <h2 className="PlanSelection__formBody__title s-bottom xl-top">
                        <Trans ns="pricing_component">Commencez gratuitement votre essai de <span className="blue">14 jours</span></Trans>
                    </h2>
                    <h3><Trans ns="pricing_component">Sans engagement, annulez à tout moment</Trans></h3>
                    {/* <div className="PlanSelection__offer">
                        <div className="PlanSelection__offer__icon">
                            <img src={EmojiGift} alt=""/>
                        </div>
                        <div className="PlanSelection__offer__text">
                            <b>Cadeau :</b> En ce moment, notre accompagnement pour vous faire passer maître de l’animation de communauté à distance est offert avec le forfait Pro. <span className="link" onClick={this.showExplanations.bind(this)}>En savoir plus</span>
                        </div>
                    </div> */}
                    <a href={Translation.getLanguage() === "fr" ? "https://mixlab.typeform.com/to/bmMzoE" : "https://mixlab.typeform.com/to/H6CAAL"} target="_blank" rel="noopener nofollow noreferrer" className="l-top home__hashlink__feature">
                        <button className="blue big">
                            <Trans>Demander une démo</Trans>
                        </button>
                    </a>
                    <div className="PlanSelection__plansContainer l-top">
                        <div className="PlanSelection__plansContainer__elementWrapper">
                            <div className="PlanSelection__plansContainer__elementWrapper__element orange">
                                <div className="PlanSelection__plansContainer__elementWrapper__element__title">Starter</div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__pricing m-top">
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__price">39</span>
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__euroSign">€</span>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__pricing__duration">
                                        <Trans ns="pricing_component">HT / mois</Trans>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__features l-top">
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            100 <Trans ns="pricing_component">membres max</Trans>
                                        </div>
                                    </div>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element m-top">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            <Trans ns="pricing_component">Support</Trans>
                                        </div>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper m-top">
                                    <button onClick={_=>this.goToPlan(0)} className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper__button white border"><Trans ns="pricing_component">Démarrer</Trans></button>
                                </div>
                            </div>
                        </div>


                        <div className="PlanSelection__plansContainer__elementWrapper">
                            <div className="PlanSelection__plansContainer__elementWrapper__element pink">
                                <div className="PlanSelection__plansContainer__elementWrapper__element__title">Pro</div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__pricing m-top">
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__price">89</span>
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__euroSign">€</span>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__pricing__duration">
                                        <Trans ns="pricing_component">HT / mois</Trans>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__features l-top">
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            500 <Trans ns="pricing_component">membres max</Trans>
                                        </div>
                                    </div>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element m-top">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            <a href="#PremiumFeatures">
                                                <Trans ns="pricing_component">Fonctionnalités Premium</Trans>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element m-top">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            <Trans ns="pricing_component">Support</Trans>
                                        </div>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper m-top">
                                    <button onClick={_=>this.goToPlan(1)} className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper__button white border"><Trans ns="pricing_component">Démarrer</Trans></button>
                                </div>
                            </div>
                        </div>


                        <div className="PlanSelection__plansContainer__elementWrapper">
                            <div className="PlanSelection__plansContainer__elementWrapper__element purple">
                                <div className="PlanSelection__plansContainer__elementWrapper__element__title">Expert</div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__pricing m-top">
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__price">149</span>
                                    <span className="PlanSelection__plansContainer__elementWrapper__element__pricing__euroSign">€</span>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__pricing__duration">
                                        <Trans ns="pricing_component">HT / mois</Trans>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__features l-top">
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            1000 <Trans ns="pricing_component">membres max</Trans>
                                        </div>
                                    </div>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element m-top">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            <a href="#PremiumFeatures">
                                            <Trans ns="pricing_component">Fonctionnalités Premium</Trans>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="PlanSelection__plansContainer__elementWrapper__element__features__element m-top">
                                        <div className="PlanSelection__plansContainer__elementWrapper__element__features__element__description">
                                            <Trans ns="pricing_component">Support</Trans>
                                        </div>
                                    </div>
                                </div>
                                <div className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper m-top">
                                    <button onClick={_=>this.goToPlan(2)} className="PlanSelection__plansContainer__elementWrapper__element__buttonWrapper__button white border"><Trans ns="pricing_component">Démarrer</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="PlanSelection__features l-top">
                    <div id="BasicFeatures">
                        <h2>
                            <Trans ns="pricing_component">Les fonctionnalités</Trans>
                        </h2>
                        <div className="PlanSelection__features__container l-top">

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconIntern} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Événements</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Créez tous les types d'événements selon vos besoins</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconPublic} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Webinars</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Faites des événements en ligne en toute simplicité</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconIntegration} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Intégration</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Automatisez la promotion sur vos réseaux</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconInvitation} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Discussions</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Laissez vos membres partager librement par écrit</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconCheckIn} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Check-in</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Validez les présences de chaque participant</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconTribe} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Tribus</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Rassemblez vos membres par centres d'intérêt</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconFeedback} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Feedbacks</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Collectez des retours après chaque événement</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconPoll} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Sondages</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Posez des questions à votre communauté</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconSearch} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Recherche</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Retrouvez un événement en quelques secondes</Trans>
                                    </div>
                                </div>
                            </div>


                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconModeration} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Modération</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Supprimez les contenus ou les membres inappropriés</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container">
                                        <img alt="" src={iconPersonnalisation} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Personnalisation</Trans>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Mettez votre Mixlab aux couleurs de votre communauté</Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="PremiumFeatures">
                        <h2 className="l-top">
                            <Trans ns="pricing_component">Les fonctionnalités Premium</Trans>
                        </h2>
                        <div className="PlanSelection__features__container l-top">
                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconDashboard} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Tableau de bord</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Visualisez toutes les données de votre communauté</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconTrombi} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Trombinoscope</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Offrez à vos membres un réseau professionnel interne</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconPrivate} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Ateliers privés</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Selectionnez les membres autorisés sur vos événements</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconDebrief} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Compte rendu</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Partagez du contenu après chaque événement</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconRandom} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Groupes aléatoires</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Générez des groupes parmi vos participants</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconExport} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">Export des données</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Téléchargez toutes les données de votre communauté sur vos outils</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconSSO} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">SSO</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Permettez à vos membres de se connecter via votre intranet</Trans>
                                    </div>
                                </div>
                            </div>

                            <div className="PlanSelection__features__container__element">
                                <div className="PlanSelection__features__container__element__imageWrapper">
                                    <div className="PlanSelection__features__container__element__imageWrapper__container premium">
                                        <img alt="" src={iconApi} className="PlanSelection__features__container__element__imageWrapper__container__image"/>
                                    </div>
                                </div>
                                <div className="PlanSelection__features__container__element__text">
                                    <div className="PlanSelection__features__container__element__text__name">
                                        <Trans ns="pricing_component">API</Trans> <img alt="" src={iconCrown} className="PlanSelection__features__container__element__text__name__premium"/>
                                    </div>
                                    <div className="PlanSelection__features__container__element__text__subText">
                                        <Trans ns="pricing_component">Importez vos données automatiquement sur Mixlab</Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="PlanSelection__formBody__sub xl-top">
                        <div className="PlanSelection__formBody__sub__title l-top">
                        <Trans ns="pricing_component">Besoin d’une fonctionnalité spécifique ? Vous avez plus de 1000 membres dans votre communauté ?</Trans>
                        </div>
                        <div className="PlanSelection__formBody__sub__buttonWrapper l-top">
                            <a href="mailto:noemie@mixlab.fr" rel="noopener noreferrer nofollow" className="PlanSelection__formBody__sub__buttonWrapper__link"><button className="blue big">
                                <Trans ns="pricing_component">Contacter Noémie</Trans>
                            </button></a>
                        </div>
                    </div>
                </div>
                <br/>
                <FooterPage />
                <br/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        planName: state.community && state.community.planName
    }
}, (dispatch) => ({
    savePlan: CommunityAction.savePlan.bind(null, dispatch),
    showModal: actions.modal.show.bind(null, dispatch),
    saveCommunity: CommunityAction.saveCommunity.bind(null, dispatch)
}))(withRouter(PlanSelection));
