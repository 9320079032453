import React from 'react';
import './TestimonialSignature.scss';
import PropTypes from 'prop-types';

function TestimonialSignature(props) {
    return (
        <div className={`TestimonialSignature ${props.className}`}>
            <div className="TestimonialSignature__imageWrapper">
              <img className="TestimonialSignature__imageWrapper__image" src={props.image} alt=""/>
            </div>
            <div className="TestimonialSignature__textWrapper">
              <div className="TestimonialSignature__textWrapper__name">
                {props.name}
              </div>
              <div className="TestimonialSignature__textWrapper__job xs-top">
                {props.job}
              </div>
            </div>
        </div>
    )
}

// TabSelector.defaultProps = {};

TestimonialSignature.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    job: PropTypes.string,
}

export default TestimonialSignature;
