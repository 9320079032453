import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { CommunityAction } from '../../../redux/actions'
import './CreateCommunity.scss';
import TextField from '../../Components/Forms/TextForm'
import FormChecker from '../../../Helpers/FormChecker'
import RainbowEmoji from '../../../Pictures/Emojis/emoji_rainbow.png'
import MiniLoader from '../../../Pictures/gif/loaderMini.gif'

import { CommunityManager } from '../../../Manager/Manager'
import PaymentManager from '../../../Manager/PaymentManager';

import NavBar from './NavBar'
import ScrollTo from '../../../Helpers/ScrollTo';
import Translation from '../../../Helpers/Translation';
import { Trans } from 'react-i18next';

class CreateCommunity extends Component {


    constructor(props) {
        super(props)
        this.state = {
            community:{},
            userEmail: props.userEmail
        }
    }

    UNSAFE_componentWillMount() {
        ScrollTo()
        if (!this.props.plan)
            window.location.replace("/pricing")
    }

    componentDidMount() {
        this.setState({community: this.props.community})
        if (this.props.community && this.props.community.name)
            this.checkCommunityURL(this.props.community.name)
    }

    changeCommunityName(element) {
        let formChecker = FormChecker.check(element, {
            min:1,
            max: 30,
            type: "subdomain"
        });
        if (!element) {
            this.setState({validUrl: undefined, isLoadingCheckName: false})
        } else if (formChecker.error === undefined) {
            this.setState({isLoadingCheckName: true})
        }

        let community = this.state.community
        community.name = element ? String(element).toLowerCase().trim() : element
        this.setState({
            community:community,
            errorUrl: formChecker.error,
            infoName:formChecker.info,
            hasError: false,
            nameErrorHighligth: element ? false : true
        })
        if (community.name)
            this.checkCommunityURL(community.name)
    }

    checkCommunityURL(element) {
        if (this.timeoutURLRequest)
            clearTimeout(this.timeoutURLRequest)
        this.timeoutURLRequest = setTimeout((() => {
            CommunityManager.checkName(element).then(() => {
                this.setState({validUrl: false, isLoadingCheckName: false})
            }).catch((err) => {
                if (err && err.response && err.response.status === 404)
                    return this.setState({validUrl: true, isLoadingCheckName: false})
                else if (err && err.message !== undefined) {
                    this.setState({
                        errorUrl: Translation.shared.t("errors|Oups ! Une erreur s'ai produite sur le serveur..."),
                        isLoadingCheckName: false,
                        validUrl: undefined
                    })
                }
            })
        }), 500)
    }

    changeCommunityDisplayName(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 30,
            type: "name"
        });

        let community = this.state.community
        community.displayName = value
        this.setState({
            community:community,
            errorDisplayName: formChecker.error,
            infoDisplayName:formChecker.info,
            hasError: false,
            displayNameErrorHighligth: value ? false : true,
        })
    }

    changeCommunitSentence(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 80,
            regex: "[0-9a-zA-ZÀ-ÿ’ʼ'!?+&^.@%,:=/$~;#\" +()_-]"
        });

        let community = this.state.community
        community.sentence = value
        this.setState({
            community:community,
            errorSentence: formChecker.error,
            infoSentence:formChecker.info,
            hasError: false,
            sentenceErrorHighligth: value ? false : true
        })
    }

    onChangeEmail(value) {
        let formChecker = FormChecker.check(value, {
            type: "email"
        });
        this.setState({userEmail: value ? String(value).trim() : "", errorEmail: formChecker.error, error:null, emailErrorHighligth: value ? false : true})
    }

    checkEmail() {
        let formChecker = FormChecker.check(this.state.userEmail, {
            type: "email"
        });
        this.setState({
            emailErrorHighligth: this.state.errorEmail || !this.state.userEmail || formChecker.error,
            errorEmail: !this.state.userEmail ? Translation.shared.t("errors|Obligatoire !") : formChecker.error && Translation.shared.t("errors|Le mail doit être valide")
        })
    }

    highligthMissingFields() {
        this.setState({
            displayNameErrorHighligth: !this.state.community.displayName || this.state.errorDisplayName,
            nameErrorHighligth: !this.state.community.name || this.state.errorUrl,
            emailErrorHighligth: !this.state.userEmail || this.state.errorEmail,
            errorUrl: !this.state.errorUrl && !this.state.community.name ? Translation.shared.t("errors|Obligatoire !") : this.state.errorUrl,
            errorDisplayName: !this.state.errorDisplayName && !this.state.community.displayName ? Translation.shared.t("errors|Obligatoire !") : this.state.errorDisplayName,
            errorEmail: !this.state.errorEmail && !this.state.userEmail ? Translation.shared.t("errors|Obligatoire !") : this.state.errorEmail,
        })
    }

    checkError() {
        if (!(this.state.community.name
            && this.state.userEmail
            && this.state.community.displayName)) {
            this.highligthMissingFields()
            return Translation.shared.t("errors|Tous les champs sont obligatoires.")
        } else if (this.state.errorUrl
            || this.state.errorEmail
            || this.state.errorDisplayName) {
            this.highligthMissingFields()
            return Translation.shared.t("errors|Il y a des erreurs dans le formulaire.")
        } else  if (!this.state.validUrl)
            return Translation.shared.t("errors|L'URL de la communauté que vous avez choisi n'est pas disponible.")
        else if (this.state.isLoadingCheckName)
            return Translation.shared.t("errors|Veuillez attendre que l'URL de la communauté soit validée.")
    }

    isValid() {
        return (
                !(
                    this.state.errorUrl
                    || this.state.errorEmail
                    || this.state.errorDisplayName
                 )
                &&  this.state.userEmail
                &&  this.state.community
                && (
                    this.state.community.name
                )
                && this.state.validUrl
               )
    }

    async saveCommunity() {
        if (!this.isValid())
            return this.setState({error:this.checkError(), hasError: true})
        let community = this.state.community
        community.isValid = true
        this.props.saveCommunity(community)
        this.props.saveEmail(this.state.userEmail)
        this.props.history.push('/PaymentInformations')
        await PaymentManager.subscribe(this.state.userEmail)
    }

    render() {
        return (
            <div id="CreateCommunity">
                <NavBar />
                <div className="formBody">
                    <h2>
                        <Trans ns="community_component">Créez votre Mixlab</Trans>
                        &nbsp;<img className="emoji" src={RainbowEmoji} alt="Mixlab raimbow"/></h2>
                    <div className="l-top">
                        <div className="label center">
                            <Trans ns="community_component">Ajoutez votre mail</Trans>
                        </div>
                        <span className="errorLabel">{this.state.errorEmail? <span className="error">{this.state.errorEmail}</span> : ""}</span>
                        <TextField
                            isOneLine={true}
                            value={this.state.userEmail}
                            onChange={this.onChangeEmail.bind(this)}
                            placeholder="john@mail.com"
                            onBlur={this.checkEmail.bind(this)}
                            className={`center ${this.state.emailErrorHighligth ? "error" : ""}`}
                        />
                        <br/>
                        <br/>
                        <div className="label">
                            <Trans ns="community_component">Nom de votre communauté</Trans>
                        </div>
                        <span className="errorLabel">{this.state.errorDisplayName ? <span className="error">{this.state.errorDisplayName}</span> : this.state.infoDisplayName ? <span className="info">{this.state.infoDisplayName}</span> : ""}</span>
                        <TextField
                            isOneLine={true}
                            value={this.state.community.displayName}
                            onChange={this.changeCommunityDisplayName.bind(this)}
                            placeholder={Translation.shared.t("community_component|Nom de la communauté")}
                            onBlur={() => {
                                this.setState({
                                    displayNameErrorHighligth: this.state.errorDisplayName || !this.state.community.displayName ? true : false,
                                    errorDisplayName: !this.state.community.displayName ? "Obligatoire !" : this.state.errorDisplayName ? this.state.errorDisplayName : undefined
                                })
                            }}
                            className={`center ${this.state.displayNameErrorHighligth ? "error" : ""}`}
                        />
                        <br/>
                        <br/>
                        <div className="label">
                            <Trans ns="community_component">Personnalisez l'url de votre communauté</Trans>
                        </div>
                        <div className="subLabel">
                            <Trans ns="community_component">L'url est le lien qui permettra à vos membres de se connecter à votre mixlab.</Trans>
                        </div>
                        <span className="errorLabel">{this.state.errorUrl ? <span className="error">{this.state.errorUrl}</span> : this.state.validUrl ? this.state.validUrl : ""}</span>
                        <TextField
                            isOneLine={true}
                            value={this.state.community.name}
                            onChange={this.changeCommunityName.bind(this)}
                            placeholder={Translation.shared.t("community_component|macommunauté.mixlab.fr")}
                            className={`center ${this.state.nameErrorHighligth ? "error" : ""}`}
                            onBlur={() => {
                                this.setState({
                                    nameErrorHighligth: this.state.errorUrl || !this.state.validUrl || !this.state.community.name,
                                    errorUrl: !this.state.community.name ? Translation.shared.t("errors|Obligatoire !") : this.state.errorUrl ? this.state.errorUrl : undefined
                                })
                            }}
                        />
                        <div className={"informationsViewer " + (this.state.community.name ? "" : "hidden") + " " + (this.state.validUrl === undefined || this.state.isLoadingCheckName ? "" : this.state.validUrl && !this.state.errorUrl ? "valid" : "error")}>
                            <div>
                                {this.state.community.name}.mixlab.fr
                            </div>
                        </div>
                        <span className="errorText">{this.state.isLoadingCheckName ? (<img className="miniloader" src={MiniLoader} alt="Mixlab loader"/>) : this.state.validUrl === undefined ? "" : this.state.validUrl === false ? Translation.shared.t("errors|Cette url est déjà prise !") : ""}</span>
                        <span className={`errorGlobal ${this.state.hasError ? "open" : ""}`}>{this.state.error ? <span className="error">{this.state.error}</span> : ""}</span>
                        <div className="navigation">
                            <button className="back" onClick={() => {this.props.coupon ? this.props.history.push(`/offer`) : this.props.history.push("/pricing")}}><Trans>Retour</Trans></button>
                            <button className="next" onClick={() => {this.saveCommunity()}}><Trans>Suivant</Trans></button>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        community: (state.community && state.community.community) || {},
        userEmail: state.community && state.community.userEmail,
        plan: state.community && state.community.plan,
        coupon: state.community && state.community.coupon
    }
}, (dispatch) => {
    return {
        saveCommunity: CommunityAction.saveCommunity.bind(null, dispatch),
        saveEmail: CommunityAction.saveUserEmail.bind(null, dispatch)
    }
})(withRouter(CreateCommunity));
