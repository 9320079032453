import React, { Component } from 'react';
import './Dashboard.scss';
import PropTypes from 'prop-types';
import dashboard from '../../../Pictures/illustrations/dashboard.png';
import dashboardEng from '../../../Pictures/illustrations/dashboard_eng.png';
import Translation from '../../../Helpers/Translation';

class Dashboard extends Component {
    render() {
        return (
            <div className={`Dashboard xl-top ${this.props.className}`} data-aos={!this.props.noAOS ? "zoom-out-up" : ""}>
                <div className="container">
                    <h2>{this.props.title}</h2>
                    <p className="Dashboard__subtitle">{this.props.text}</p>
                    <div className="Dashboard__image">
                        <img src={Translation.getLanguage() === "fr" ? dashboard : dashboardEng} alt="dashboard"/>
                    </div>
                </div>
            </div>
        )
    }
}

Dashboard.defaultProps = {
    title: "Je n'ai pas de titre",
    text: "Ceci n'est pas une description, ni du lorem ipsum mais elle permet de voir ce que ferais d'avoir du texte",
};

Dashboard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    noAOS: PropTypes.bool
}

export default Dashboard;
