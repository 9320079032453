import React, {useEffect} from 'react';
import './DefaultModal.scss'
import { connect } from 'react-redux';
import CrossImage from '../../../Pictures/icons/icon_close.svg'
import {actions} from '../../../redux';

function DefaultModal(props) {

    useEffect(() => {
        let bodyElement = document.getElementById("body")
        bodyElement.style.overflow = "hidden"
        return () => {
            bodyElement.style.overflow = "auto"
        };
    }, [])

    function hideModal(){
        props.hide()
        props.onHide()
    }

    return (
        <div id="DefaultModal" onClick={hideModal}>
            <div
                id="modal"
                className="DefaultModal__modal"
                onClick={(e) => {e.stopPropagation()}}
                style={props.width ? {maxWidth: props.width} : {}}
            >
                <div className={"closeImage clickable"} onClick={hideModal}><img src={CrossImage} alt="Close"/></div>
                <div
                    className={"closeImage clickable"}
                    onClick={hideModal}
                >
                    <img src={CrossImage} alt="Close"/>
                </div>
                <div>
                {props.content}
                </div>
            </div>
        </div>
    );
}

export default connect((state) => {
    return {
        content: state.modal.content,
        width: state.modal.width,
        onHide: state.modal.onHide || (()=>{})
    }}, (dispatch) => { return {
            show: actions.modal.show.bind(null, dispatch),
            hide: actions.modal.hide.bind(null, dispatch)
        }
    })(DefaultModal);
