/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'

class AdminManager extends ApiManager {
  static exchangeCode(code, state){
    return ApiManager.requester('post', '/public/admin/social/code', {
      code,
      state
    })
  }
}

export default AdminManager;