import React, { Component } from 'react';
import './Customers.scss';
import testi1 from '../../../Pictures/customers/mixlab-lion.png'
import testi2 from '../../../Pictures/customers/mixlab-thefamily.png'
// import testi3 from '../../../Pictures/customers/mixlab-bpi.png'
import testi4 from '../../../Pictures/customers/mixlab-creme.png'
import testi5 from '../../../Pictures/customers/mixlab-matrice.png'
import testi6 from '../../../Pictures/customers/mixlab-belair.png'

class Customers extends Component {
    render() {
        return (
            <div id="Customers">
                <div className="container">
                    <div className="testisContainer">
                        {/*<a href="https://42.mixlab.fr" target="_blank" rel="nofollow noopener noreferrer"><img alt="Mixlab 42" src={testi1}/></a>*/}
                        {/*<a href="https://matrice.mixlab.fr" target="_blank" rel="nofollow noopener noreferrer"><img alt="Mixlab matrice" src={testi2}/></a>*/}
                        {/*<a href="https://creme-community.mixlab.fr" target="_blank" rel="nofollow noopener noreferrer"><img alt="Mixlab creme" src={testi3}/></a>*/}
                        {/*<a href="https://lion.mixlab.fr" target="_blank" rel="nofollow noopener noreferrer"><img alt="Mixlab lion" src={testi4}/></a>*/}
                        <img alt="Mixlab belair" src={testi6}/>
                        <img alt="Mixlab lion" src={testi1}/>
                        <img alt="Mixlab thefamily" src={testi2}/>
                        {/* <img alt="Mixlab bpi" src={testi3}/> */}
                        <img alt="Mixlab creme" src={testi4}/>
                        <img alt="Mixlab matrice" src={testi5}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Customers;
