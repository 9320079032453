import React, {Fragment, useState, useEffect} from 'react';
import './TicketingModalContent.scss'
import { connect } from 'react-redux';
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

import config from '../../../../../config.json'

import { actions } from '../../../../../redux';

import TransformErrorCode from '../../../../../Helpers/TransformErrorCode';
import Translation from '../../../../../Helpers/Translation';

import ChallengeManager from '../../../../../Manager/ChallengeManager';

import RegisterTopModalContent from './RegisterTopModalContent';
import TopModalSquare from '../../../Elements/TopModalSquare';
import LoadingSpinner from '../../../Elements/LoadingSpinner';
import FormatPrice from '../../../../../Helpers/FormatPrice';
import TicketPriceElement from './TicketPriceElement/TicketPriceElement';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState()
  const [priceText, setPriceText] = useState()
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
 
  async function handleSubmit(event) {
    event.preventDefault();
    setHasError(false)
    setIsLoading(true)
    try {
      const request = await ChallengeManager.getPaymentIntent(props.challengeId, props.ticket.index)
      const result = await stripe.confirmCardPayment(request.data.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      })
      if (result.error)
        throw {...result.error, stripeError: true} // eslint-disable-line no-throw-literal
      if (result.paymentIntent && result.paymentIntent.id)
          props.onAttending(result.paymentIntent.id)
    } catch (err) {
      if (err && err.stripeError) {
        setHasError(true)
        setError(err.message)
      } else {
        setHasError(true)
        setError(TransformErrorCode(err))
      }
    }
    setIsLoading(false)
  };

  function onChangeCard(value) {
    if (value.error)
      setError(value.error.message)
    setHasError(!!value.error)
  }

  useEffect(() => {
    if (props.ticket)
      setPriceText(` ${FormatPrice(props.ticket.price)}€`)
  }, [props.ticket])

  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="TicketingModalContent__form">
        <CardElement 
          options={{
            style:{
                invalid: {
                  // iconColor: "#FF3465",
                  color: "#FF3465"
                }, 
                base:{
                  color: "#344564",
                  "::placeholder":{
                    // iconColor: "#A0ACC2",
                    color:"#A0ACC2"
                  }
                }
              }
          }}
          className={`TicketingModalContent__form__card m-bottom`}
          onChange={onChangeCard}
        />
        {isLoading ? (
          <LoadingSpinner mini size="55px" onClick={_=>setIsLoading(false)} top="25px"/>
        ) : (
          <button type="submit" className="btn-mixlab green btn-mixlab__disabledActivation TicketingModalContent__form__button" disabled={!stripe}>
            <Trans ns="income_component">Payer{{priceText}}</Trans>
          </button>
        )}
      </form>
      <div className={`TicketingModalContent__error ${hasError ? "show" : ""}`}>
        {error}
      </div>
    </Fragment>
  );
};

const stripePromise = loadStripe(config.stripe.publicToken, {locale: Translation.getLanguage()});

function TicketingModalContent(props) {
  const [selectedTicket, setSelectedTicket] = useState()

  useEffect(() => {
    let firstTicket =  props.challenge.tickets[0]
    if (!firstTicket)
      props.onAttending()
    else
      setSelectedTicket(firstTicket)
  }, [])

  return (
      <div className="TicketingModalContent">
        <TopModalSquare>
          <RegisterTopModalContent challenge={props.challenge} />
        </TopModalSquare>
        <div className="TicketingModalContent__content xl-top">
          <h2>{props.challenge.title}</h2>
          <div className="TicketingModalContent__content__form">
            <div className="TicketingModalContent__priceWrapper l-top">
              {props.challenge.tickets && (
                props.challenge.tickets.map((ticket, index) => 
                  <TicketPriceElement 
                    key={index}
                    className="s-top"
                    ticket={ticket} 
                    isSelected={ticket === selectedTicket} 
                    onClick={t => setSelectedTicket(t)}
                  />)
              )}
            </div>  
            <div className="TicketingModalContent__line ml-top" />
            <div className="TicketingModalContent__email s-bottom ml-top">
              {props.profile && props.profile.email}
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm 
                onAttending={(id) => {
                  props.onAttending(id)
                  props.hide()
                }} 
                challengeId={props.challenge.id} 
                ticket={selectedTicket}
              />
            </Elements>
            <div className="TicketingModalContent__legalWrapper m-top">
              <Trans ns="income_component">
                En cliquant sur « Payer », je déclare avoir lu et accepté les <a className="link" href="https://mixlab.fr/cgv" target="_blank" rel="noopener noreferrer">CGVs</a>
              </Trans>
            </div>
          </div>
        </div>
      </div>
  );
}

TicketingModalContent.propTypes = {
  onAttending: PropTypes.func,
  challenge: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    community: PropTypes.shape({
      imageUrl: PropTypes.string
    }),
    options: PropTypes.shape({
      payable: PropTypes.shape({
        price: PropTypes.number
      })
    })
  })
}

TicketingModalContent.defaultProps = {
  onAttending: (() => {}),
  challenge: {
    community: {},
    options: {
      payable: {
        price: 0
      },
    }
  }
}

export default connect((state) => ({
  profile: state.user && state.user.profile,
}), (dispatch) => ({
  hide: actions.modal.hide.bind(null, dispatch)
}))(TicketingModalContent);
