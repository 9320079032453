/**
 * Created by larry on 27/04/2018.
 */
import React from 'react';
import PropTypes from 'prop-types'
import Linkify from 'react-linkifier'
import {withRouter} from 'react-router-dom'

import './ChallengeInformations.scss'
import Translation from '../../../Helpers/Translation';
import { Trans } from 'react-i18next';

function ChallengeInformations(props) {

  return (
    <div className={`ChallengeInformations ${props.className || ""}`}>
          <div className="ChallengeInformations__element">
            <div className="ChallengeInformations__element__title">
                {props.isWebinar ? Translation.shared.t("event_component|Lien du webinar") : Translation.shared.t("event_component|Adresse")}
            </div>
            <div className="ChallengeInformations__element__text">
              {(props.address && props.address.full) ? (
                <a href={`https://www.google.com/maps/place/${encodeURI(props.address.full)}`} target="_blank" rel="nofollow noopener noreferrer">
                  {props.address.full}
                </a>
              ) : (props.address && props.address.url) ? (
                <a href={`${props.address.url}`} target="_blank" rel="nofollow noopener noreferrer">
                  {props.address.url}
                </a>
              ) : (props.isWebinar) ? (
                Translation.shared.t("event_component|Inscris-toi pour voir le lien du webinar")
              ) : (
                Translation.shared.t("event_component|Inscris-toi pour voir l'adresse")
              )}
            </div>
          </div>
          <div className="ChallengeInformations__element s-top">
            <div className="ChallengeInformations__element__title">
                <Trans ns="event_component">Instructions</Trans>
            </div>
            <div className={`ChallengeInformations__element__text ${props.secretInfos && props.secretInfos.details && "visible"}`}>
              {(!props.secretInfos) ? (
                Translation.shared.t("event_component|Inscris-toi pour voir les détails du rendez-vous")
              ) : (!props.secretInfos.details) ? (
                Translation.shared.t("event_component|Il n'y a pas d'instructions particulières")
              ) : (
                <Linkify target='_blank' className="link">
                  {props.secretInfos.details}
                </Linkify>
              ) }
            </div>
          </div>
      </div>
  )
}

ChallengeInformations.propTypes = {
  isWebinar: PropTypes.bool,
  random: PropTypes.shape({
    isActivated: PropTypes.bool,
    doneAt: PropTypes.string
  }),
  address: PropTypes.shape({
    full: PropTypes.string,
    url: PropTypes.string
  }),
  secretInfos: PropTypes.shape({
    details: PropTypes.string
  })
}

export default withRouter(ChallengeInformations)
