import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import './ChallengeSignUpForm.scss'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import Translation from '../../../Helpers/Translation'
import { Trans } from 'react-i18next'
import PasswordForceHelper from '../../../Helpers/Password/PasswordForceHelper'
import PasswordForceIndicator from '../../Components/Elements/Password/PasswordForceIndicator'

function ChallengeSignUpForm(props) {
  const {register, handleSubmit} = useForm()
  const [passwordForce, setPasswordForce] = useState()

  function onChangePassword(element) {
    let force = PasswordForceHelper(element.target.value)
    setPasswordForce(force)
    props.onChange("password", force)
  }

  return (
    <form 
      className="ChallengeSignUpForm"
      onSubmit={handleSubmit((data) => props.onSubmit(data, passwordForce))}
    >
      <input
        name="surname"
        ref={register}
        type="text"
        autoComplete="surname"
        className="ChallengeSignUpForm__input"
        placeholder={Translation.shared.t("login_component|Prénom")}
        onChange={props.onChange.bind(null, "surname")}
      />
      <input
        name="name"
        ref={register}
        type="text"
        autoComplete="name"
        className="ChallengeSignUpForm__input s-top"
        placeholder={Translation.shared.t("login_component|Nom")}
        onChange={props.onChange.bind(null, "name")}
      />
      <input
        name="email"
        ref={register}
        type="text"
        autoComplete="email"
        className="ChallengeSignUpForm__input s-top"
        placeholder={Translation.shared.t("login_component|Email")}
        onChange={props.onChange.bind(null, "email")}
      />
      <input
        name="password"
        ref={register}
        type="password"
        autoComplete="none"
        className="PasswordForm__input s-top"
        placeholder={Translation.shared.t("login_component|Mot de passe")}
        onChange={onChangePassword}
      />
      <PasswordForceIndicator 
        className="s-top"
        force={passwordForce}
      />
      <div className="ChallengeSignUpForm__cguSentence m-top m-bottom">
        <Trans ns="login_component">En cliquant sur « S'inscrire », je déclare avoir lu et accepté les <a href="/cgu" rel="noopener noreferrer" target="_blank">CGU</a></Trans>
      </div>
      <div className={`ChallengeSignUpForm__errors ${props.hasErrors ? "open" : ""}`}>
        {props.errorNode}
      </div>
      {props.isLoading ? (
        <LoadingSpinner 
          size="47px"
          small
        />
      ) : (
        <button className="ChallengeSignUpForm__button green">
          <Trans ns="login_component">S'inscrire</Trans>
        </button>
      )}
    </form>
  )
}

ChallengeSignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool
}

ChallengeSignUpForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {}),
}

export default ChallengeSignUpForm