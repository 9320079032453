import React from 'react'
import './ProfileInvoicesPage.scss'

import NavBar from '../../Components/Elements/ChallengeNavBar'
import { Trans } from 'react-i18next'
import ProfileNavBar from './ProfileNavBar'
import InvoicesList from './IncomesList/InvoicesList'

function ProfileInvoicesPage() {
  return (
    <div className="ProfileInvoicesPage">
      <NavBar />
      <div className="xxl-top ProfileInvoicesPage__content">
        <ProfileNavBar pageName="invoices" className="l-bottom"/>
        <div className="ProfilePage__content__title"><Trans ns="user_component">Reçus</Trans></div>
        <InvoicesList className="l-top"/>
        <br/>
        <br/>
      </div>
    </div>
  )
}

export default ProfileInvoicesPage