export const actions = {
  setIsDown(dispatch, isDown) {
    dispatch({
      type: "API_DOWN",
      isDown: isDown
    });
  },
  setIsTimeout(dispatch, isTimeout) {
    dispatch({
      type: "API_TIMEOUT",
      isTimeout: isTimeout
    });
  }
}

export const reducer = (state = {}, action) => {
  let newState = {...state}
  switch (action.type) {
    case "API_DOWN":
      newState.isDown = action.isDown;
      break;
    case "API_TIMEOUT":
      newState.isTimeout = action.isTimeout;
      break;
    default:
      return newState;
  }
  return newState;
}

export default {
  reducer,
  actions
}