import React, { Component } from 'react';
import Landing from './Components/Pages/Landing'
import './App.scss';
import { Provider } from "react-redux";
import { createStore } from "redux";
import PackageJSON from '../package.json'
import ReduxHelper from './redux/index'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import ApiManager from './Manager/ApiManager';
import Translation from './Helpers/Translation';

let persistRootStore = createStore(
                        ReduxHelper.persistedReducer,
                        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                      );
let persistor = persistStore(persistRootStore);
ApiManager.shared = new ApiManager(persistRootStore)
Translation.shared.start(persistRootStore)

class App extends Component {

  componentDidMount() {
    console.log("Version :", PackageJSON.version)
  }

  render() {
    return (
      <div id="App">
        <Provider store={persistRootStore}>
          <PersistGate loading={null} persistor={persistor}>
            <Landing />
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
