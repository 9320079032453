import React, { Component } from 'react';
import {CardElement, injectStripe } from 'react-stripe-elements';
import {connect} from 'react-redux'
import {
    CommunityAction
} from '../../../redux/actions'
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import moment from 'moment';

import './PaymentInformations.scss';
import TextField from '../../Components/Forms/TextForm'
import FormChecker from '../../../Helpers/FormChecker'

import LoadingGif from '../../../Pictures/gif/loading.gif'
import iconLock from '../../../Pictures/icons/icon_lock.png'
import MiniLoader from '../../../Pictures/gif/loaderMini.gif'

import CommunityManager from '../../../Manager/CommunityManager'
import PaymentManager from '../../../Manager/PaymentManager'

import NavBar from './NavBar'
import ScrollTo from '../../../Helpers/ScrollTo';
import { actions } from '../../../redux';
import Translation from '../../../Helpers/Translation';
import TransformErrorCode from '../../../Helpers/TransformErrorCode';

class PaymentInformations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {
            },
            payment: {
                address: {}
            },
            plan: props.plan,
            validCGV: false,
            pollCount: 0,
            coupon: props.coupon,
            userEmail: props.userEmail
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            plan: props.plan,
            userEmail: props.userEmail
        })
    }

    UNSAFE_componentWillMount() {
        ScrollTo()
        if (!this.props.plan || !(this.props.community && this.props.community.isValid))
            window.location.replace("/pricing")
    }

    // Facturation address
    changePaymentSurname(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 125,
            regex: "[0-9a-zA-ZÀ-ÿ’ʼ' -]"
        });

        let payment = this.state.payment
        payment.nameSurname = value
        this.setState({payment:payment, errorPaymentNameSurname: formChecker.error, infoPaymentNameSurname:formChecker.info, error:null, paymentNameSurnameErrorHighligth: value ? false : true})
    }

    changePaymentAddressLine(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 250,
            type: "address"
        });

        let payment = this.state.payment
        payment.address.line = value
        this.setState({payment:payment, errorPaymentAddressLine: formChecker.error, infoPaymentAddressLine:formChecker.info, error:null, paymentAddressLineErrorHighligth: value ? false : true})
    }

    changePaymentAddressCity(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 100,
            regex: "[0-9a-zA-ZÀ-ÿ’ʼ' -]"
        });

        let payment = this.state.payment
        payment.address.city = value
        this.setState({payment:payment, errorPaymentCity: formChecker.error, infoPaymentCity:formChecker.info, error:null, paymentAddressCityErrorHighligth: value ? false : true})
    }

    changePaymentAddressPostalCode(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 100,
            regex: "[0-9]"
        });

        let payment = this.state.payment
        payment.address.postalCode = value ? String(value).trim() : ""
        this.setState({payment:payment, errorPaymentPostalCode: formChecker.error, infoPaymentPostalCode:formChecker.info, error:null, paymentAddressPostalCodeErrorHighligth: value ? false : true})
    }

    changePaymentAddressCountry(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 100,
            regex: "[0-9a-zA-ZÀ-ÿ’ʼ' -]"
        });

        let payment = this.state.payment
        payment.address.country = value
        this.setState({payment:payment, errorPaymentCountry: formChecker.error, infoPaymentCountry:formChecker.info, error:null, paymentAddressCountryErrorHighligth: value ? false : true})
    }

    changePaymentCoupon(value) {
        let formChecker = FormChecker.check(value, {
            min:1,
            max: 30,
            regex: "[0-9a-zA-Z-]"
        });

        let payment = this.state.payment
        payment.coupon = undefined
        if (value)
            payment.coupon = value
        this.setState({payment:payment, isValidCoupon:false, coupon: undefined, errorRequestPaymentCoupon:undefined, errorPaymentCoupon: formChecker.error, infoPaymentCoupon:formChecker.info, error:null, isLoadingCoupon:true})
        if (!formChecker.error && value) {
            if (this.couponTimeout)
                clearTimeout(this.couponTimeout)
            return this.couponTimeout = setTimeout((() => PaymentManager.getCoupon(value).then((res) => {
                this.props.saveCoupon(res.data)
                this.setState({coupon: res.data, isValidCoupon: true, isLoadingCoupon: false})
            }).catch((err) => {
                var error = Translation.shared.t("errors|Oups ! une erreur s'est produite lors de la vérification du coupon.")
                if (err && err.response && err.response.data && err.response.data.code)
                    error = TransformErrorCode(err)
                this.setState({errorRequestPaymentCoupon: error, isLoadingCoupon: false})
            })), 1000)
        }
        clearTimeout(this.couponTimeout)
        return this.setState({isLoadingCoupon:false})
    }

    highligthMissingFields() {
        this.setState({
            paymentNameSurnameErrorHighligth: this.state.payment.nameSurname && !this.state.errorPaymentNameSurname ? false : true,
            paymentAddressLineErrorHighligth: this.state.payment.address.line && !this.state.errorPaymentAddressLine ? false : true,
            paymentAddressCityErrorHighligth: this.state.payment.address.city && !this.state.errorPaymentCity ? false : true,
            paymentAddressPostalCodeErrorHighligth: this.state.payment.address.postalCode && !this.state.errorPaymentPostalCode ? false : true,
            paymentAddressCountryErrorHighligth: this.state.payment.address.country && !this.state.errorPaymentCountry ? false : true,
            cardErrorHighligth: !this.state.validCard,
            cgvErrorHighligth: !this.state.validCGV,
            errorPaymentNameSurname: !this.state.errorPaymentNameSurname && !this.state.payment.nameSurname ? Translation.shared.t("errors|Obligatoire !") : this.state.errorPaymentNameSurname,
            errorPaymentAddressLine: !this.state.errorPaymentAddressLine && !this.state.payment.address.line ? Translation.shared.t("errors|Obligatoire !") : this.state.errorPaymentAddressLine,
            errorPaymentCity: !this.state.errorPaymentCity && !this.state.payment.address.city ? Translation.shared.t("errors|Obligatoire !") : this.state.errorPaymentCity,
            errorPaymentPostalCode: !this.state.errorPaymentPostalCode && !this.state.payment.address.postalCode ? Translation.shared.t("errors|Obligatoire !") : this.state.errorPaymentPostalCode,
            errorPaymentCountry: !this.state.errorPaymentCountry && !this.state.payment.address.country ? Translation.shared.t("errors|Obligatoire !") : this.state.errorPaymentCountry
        })
    }

    checkError() {
        if ((
                !this.state.payment.nameSurname
                || !this.state.payment.address
                || !this.state.payment.address.line
                || !this.state.payment.address.city
                || !this.state.payment.address.postalCode
                || !this.state.payment.address.country 
            )) {
            this.highligthMissingFields()
            return Translation.shared.t("errors|Tous les champs, sauf le coupon, sont obligatoires")
        } else if (this.state.errorPaymentNameSurname
            || this.state.errorPaymentAddressLine
            || this.state.errorPaymentCity
            || this.state.errorPaymentPostalCode
            || this.state.errorPaymentCountry) {
            this.highligthMissingFields()
            return Translation.shared.t("errors|Il y a des erreurs dans le formulaire")
        } else if (!this.state.validCard)
            return Translation.shared.t("errors|La carte bancaire communiquée ne semble pas valide")
        else if (!this.state.validCGV) {
            this.highligthMissingFields()
            return Translation.shared.t("errors|L'acceptation des conditions générales de ventes sont obligatoires")
        }
        return Translation.shared.t("errors|Oups ! Il y a une erreur...")
    }

    isValid() {
        return (
            this.state.payment
            && this.state.validCard
            && this.state.validCGV
        )
    }

    createCommunity(body) {
        CommunityManager.send(body).then((res) => {
            this.props.removeUser()
            this.props.history.push(`/success${res.data.code ? `?code=${res.data.code}` : ""}`)
            this.setState({isLoading:false})
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            if (err && err.response && err.response.data)
                return this.setState({error:this.transformErrorCode(err.response.data.code), isLoading:false})
            this.setState({error:this.transformErrorCode(), isLoading:false})
        })
    }

    async submit() {
        this.setState({error:undefined})
        if (!this.isValid())
            return this.setState({error:this.checkError()})
        this.setState({isLoading:true})
        let {token} = await this.props.stripe.createToken()
        // {
        //     name: this.state.payment.nameSurname,
        //     address_line1: this.state.payment.address.line,
        //     address_line2: "",
        //     address_city: this.state.payment.address.city,
        //     address_zip: this.state.payment.address.postalCode,
        //     address_country: this.state.payment.address.country
        // });

        let payment = this.state.payment
        if (!(this.state.coupon && this.state.coupon.isValid)) {
            payment.coupon = undefined
            this.setState({payment:payment})
        } else if (this.state.coupon) {
            payment.coupon = this.state.coupon.code
        }
        payment.card = token
        payment.cgv = this.state.validCGV
        if (this.state.plan)
            payment.planId = this.state.plan.id
        let newCommunity = Object.assign({}, this.props.community)
        let body = {
            community: newCommunity,
            user: {
                email: this.props.userEmail
            },
            payment: this.state.payment,
            language: Translation.getLanguage() === "en" ? "en" : "fr"
        }

        PaymentManager.intent(body).then((res) => {
            let response = res.data
            if (response.client_secret)
                return this.props.stripe.handleCardPayment(
                    response.client_secret
                ).then((result) => {
                    if (result.error) {
                        console.log("Error :", result.error)
                        return this.setState({
                            error: this.transformErrorCode(result.error.code),
                            isLoading: false
                        })
                    }
                    body.community.subscriptionId = response.subscriptionId
                    this.createCommunity(body)
                });
            else if (!response.client_secret && response.subscriptionId) {
                body.community.subscriptionId = response.subscriptionId
                return this.createCommunity(body)
            }
            this.setState({error:this.transformErrorCode(), isLoading:false})
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            if (err && err.response && err.response.data)
                return this.setState({error:
                        (err.response.data.code === 'card_declined') ?
                            this.transformErrorDeclinedCode(err.response.data.raw ? err.response.data.raw.decline_code : "")
                            : this.transformErrorCode(err.response.data.code), isLoading:false})
            this.setState({error:this.transformErrorCode(), isLoading:false})
        })
    }

    defaultErrorMessage() {
        return (
            <Trans ns="errors">
                Bravo ! Tu as trouvé une erreur, contacte nous sur <a href={
                "mailto:bug@mixlab.fr?"
                + "subject=Help me... (Payment)"
                + "&body= Salutation Julien et Emeric,%0D%0A%0D%0A"
                + "%0D%0A%0D%0A- Date :%0D%0A" + new Date()
                + "%0D%0A%0D%0A- Remarques :%0D%0A<Vos remarques ;)>"
                }
                >
                    bug@mixlab.fr
                </a> pour qu'on corrige le bug ASAP ;)
            </Trans>
        )
    }

    transformErrorDeclinedCode(errorCode) {
        switch (errorCode) {
            case "card_not_supported":
                return Translation.shared.t("errors_paiment|La carte bancaire n'est pas supportée par notre système de paiement.");
            case "withdrawal_count_limit_exceeded":
            case "card_velocity_exceeded":
                return Translation.shared.t("errors_paiment|Votre carte semble avoir déjà dépassé sa limite de paiement.");
            case "expired_card":
                return Translation.shared.t("errors_paiment|La carte bancaire spécifiée a expiré.");
            case "insufficient_funds":
                return Translation.shared.t("errors_paiment|Les fonds disponibles sur la carte bancaire ne semblent pas suffisants.");
            default:
                return Translation.shared.t("errors_paiment|Votre carte semble avoir un problème. Veuillez contacter votre banque pour de plus amples informations.");
        }
    }

    transformErrorCode(errorCode){
        switch (errorCode) {
            case "UPLOAD001":
                return Translation.shared.t("errors_paiment|L'image est trop volumineuse (1Mo maximum)");
            case "UPLOAD002":
                return Translation.shared.t("errors_paiment|Mauvais format, utilise que les jpeg, jpg, png ou gif.");
            case "UPLOAD003":
                return Translation.shared.t("errors_paiment|Aucune image n'a été envoyée.");
            case "invalid_coupon":
                return Translation.shared.t("errors_paiment|La réduction ne semble pas valide.");
            case "cgv_mandatory":
                return Translation.shared.t("errors_paiment|Les conditions génerales de ventes sont obligatoires.");
            case "form_error":
                return Translation.shared.t("errors_paiment|Il semble y avoir des erreurs dans le formulaire.");
            case "card_declined":
                return Translation.shared.t("errors_paiment|Votre carte a été déclinée.");
            case "missing_coupon":
                return Translation.shared.t("errors_paiment|Ce coupon n'existe pas.");
            case "already_exist":
                return Translation.shared.t("errors_paiment|L'url de la communauté est déjà prise... Désolé.");
            case "processing_error":
                return Translation.shared.t("errors_paiment|Le débit de la carte bancaire est impossible.");
            case "incorrect_cvc":
                return Translation.shared.t("errors_paiment|La carte bancaire n'est pas valide");
            case "payment_intent_authentication_failure":
                return Translation.shared.t("errors_paiment|Nous ne parvenons pas à authentifier votre méthode de paiement.");
            default:
                return this.defaultErrorMessage()
        }
    }

    priceHT() {
        var price = this.state.plan ? this.state.plan.price : 29900

        if (this.state.coupon){
            if (this.state.coupon.amount_off)
                price -= this.state.coupon.amount_off
            else if (this.state.coupon.percent_off)
                price = price - (price * (this.state.coupon.percent_off)/100)
        }

        return (price)
    }

    amountTVA() {
        return (this.priceHT() * 0.2)
    }

    priceTTC() {
        return (this.priceHT() + this.amountTVA())
    }

    intervalName(intervalCount, interval) {
        if (intervalCount === 3 && interval === "month")
            return Translation.shared.t("dateformat|Trimestre")
        if (intervalCount === 6 && interval === "month")
            return Translation.shared.t("dateformat|Semestre")
        let intervalUnit = Translation.shared.t("dateformat|Mois")
        let canBePlurial = false
        switch (interval) {
            case "month":
                intervalUnit = Translation.shared.t("dateformat|Mois")
                break;
            case "year":
                intervalUnit = Translation.shared.t("dateformat|An")
                canBePlurial = true
                break;
            case "week":
                intervalUnit = Translation.shared.t("dateformat|Semaine")
                canBePlurial = true
                break;
            case "day":
                intervalUnit = Translation.shared.t("dateformat|Jour")
                canBePlurial = true
                break;
            default:
                intervalUnit =  interval
        }
        return `${intervalCount > 1 ? `${intervalCount} ` : ""}${intervalCount > 1 ? intervalUnit : intervalUnit[0].toUpperCase() + intervalUnit.slice(1)}${intervalCount > 1 && canBePlurial ? "s" : ""}`
    }

    render() {
        let dateEndTrial = moment().add("14", "days").format(Translation.shared.t("dateformat|D MMMM YYYY"))

        return (
            <div id="PaymentInformations">
                <NavBar />
                <div className="formBody">
                    <div className="PaymentInformations__formBody__title">
                        <div className="PaymentInformations__formBody__title__icon">
                            <img src={iconLock} alt=""/>
                        </div>
                        <div className="PaymentInformations__formBody__title__text">
                            <Trans ns="community_component">Paiement sécurisé</Trans>
                        </div>
                    </div>

                    <span className="errorLabel">{this.state.errorCard ? <span className="error">{this.state.errorCard}</span> : ""}</span>
                    <CardElement
                        style={{base:{fontSize: '18px',"::placeholder":{color:"#E4E8EE"}}}}
                        onChange={(value) => {this.setState({error:undefined, validCard: value && value.complete, errorCard:value.error ? value.error.message : undefined})}}
                        className={this.state.cardErrorHighligth ? "StripeElement StripeElement--invalid" : "StripeElement"}
                    />
                    <br/>
                    <div className="label center">
                        <Trans ns="community_component">Coupon de réduction</Trans>
                    </div>
                    {/* <div className="subLabel">
                        Ajoutez votre code de réduction (Pour peut-être bénéficier d’une réduction contactez-nous sur l’adresse <a href={"mailTo:hello@mixlab.fr"}>hello@mixlab.fr</a>)
                    </div> */}
                    <span className="errorLabel">{this.state.errorPaymentCoupon ? <span className="error">{this.state.errorPaymentCoupon}</span> : <span className="info">{this.state.infoPaymentCoupon}</span>}</span>
                    <TextField isOneLine={true} value={this.state.payment.coupon || (this.state.coupon && this.state.coupon.code)} onChange={this.changePaymentCoupon.bind(this)} placeholder={Translation.shared.t("community_component|Coupon de réduction")} className={this.state.isValidCoupon || (this.state.coupon && this.state.coupon.isValid) ? "valid" : this.state.errorRequestPaymentCoupon ? "error" : ""}/>
                    <span className="errorText">
                        {this.state.isLoadingCoupon ? (<img className="miniloader" src={MiniLoader} alt="Mixlab mini loader"/>) : this.state.errorRequestPaymentCoupon ? this.state.errorRequestPaymentCoupon : ""}
                    </span>
                    <br/>

                    <div className="label center">
                        <Trans ns="community_component">Récapitulatif</Trans>
                    </div>
                    {this.state.plan && this.state.plan.try && !this.state.coupon && (
                        <div className="PaymentInformations__offerWrapper s-top">
                            <div className="PaymentInformations__offerWrapper__content s-top">
                                <div className="PaymentInformations__offerWrapper__content__iconWrapper">
                                    <div className="PaymentInformations__offerWrapper__content__iconWrapper__icon"/>
                                </div>
                                <div className="PaymentInformations__offerWrapper__content__iconWrapper__text">
                                    <Trans ns="community_component">Aucun montant ne sera débité de votre compte pendant 14 jours. Cela se produira à la fin de votre essai, le {{dateEndTrial}}.</Trans>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="priceCalculation">
                        <div className="row">
                            <div>
                                {this.state.plan ? this.state.plan.name : "Mixlab"}
                            </div>
                            <div>
                                {this.state.plan ? this.state.plan.price/100 : 29900}€ / {this.state.plan ? this.intervalName(this.state.plan.intervalCount, this.state.plan.interval) : "Mois"}
                            </div>
                        </div>
                        {(this.state.coupon) ? (
                            <div className="row">
                                <div>
                                    <Trans ns="community_component">Réduction</Trans> "{this.state.coupon.name}"<br/>
                                    <span className="informations">{this.state.coupon.duration === "repeating" ? "Appliqué " + (this.state.coupon.duration_in_months > 1 ? "les " : "le ") + this.state.coupon.duration_in_months + " premier" + (this.state.coupon.duration_in_months > 1 ? "s" : "") + " mois" : this.state.coupon.duration === "forever" ? "À vie" : "le 1er mois"}</span>
                                </div>
                                <div>
                                    {this.state.coupon.amount_off ? "-" + (parseFloat(this.state.coupon.amount_off/100).toFixed(2)) + "€ " + (this.state.coupon.duration === "repeating"? "/ mois" : "") : ""}
                                    {this.state.coupon.percent_off ? "-" + (parseFloat(this.state.coupon.percent_off).toFixed(2)) + "% " + (this.state.coupon.duration === "repeating"? "/ mois" : "") : ""}
                                </div>
                            </div>
                        ) : (null)}
                        <div className="line"></div>
                        <div className="row">
                            <div>
                                <Trans ns="community_component">TVA</Trans>
                            </div>
                            <div>
                                20%
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <Trans ns="community_component">Montant TVA</Trans>
                            </div>
                            <div>
                                {parseFloat(this.amountTVA()/100).toFixed(2)} €
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="row big">
                            <div>
                                <Trans ns="community_component">Total</Trans>
                            </div>
                            <div>
                                {parseFloat(this.priceTTC()/100).toFixed(2)} € {Translation.shared.t("community_component|TTC")} / {this.state.plan ? this.intervalName(this.state.plan.intervalCount, this.state.plan.interval) : Translation.shared.t("dateformat|Mois")}
                                {(this.state.coupon) ? (
                                    <span className="informations">&nbsp;<br/>{this.state.coupon.duration === "repeating" ? "Appliqué " + (this.state.coupon.duration_in_months > 1 ? "les " : "le ") + this.state.coupon.duration_in_months + " premier" + (this.state.coupon.duration_in_months > 1 ? "s" : "") + " mois" : this.state.coupon.duration === "forever" ? "À vie" : "le 1er mois"}</span>
                                ) : (null)}
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <label className="CGVcontainer"><span className="label"><Trans ns="community_component">J'accepte les <a href="/cgv" target="_blank">CGV</a></Trans></span>
                        <input defaultValue={this.state.validCGV} onChange={() => {this.setState({validCGV: !this.state.validCGV, error:undefined, cgvErrorHighligth: undefined})}} type="checkbox" />
                        <span
                            className={"checkmark " + (this.state.cgvErrorHighligth ? "error" : "")}
                        ></span>
                    </label>

                    <span className={`errorGlobal ${this.state.error ? "open" : ""}`}>{this.state.error ? this.state.error : ""}</span><br/>
                    <div className="navigationButtons">
                        {(this.state.isLoading) ? (
                            <img src={LoadingGif} alt="Mixlab loading" className="loading" onClick={() => {this.setState({isLoading:false})}}/>
                        ) : (
                            <div className="buttonContainer">
                                <button className="buyButton" onClick={() => {this.submit()}}><Trans ns="community_component">Payer</Trans> {parseFloat(this.priceTTC()/100).toFixed(2)} € / {this.state.plan ? this.intervalName(this.state.plan.intervalCount, this.state.plan.interval) : Translation.shared.t("dateformat|Mois")}</button>
                                <button className="back" onClick={() => this.props.history.push('/community')}><Trans>Retour</Trans></button>
                            </div>
                        )}
                    </div>
                    <br/>
                    <br/>



                </div>
                {(this.state.iframeUrl ? (
                    <div className="modal3DSecureContainer">
                        <div className="modal3DSecure">
                            <div className="threedsecure">
                                <iframe title="3dSecure" src={this.state.iframeUrl} ></iframe>
                            </div>
                            <button className="baseButton" onClick={() => {clearTimeout(this.threeDSecureTimeout);this.setState({iframeUrl: undefined, isLoading: false})}}>Annuler</button>
                        </div>
                        <br/>
                        <br/>
                    </div>
                ) : (null))}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        community: (state.community && state.community.community) || {},
        userEmail: state.community && state.community.userEmail,
        coupon: state.community && state.community.coupon,
        plan: state.community && state.community.plan
    }
}, (dispatch) => {
    return {
        removeUser: CommunityAction.removeUserEmail.bind(null, dispatch),
        saveCoupon: CommunityAction.saveCoupon.bind(null, dispatch),
        saveEmail: CommunityAction.saveUserEmail.bind(null, dispatch),
        showModal: actions.modal.show.bind(null, dispatch)
    }
})(withRouter(injectStripe(PaymentInformations)));
