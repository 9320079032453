import React from 'react'
import PropTypes from 'prop-types'
import './MagicLinkEmailSentModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'
import { Trans } from 'react-i18next'

function MagicLinkEmailSentModalContent(props) {
  return (
    <div className="MagicLinkEmailSentModalContent">
      <TopModalSquare>
        {props.topElement || (
          <div className="MagicLinkEmailSentModalContent__TopModalSquare__title">
            {props.title}
          </div>
        )}
      </TopModalSquare>
      <div className="MagicLinkEmailSentModalContent__content">
        <div className="MagicLinkEmailSentModalContent__content__sentence l-top">
          {props.isPassword ? (
            <Trans ns="login_component">Parfait ! Clique sur le lien que tu viens de recevoir par mail pour retrouver ton mot de passe</Trans>
          ) : (
            <Trans ns="login_component">Parfait ! Clique sur le lien que tu viens de recevoir par mail pour confirmer ta participation</Trans>
          )}
        </div>
        <div className="MagicLinkEmailSentModalContent__content__bottomSentence l-top">
          <Trans ns="login_component">Tu peux fermer cette page et consulter tes mails</Trans>
        </div>
      </div>
    </div>
  )
}

MagicLinkEmailSentModalContent.propTypes = {
  topElement: PropTypes.element,
  title: PropTypes.string,
  isPassword: PropTypes.bool
}

export default MagicLinkEmailSentModalContent