/**
 * Created by larry on 02/04/2018.
 */
import axios from 'axios';
import packageJSON from '../config.json';
import Redux from '../redux'

class ApiManager {
    static baseUrl = packageJSON.apiUrl
    static shared;

    constructor(store){
        this.baseUrl = ApiManager.baseUrl;
        this.store = store
    }

    setServerDown(isDown) {
        if (this.store)
            Redux.actions.api.setIsDown(this.store.dispatch, isDown)
    }

    setServerTimeout(isTimeout) {
        if (this.store)
            Redux.actions.api.setIsTimeout(this.store.dispatch, isTimeout)
    }

    get token() {
        let store = this.store.getState()
        return ((store.user && store.user.isLoggued && store.user.token && store.user.token.access_token) || "")
    }

    get dispatch() {
        return this.store.dispatch
    }

    static checkIfIsDown() {
        axios.get('https://api.github.com').then(() => {
            return ApiManager.shared.setServerDown(true)
        }).catch(() => {
            return ApiManager.shared.setServerTimeout(true)
        })
    }

    static requester(method, url, data, headers = {}){
        return new Promise(async (success, fail) => {
            let additionnalHeaders = {}
            if (ApiManager.shared.token)
                additionnalHeaders = {
                    "Authorization":`Bearer ${ApiManager.shared.token}`
                }
            var config = {
                url: url,
                method: method,
                baseURL: ApiManager.baseUrl,
                data: data,
                headers: {
                    ...headers,
                    ...additionnalHeaders
                }
            };
            try {
                let res = await axios(config)
                success(res)
            } catch (err) {
                console.log(err)
                console.log(err.response)
                if (err && !err.response && !err.status)
                    return ApiManager.checkIfIsDown()
                if (err && err.response && err.response.status === 401) {
                    Redux.actions.user.logout(ApiManager.shared.dispatch)
                }
                fail(err)
            }
        })
    }
}

export default ApiManager
