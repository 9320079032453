import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import {withRouter, NavLink} from 'react-router-dom'

import './ProfileNavBar.scss'

import profileIconBlue from '../../../Pictures/icons/icon_member.png'
import profileIconWhite from '../../../Pictures/icons/icon_memberWhite.png'
import cbIconBlue from '../../../Pictures/icons/icon_cb.png'
import cbIconWhite from '../../../Pictures/icons/icon_cbWhite.png'
import houseIconBlue from '../../../Pictures/icons/icon_houseBlue.png'
import houdeIconWhite from '../../../Pictures/icons/icon_houseWhite.png'
import calendarIconWhite from '../../../Pictures/icons/icon_calendarWhite.png'
import calendarIconBlue from '../../../Pictures/icons/icon_calendarBlue.png'

function ProfileNavBar(props) {
  return (
    <div className={`ProfileNavBar ${props.className}`}>
      <div className={`ProfileNavBar__element ${props.pageName === "communities" ? "ProfileNavBar__element--selected" : ""}`}>
        <NavLink to="/profile" className="ProfileNavBar__element__link">
          <div className="ProfileNavBar__element__imageWrapper">
            <img src={props.pageName === "communities" ? houdeIconWhite : houseIconBlue} alt=""/>
          </div>
          <div className="ProfileNavBar__element__text s-top">
            <Trans ns="user_component">Communautés</Trans>
          </div>
        </NavLink>
      </div>
      <div className={`ProfileNavBar__element ${props.pageName === "events" ? "ProfileNavBar__element--selected" : ""}`}>
        <NavLink to="/profile/events" className="ProfileNavBar__element__link">
          <div className="ProfileNavBar__element__imageWrapper">
            <img src={props.pageName === "events" ? calendarIconWhite : calendarIconBlue} alt=""/>
          </div>
          <div className="ProfileNavBar__element__text s-top">
            <Trans ns="user_component">Événements</Trans>
          </div>
        </NavLink>
      </div>
      <div className={`ProfileNavBar__element ${props.pageName === "profile" ? "ProfileNavBar__element--selected" : ""}`}>
        <NavLink to="/profile/settings" className="ProfileNavBar__element__link">
          <div className="ProfileNavBar__element__imageWrapper">
            <img src={props.pageName === "profile" ? profileIconWhite : profileIconBlue} alt=""/>
          </div>
          <div className="ProfileNavBar__element__text s-top">
            <Trans ns="user_component">Profil</Trans>
          </div>
        </NavLink>
      </div>
      <div className={`ProfileNavBar__element ${props.pageName === "invoices" ? "ProfileNavBar__element--selected" : ""}`}>
        <NavLink to="/profile/invoices" className="ProfileNavBar__element__link">
          <div className="ProfileNavBar__element__imageWrapper">
            <img src={props.pageName === "invoices" ? cbIconWhite : cbIconBlue} alt=""/>
          </div>
          <div className="ProfileNavBar__element__text s-top">
            <Trans ns="user_component">Reçus</Trans>
          </div>
        </NavLink>
      </div>
    </div>
  )
}

ProfileNavBar.propTypes = {
  pageName: PropTypes.oneOf(["profile", "invoices", "communities", "events"])
}

export default withRouter(ProfileNavBar)