import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import {actions} from '../../../redux';

import './ChallengeNavBar.scss';

import LoginOrchesterModalContent from '../Modals/contents/Login/LoginOrchesterModalContent';
import UserManager from '../../../Manager/UserManager';
import PlaceholderImage from './PlaceholderImage';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function ChallengeNavBar(props) {

  async function fetchingProfile() {
    try {
      let userRequest = await UserManager.me()
      props.setUser(userRequest.data)
    } catch (err) {
      console.log("Error :", err)
    }
}

  useEffect(() => {
    if (props.isLoggued)
      fetchingProfile()
  }, [props.isLoggued])
  
  return (
    <div className="ChallengeNavBar">
      <div className="NavBar_logoContainer">
          <NavLink exact to="/" className="ChallengeNavBar_logoContainer__logoWrapper"><div className={`ChallengeNavBar_logoContainer__logoWrapper__logo`}/></NavLink>
          <NavLink exact to="/" className="ChallengeNavBar_logoContainer__mixlabWrapper"><div className="NavBar_logoContainer__mixlabWrapper__mixlab">Mixlab</div></NavLink>
      </div>
      <div className="ChallengeNavBar__buttons">
        {props.isLoggued && props.user ? (
          <NavLink to={!props.isProfile ? "/profile" : "/profile/settings"} className={`ChallengeNavBar__content__profileImageRow ${!props.user.imageUrl ? "noImage" : ""}`}>
              {props.user.imageUrl ? (
                <img src={props.user.imageUrl} className="ChallengeNavBar__content__profileImageRow__image" alt=""/>
              ) : (
                <PlaceholderImage surname={props.user.surname}/>
              )}
          </NavLink>
        ) : (
          <button 
            className={`ChallengeNavBar__content__element clickable blue ${isMobile && "text ChallengeNavBar__content__element__mobile"}`}
            onClick={() => props.showModal(<LoginOrchesterModalContent onSubmit={props.onSubmit} noSignUp/>)}
          >
            <Trans>Connexion</Trans>
          </button>
        )}
      </div>
    </div>
  )
}

ChallengeNavBar.propTypes = {
  onLogin: PropTypes.func,
  isProfile: PropTypes.bool
}

ChallengeNavBar.defaultProps = {
  onLogin: (() => {}),
}

export default connect((state) => ({
  user: state.user && state.user.profile,
  isLoggued: state.user && state.user.isLoggued
}), dispatch => ({
  showModal: actions.modal.show.bind(null, dispatch),
  setUser: actions.user.setUser.bind(null, dispatch)
}))(ChallengeNavBar);
