import React from 'react'
import Translation from './Translation';
import { Trans } from 'react-i18next';

const defaultErrorMessage =
  (
    <Trans ns="errors">
      Bravo ! Tu as trouvé une erreur, contacte nous sur <a href={
      "mailto:bug@mixlab.fr?"
      + "subject=Help me landing... "
      + "&body= Salutation Julien et Emeric,%0D%0A%0D%0A"
      + "%0D%0A%0D%0A- Date :%0D%0A" + new Date()
      + "%0D%0A%0D%0A- Remarques :%0D%0A<Vos remarques ;)>"
      }
      >
          bug@mixlab.fr
      </a> pour qu'on corrige le bug ASAP ;)
    </Trans>
  )

function TransformErrorCode(errorCode) {
  let errorStringCode = errorCode
  if (typeof errorStringCode !== 'string')
      errorStringCode = errorCode && errorCode.response && errorCode.response.data && errorCode.response.data.code
  switch (errorStringCode) {
    //Code 
    case "invalid_coupon":
        return Translation.shared.t("errors|Le coupon n'est plus valide");
    case "missing_coupon":
      return Translation.shared.t("errors|L'offre n'existe pas")
    case "expired_coupon":
      return Translation.shared.t("errors|L'offre n'est plus disponible")
    case "missing_plan":
      return Translation.shared.t("errors|L'abonnement pour cette offre n'existe plus")


    // Image upload
    case "UPLOAD001":
        return Translation.shared.t("errors|L'image est trop volumineuse (500Ko maximum)");
    case "UPLOAD002":
        return Translation.shared.t("errors|Mauvais format, seul les jpeg, jpg, png, webp ou gif sont acceptés.")
    case "UPLOAD003":
        return Translation.shared.t("errors|Aucune image n'a été envoyée.")
    case "error_compression":
        return Translation.shared.t("errors|Une erreur s'est produite lors de la compression de l'image")

    // Event
    case "full_event":
      return Translation.shared.t("errors|L'événement est complet")
    case "already_register_event":
      return Translation.shared.t("errors|Tu participes déjà")
    case "event_canceled":
      return Translation.shared.t("errors|L'événement est annulé")
    case "event_finished":
      return Translation.shared.t("errors|L'événement est fini")
    case "event_full":
      return Translation.shared.t("errors|L'événement est complet")
    case "event_not_payable":
      return Translation.shared.t("errors|L'événement n'est pas payant")
    case "event_no_ticket_found":
      return Translation.shared.t("errors|Le ticket selectionné n'existe plus")
    case "event_already_attending":
      return Translation.shared.t("errors|Tu participes déjà")
    case "event_attend_without_payment":
      return Translation.shared.t("errors|Cet événement est maintenant payant, rafraîchi la page pour pouvoir t'inscrire")
    case "event_already_organizer":
      return Translation.shared.t("errors|Tu es déja organisateur")
    case "event_not_found":
      return Translation.shared.t("errors|L'événement n'existe plus")

    // Sign In
    case "not_found_sign_in_user":
      return Translation.shared.t("errors|Mot de passe ou mail incorrect")
    case "not_found_email_user":
        return Translation.shared.t("errors|Ce mail n'existe pas")

    // Sign Up
    case "already_signup_user":
      return Translation.shared.t("errors|Cet mail est déjà utilisé, utilise ton mot de passe pour te connecter")

    // User delete
    case "can_t_delete_if_owner":
      return Translation.shared.t("errors|Vous ne pouvez pas supprimer votre compte si vous possédez des communautés. Annulez d'abord vos abonnements et recommencez")
    case "can_t_delete_if_god":
        return Translation.shared.t("errors|Vous ne pouvez pas supprimer votre compte si vous êtes god")

    // Password 
    case "weak_user_password":
      return Translation.shared.t("errors|Ton mot de passe est trop court")

    default: 
      return defaultErrorMessage
  }
}

export default TransformErrorCode