import React from 'react'
import {
  Route
} from "react-router-dom";

import CatchParam from '../../../Helpers/CatchParam';

import ProfilePage from './ProfilePage';
import LoginMagicPage from './LoginMagicPage';
import ProfileSettingsPage from './ProfileSettingsPage';
import Home from '../Home';
import ProfileInvoicesPage from './ProfileInvoicesPage';
import EventsPage from './EventsPage';


function UserRoutes(props) {
  let routes = [
    <Route key="magicLogin" exact path={`/magic/login`} render={(route) => 
      <LoginMagicPage code={CatchParam(route, "code")} redirect={decodeURI(CatchParam(route, "redirect"))}/>
    }/>,
    <Route key="login" exact path={`/login`} render={() => 
        <Home
          showLogin
        />
    }/>
  ]
  if (props.user && props.user.isLoggued)
    routes = routes.concat([
      <Route key="profile" exact path={`/profile`} render={() => 
        <ProfilePage />
      }/>,
      <Route key="settings" exact path="/profile/settings" render={() => 
        <ProfileSettingsPage />
      }/>,
      <Route key="settings" exact path="/profile/events" render={() => 
        <EventsPage />
      }/>,
      <Route key="settings" exact path="/profile/invoices" render={() => 
        <ProfileInvoicesPage />
      }/>,
      <Route key="settingsPassword" exact path="/profile/settings/password" render={() => 
        <ProfileSettingsPage forgetPassword />
      }/>
    ])
  return routes
}

export default UserRoutes