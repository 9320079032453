import React, {useEffect, useState, Fragment} from 'react'
import './EventsPage.scss'
import { connect } from 'react-redux'
import {actions} from '../../../redux'
import {withRouter, NavLink} from 'react-router-dom'

import ChallengeManager from '../../../Manager/ChallengeManager'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import NavBar from '../../Components/Elements/ChallengeNavBar'

import moment from 'moment'
import { Trans } from 'react-i18next'
import ChallengeHourRange from '../Challenge/ChallengeHourRange'
import ProfileNavBar from './ProfileNavBar'

function EventsPage(props) {
  const [isLoadingEvents, setIsLoadingEvents] = useState(true)
  const [events, setEvents] = useState([])

  async function getPublicEvents() {
    setIsLoadingEvents(true)
    try {
      let eventsData = await ChallengeManager.me()
      setEvents(eventsData.data)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingEvents(false)
  }
  
  useEffect(() => {
    getPublicEvents()
    props.redirectOn401(true)
    return () => {
      props.redirectOn401(false)
    }
  }, [])

  return (
    <div className="EventsPage">
      <NavBar 
        isProfile
      />
      <div className="ProfilePage__content xxl-top">
        <ProfileNavBar pageName="events" className="l-bottom"/>
          <div className="ProfilePage__content__eventsWrapper">
            <div className="ProfilePage__content__title"><Trans ns="user_component">Mes événements publics</Trans></div>
            <div className="ProfilePage__content__eventsWrapper__events l-top">
            {(isLoadingEvents) ? (
              <LoadingSpinner mini size="30px" className="l-top"/>
            ) : !(Array.isArray(events) && events.length > 0) ? (
              <div className="ProfilePage__content__empty">
                <Trans ns="user_component">Vous n'avez pas encore d'événements publics de prévus</Trans>
              </div>
            ) : events.map((element, index) => {
              let isAfterEnded = !!(index < events.length - 1 && new Date(events[index + 1].endDate) < new Date())
              let isEnded = new Date(element.endDate) < new Date()
              return (
                <Fragment key={index}>
                  <NavLink to={`/e/${element.options && element.options.public && element.options.public.id}`} className={`ProfilePage__content__eventsWrapper__events__event ${!isAfterEnded && events.length - 1 > index ? "bordered" : ""}`} key={index}>
                    <div className="ProfilePage__content__eventsWrapper__events__event__dateAndLogo">
                      <div className={`ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper ${isEnded ? "ended" : element.canceled ? "canceled" : ""}`}>
                        <div className="ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__day">{moment(element.startDate).format('D')}</div>
                        <div className="ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__month">{String(moment(element.startDate).format('MMM')).slice(0, 3)}</div>
                      </div>
                      <img src={element.community && element.community.imageUrl} alt="" className="ProfilePage__content__eventsWrapper__events__event__dateAndLogo__logo"/>
                    </div>
                    <div className="ProfilePage__content__eventsWrapper__events__event__informations">
                      <div className="ProfilePage__content__eventsWrapper__events__event__informations__communityName">{element.community && element.community.displayName}</div>
                        <ChallengeHourRange
                          date={element.startDate}
                          endDate={element.endDate}
                          className="ProfilePage__content__eventsWrapper__events__event__informations__hour xs-top"
                          noIco
                        />
                      <div className="ProfilePage__content__eventsWrapper__events__event__informations__title xs-top">{element.title}</div>
                    </div>
                    {element.canceled && (
                      <div className="ProfilePage__content__eventsWrapper__events__event__status">
                        <button className="pinkLight" disabled><Trans ns="event_component">Annulé</Trans></button>
                      </div>
                    )}
                  </NavLink>
                </Fragment>
              )
            })}
            </div>
          </div>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default connect((state) => ({
  user: state.user.profile
}), (dispatch) => ({
  setUser: actions.user.setUser.bind(null, dispatch),
  logout: actions.user.logout.bind(null, dispatch),
  redirectOn401: actions.user.redirectOn401.bind(null, dispatch)
}))(withRouter(EventsPage))