import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { actions } from '../../../../../redux'

import "./RegisterOrchesterModalContent.scss"

import SignUpRegisterModalContent from './SignUpChallengeModalContent'
import LoginModalContent from '../Login/LoginModalContent'
import LostPasswordModalContent from '../Login/LostPasswordModalContent'
import PasswordModalContent from '../Login/PasswordModalContent'
import RegisterTopModalContent from './RegisterTopModalContent'
import { Trans } from 'react-i18next'

function RegisterOrchesterModalContent(props) {
  const [pageState, setPage] = useState({page: props.page})

  function onSubmit(emailSent) {
    switch (pageState.page) {
      case "password":
      case "signUp":
      case "login":
      case "lostPassword":
          props.onSubmit(pageState.page, emailSent);
        break;
      default:
        break;
    }
  }

  const topElement = <RegisterTopModalContent challenge={props.challenge} />
  return (
    <div className="RegisterOrchesterModalContent">
      { (pageState.page === "password") ? (
        <PasswordModalContent 
          onSubmit={onSubmit}
          forgot={props.forgot}
          isCreate={!props.forgot}
          topElement={
            <div className="RegisterOrchesterModalContent__password__topElement">
              <Trans ns="login_component">Crée ton mot de passe</Trans>
            </div>
          }
        />
      ) : (pageState.page === "signUp") ? (
        <SignUpRegisterModalContent 
          challengeId={props.challenge.options.public.id}
          onSubmit={onSubmit}
          onLogin={error => setPage({page:"login", error})}
          topElement={topElement}
        />
      ) : (pageState.page === "login") ? (
        <LoginModalContent
          error={pageState.error}
          onSubmit={onSubmit}
          onRegister={error => setPage({page:"signUp", error})}
          onLostPassword={error => setPage({page:"lostPassword", error})}
          topElement={topElement}
        />
      ) : (
        <LostPasswordModalContent
          onSubmit={_=> onSubmit(true)}
          challengeId={props.challenge.options.public.id}
          onLogin={error => setPage({page:"login", error})}
          topElement={topElement}
        />
      )}
    </div>
  )
}

RegisterOrchesterModalContent.propTypes = {
  onSubmit: PropTypes.func,
  forgot: PropTypes.bool,
  page: PropTypes.oneOf(["password", "login", "signUp", undefined]),
  challenge: PropTypes.shape({
    startDate: PropTypes.string,
    options: PropTypes.shape({
      public: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    community: PropTypes.shape({
      imageUrl: PropTypes.string
    })
  })
}

RegisterOrchesterModalContent.defaultProps = {
  onSubmit: (() => {}),
  challenge: {
    options: {
      public:{}
    },
    community: {}
  },
  page: "login"
}

export default connect(null, dispatch => ({
  hideModal: actions.modal.hide.bind(null, dispatch)
}))(RegisterOrchesterModalContent)