import React from 'react'
import {
  Route
} from "react-router-dom";
import {StripeProvider, Elements} from 'react-stripe-elements';

import config from '../../../config.json'

import CatchParam from '../../../Helpers/CatchParam';

import CreateCommunity from './CreateCommunity'
import PlanSelection from './PlanSelection'
import SuccessPage from './Success'
import UserInformations from './UserInformations'
import PaymentInformations from './PaymentInformations'
import CodePage from './CodePage';


export default [
  <Route exact key="pricing" path="/pricing" render={(route) => <PlanSelection keepScroll={route.location && route.location.hash} communityUrl={CatchParam(route, 'communityUrl')}/>}/>,
  <Route exact key="userInformations" path="/userInformations" render={(route) => <UserInformations planId={CatchParam(route, 'plan')} communityUrl={CatchParam(route, 'communityUrl')}/>}/>,
  <Route exact key="code" path="/code" render={() => <CodePage />}/>,
  <Route exact key="offer" path="/offer" render={(route) => <CodePage code={CatchParam(route, 'code')}/>} />,
  <Route exact key="community" path="/community" render={() => <CreateCommunity />}/>,
  <Route exact key="success" path="/success" render={(route) => <SuccessPage subdomain={CatchParam(route, 'subdomain')} code={CatchParam(route, "code")}/>}/>,
  <Route exact key="PaymentInformations" path="/PaymentInformations" render={() => {
    return (
      <StripeProvider apiKey={config.stripe.publicToken}>
          <Elements>
            <PaymentInformations />
          </Elements> 
      </StripeProvider>
    )}}/>
]