import React, {useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import './DeleteConfirmModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'

import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'

import UserManager from '../../../../../Manager/UserManager'
import LoadingSpinner from '../../../Elements/LoadingSpinner'
import { actions } from '../../../../../redux'
import { Trans } from 'react-i18next'

function DeleteConfirmModalContent(props) {
  const [isLoading, setIsLoading] = useState()
  const [errors, setErrors] = useState()

  async function onSubmit(data, force) {
    setIsLoading(true)
    try {
      await UserManager.deleteAccount(data.password)
      props.hideModal()
      props.onSubmit()
    } catch (err) {
      setErrors(TransformErrorCode(err))
    }
    setIsLoading(false)
  }

  return (
    <div className="DeleteConfirmModalContent">
      <TopModalSquare>
        <div className="DeleteConfirmModalContent__TopModalSquare__title"><Trans ns="user_component">Supprimer mon compte</Trans></div>
      </TopModalSquare>
      <div className="DeleteConfirmModalContent__content">
        <div className="DeleteConfirmModalContent__content__sentence s-top">
          <Trans ns="user_component">La suppression de ton compte Mixlab entrainera la perte de tes informations dans toutes tes communautés.</Trans><br/>
          <br/>
          <Trans ns="user_component">Es-tu sûr de vouloir supprimer ton compte ?</Trans>
        </div>
        {(errors) ? (
          <div className="PasswordModalContent__content__errors l-top clickable" onClick={() => setErrors()}>{errors}</div>
        ) : (
          <div className="PasswordModalContent__content__actions l-top">
          {(isLoading) ? (
            <LoadingSpinner 
            size="40px"
            small
            />
          ) : (
            <Fragment>
              <button className="pinkLight bordered" onClick={onSubmit}><Trans ns="user_component">Oui, supprimez tout !</Trans></button>
              <button className="grey text m-top" onClick={props.hideModal}><Trans ns="user_component">Non, je veux le garder</Trans></button>
            </Fragment>
          )}
        </div>
        )}
      </div>
    </div>
  )
}

DeleteConfirmModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onRegister: PropTypes.func,
  onLostPassword: PropTypes.func,
  topElement: PropTypes.element,
  error: PropTypes.string
}

DeleteConfirmModalContent.defaultProps = {
  onSubmit: (() => {}),
  onRegister: (() => {}),
  onLostPassword: (() => {})
}

export default connect(null, (dispatch) => ({
  hideModal: actions.modal.hide.bind(null, dispatch)
}))(DeleteConfirmModalContent)