/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'
import PageSupport from '../Helpers/PageSupport'

class SkillsManager extends ApiManager {

  static searchSkill(search, page, size, not) {
    return ApiManager.requester('post', `/skills/search${PageSupport(page, size, {not})}`, {
        search
    })
  }
}

export default SkillsManager;