import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import './LostPasswordForm.scss'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import Translation from '../../../Helpers/Translation'
import { Trans } from 'react-i18next'

function LostPasswordForm(props) {
  const {register, handleSubmit} = useForm()
  return (
    <form 
      className="LostPasswordForm"
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <input
        name="email"
        ref={register}
        type="text"
        autoComplete="email"
        className="LostPasswordForm__input m-bottom"
        placeholder={Translation.shared.t("login_component|Email")}
        onChange={props.onChange.bind(null, "email")}
      />
      <div className={`LostPasswordForm__errors ${props.hasErrors ? "open" : ""}`}>
        {props.errorNode}
      </div>
      {props.isLoading ? (
        <LoadingSpinner 
          size="47px"
          small
        />
      ) : (
        <button className="LostPasswordForm__button green"><Trans ns="login_component">Envoyer</Trans></button>
      )}
    </form>
  )
}

LostPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorNode: PropTypes.node,
  onChange: PropTypes.func,
  hasErrors: PropTypes.bool
}

LostPasswordForm.defaultProps = {
  onSubmit: (() => {}),
  onChange: (() => {}),
}

export default LostPasswordForm