import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import './SuccessPage.scss';
import NavBar from './NavBar'
import RocketImg from '../../../Pictures/illustrations/premium.png'
import handBottom from '../../../Pictures/Emojis/emoji_handbottom.png'
import sunglasses from '../../../Pictures/Emojis/emoji_sunglasses.png'
import ScrollTo from '../../../Helpers/ScrollTo';
import { Trans } from 'react-i18next';

class SuccessPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            community: props.community
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            community: props.community
        })
    }

    UNSAFE_componentWillMount() {
        ScrollTo()
        if (!this.state.community)
            window.location.replace("/pricing")
    }

    render() {
        let baseUrl = `https://${(this.state.community ? this.state.community.name + "." : "")}mixlab.fr`
        return (
            <div id="SuccessPage">
                <NavBar />
                <div className="formBody">
                    <img src={RocketImg} alt="Mixlab rocket success" className="successRocket"/>
                    <h2><Trans ns="community_component">Félicitations !</Trans></h2>
                    <div className="successContainer">
                        <p>
                            <Trans ns="community_component">Votre Mixlab est activé ! Désormais, vous allez pouvoir piloter votre communauté et créer des super ambassadeurs grâce à nos fabuleux outils</Trans> <img src={sunglasses} className="emoji" alt="Mixlab sunglass"/>
                            <br/>
                            <br/>
                            <Trans ns="community_component">Cliquez sur le bouton pour rejoindre votre Mixlab</Trans> <img src={handBottom} className="emoji" alt="Mixlab sunglass"/>
                        </p>
                        <button className="baseButton" onClick={() => {window.open(`${baseUrl}${this.props.code ? `/magic/login?code=${this.props.code}&redirect=${encodeURI(`${baseUrl}/custom`)}` : ""}`)}}>https://{(this.state.community ? this.state.community.name + "." : "")}mixlab.fr</button>
                    </div>
                </div>
                <br/>
                <br/>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        community: state.community && state.community.community
    }
}, (dispatch) => {
    return {}
})(withRouter(SuccessPage));
