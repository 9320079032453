import React, { Fragment, useEffect } from 'react';
import Home from './Home'
import ReactGA from 'react-ga';
import {connect} from 'react-redux'
import {
    Route,
    BrowserRouter,
    Switch,
    withRouter
} from "react-router-dom";

import CGUFile from '../../CGU.txt'

import { CommunityAction } from '../../redux/actions'

import MiniLoadingSpinner from '../../Pictures/gif/loaderMini.gif'

import CGV from './CreateCommunityForm/cgv'
import NotFoundPage from '../Pages/NotFoundPage'
import DefaultModal from '../Components/Modals/DefaultModal';
import ApiDownPage from './Error/ApiDownPage';
import ApiTimeoutPage from './Error/ApiTimeoutPage';
import LoadingPage from './LoadingPage';

import ChallengeRoutes from './Challenge/ChallengeRoutes';
import LoginRoutes from './User/UserRoutes';
import GodRoutes from './God/GodRoutes';
import AdminRoutes from './Admin/AdminRoutes';
import PaiementRoutes from './CreateCommunityForm/PaiementRoutes';

function Landing(props){
    function onChangeRoute(location) {
        ReactGA.pageview(location.pathname);
    }

    useEffect(() => {
        ReactGA.initialize('UA-120217725-2');
        let historyRemoveListener = props.history.listen(onChangeRoute)
        return historyRemoveListener
    }, [])

    if (!props.translationDownloaded)
        return (<LoadingPage />)
    if (props.isTimeoutApi)
        return [<ApiTimeoutPage />]
    if (props.isDownApi)
        return [<ApiDownPage />]
    return (
        <Switch>
            <Route exact key="index" path="/" render={() => <Home />}/>
            <Route exact key="linkedIn" path="/linkedIn" render={() => <Home noAOS={true}/>}/>
            <Route exact key="cgu" path="/cgu" render={() => <CGV textFile={CGUFile}/>}/>
            <Route exact key="cgv" path="/cgv" component={CGV}/>
            {PaiementRoutes}
            {ChallengeRoutes}
            {LoginRoutes({user: props.user})}
            {AdminRoutes({user: props.user})}
            {GodRoutes({user: props.user})}
            <Route exact key="loading" path="/loading" render={() => {return (
                <div id="LoadingPage">
                    <img src={MiniLoadingSpinner} alt="loading"/>
                </div>
            )}}/>
            <Route component={NotFoundPage}/>
        </Switch>
    )
}

const LandingWrapper = connect((state) => ({
    user: state.user,
    isDownApi: state.api && state.api.isDown,
    isTimeoutApi: state.api && state.api.isTimeout,
    translationDownloaded: state.translation && state.translation.isDownloaded
}), (dispatch) => ({
    saveCommunity: CommunityAction.saveCommunity.bind(null, dispatch)
}))(withRouter(Landing));

function RouteContainer(props) {
    return (
        <Fragment>
            {(props.displayModal) ? (<DefaultModal />) : (null)}
            <BrowserRouter>
                <Switch>
                    <LandingWrapper />
                </Switch>
            </BrowserRouter>
        </Fragment>
    )
}

export default connect((state) => ({
    displayModal: state.modal && state.modal.display
}), null)(RouteContainer);
