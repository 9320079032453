import React from 'react'
import {
  Route
} from "react-router-dom";

import CatchParam from '../../../Helpers/CatchParam';

import LoginSocialEvent from '../Admin/LoginSocialEvent';

function AdminRoutes() {
  let routes = [
    <Route key="OAuthSocialLogin" exact path={`/oauth/social/admin/authorization`} render={(route) => 
      <LoginSocialEvent code={CatchParam(route, "code")} state={CatchParam(route, "state")}/>
    }/>
  ]
  return routes
}

export default AdminRoutes