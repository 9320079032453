import React from 'react'
import {
  Route
} from "react-router-dom";

import GodPage from './GodPage'

let baseUrl = '/god'

function GodRoutes(props) {
  if (props.user && props.user.isLoggued)
    return [
      <Route key="home" exact path={`${baseUrl}`} render={() => {
        return <GodPage />
      }}/>
    ]
  return []
}

export default GodRoutes