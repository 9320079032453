import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import { actions } from '../../../redux'
import { withRouter } from 'react-router-dom'

import ChallengeManager from '../../../Manager/ChallengeManager'

import TransformErrorCode from '../../../Helpers/TransformErrorCode'

import ChallengePageContent from './ChallengePageContent'
import NotFoundPage from '../NotFoundPage'
import LoadingPage from '../LoadingPage'

import RegisterOrchesterModalContent from '../../Components/Modals/contents/ChallengeRegister/RegisterOrchesterModalContent'
import MagicLinkEmailSentModalContent from '../../Components/Modals/contents/Login/MagicLinkEmailSentModalContent'
import RegisterTopModalContent from '../../Components/Modals/contents/ChallengeRegister/RegisterTopModalContent'
import AttendingOrchesterModalContent from '../../Components/Modals/contents/ChallengeRegister/AttendingOrchesterModalContent'
import AgendaModalContent from '../../Components/Modals/contents/ChallengeRegister/AgendaModalContent'
import CancelModalContent from '../../Components/Modals/contents/ChallengeRegister/CancelModalContent'
import PasswordUpdateModalContent from '../../Components/Modals/contents/User/PasswordUpdateModalContent'
import ExternalWebSiteModalContent from '../../Components/Modals/contents/ChallengeRegister/ExternalWebSiteModalContent'
import Translation from '../../../Helpers/Translation'
import TicketingModalContent from '../../Components/Modals/contents/ChallengeRegister/TicketingModalContent'
import ChallengeUnregisterModalContent from '../../Components/Modals/contents/ChallengeRegister/ChallengeUnregisterModalContent'
import ChallengeErrorModal from '../../Components/Modals/contents/ChallengeRegister/ChallengeErrorModal'

function displayError(error) {
  return (
    <div className="ChallengePage__error">
      {TransformErrorCode(error.code)}
    </div>
  )
}

function ChallengePage(props) {

  const [challenge, setChallenge] = useState(undefined)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingBottomBar, setIsLoadingBottomBar] = useState(false)
  const [error, setError] = useState(undefined)
  
  function setBottomBarError(error) {
    props.showModal(<ChallengeErrorModal 
                      error={error}
                      onClose={props.hideModal}
                    />)
  }

  async function fetchChallenge(challengeId, loading = true) {
    setLoading(loading)
    let challenge
    try {
      let challengeData = await ChallengeManager.one(challengeId)
      challenge = challengeData.data
      setChallenge(challengeData.data)
    } catch (errorRequest) {
      setError(errorRequest.response && errorRequest.response.data)
    }
    setLoading(false)
    return challenge
  }

  function registerUser(challengeId) {
    if (challenge && Array.isArray(challenge.tickets))
      return props.showModal(<TicketingModalContent
                                  challenge={challenge}
                                  onAttending={(paymentIntentId) => registerUserRequest(challengeId, paymentIntentId)}
                              />, "25em")
    else if (challenge && challenge.options && challenge.options.externalLink)
      return registerExternalUser(challengeId, challenge.options.externalLink)
    else
      return registerUserRequest(challengeId)
  }

  async function registerUserRequest(challengeId, paymentIntentId) {
    setIsLoadingBottomBar(true)
    try {
      await ChallengeManager.register(challengeId, paymentIntentId)
      let challengeData = await fetchChallenge(challengeId, false)
      displaySuccessRegisterModal(challengeData)
    } catch (errorRequest) {
      setBottomBarError(TransformErrorCode(errorRequest))
  }
    setIsLoadingBottomBar(false)
  }

  async function displayUnregisterModal(challenge) {
    props.showModal(<ChallengeUnregisterModalContent 
                        challenge={challenge}
                        isLoading={isLoadingBottomBar}
                        onUnregister={() => {
                          unregisterUser(props.challengeId)
                        }}
                        onClose={props.hideModal}
                    />)
  }

  async function unregisterUser(challengeId) {
    setIsLoadingBottomBar(true)
    try {
      await ChallengeManager.unregister(challengeId)
      await fetchChallenge(challengeId, false)
      props.hideModal()
    } catch (errorRequest) {
      setBottomBarError(TransformErrorCode(errorRequest))
    }
    setIsLoadingBottomBar(false)
  }

  function registerOrchester(challengeId) {
    if (props.user && !props.user.settedPassword)
      return registerUserWithoutPassword(challengeId)
    else if (props.isLoggued)
      registerUser(challengeId)
    else
      return registerUserNotSignIn(challengeId)
  }

  function registerUserWithoutPassword(challengeId) {
    props.showModal(<RegisterOrchesterModalContent 
      challenge={challenge}
      page="password"
      onSubmit={() => {
        registerUser(challengeId)
      }}
    />, null, () => {
      registerUser(challengeId)
    })
  }

  function registerExternalUser(challengeId, externalLinkObject) {
    props.showModal(<ExternalWebSiteModalContent challenge={challenge} onRegister={() => {
                        window.open(externalLinkObject.url, "_blank")
                        registerUserRequest(challengeId)
                    }} externalLink={externalLinkObject}/>)
  }

  async function registerUserNotSignIn(challengeId) {
    let challengeData
    props.showModal(<RegisterOrchesterModalContent 
                        challenge={challenge}
                        page={"signUp"}
                        onSubmit={async (page, emailSent) => {
                          switch(page) {
                            case "signUp":
                              if (emailSent)
                                return props.showModal(<MagicLinkEmailSentModalContent 
                                  topElement={
                                    <RegisterTopModalContent challenge={challenge} />
                                  }
                                />)
                              challengeData = await fetchChallenge(challengeId, false)
                              if (challengeData && challengeData.status === undefined)
                                registerUser(challengeId)
                              else 
                                props.hideModal()
                              break;
                            case "login":
                              challengeData = await fetchChallenge(challengeId, false)
                              if (challengeData && challengeData.status === undefined)
                                registerUser(challengeId)
                              else
                                props.hideModal()
                              break;
                            case "lostPassword":
                                props.showModal(<MagicLinkEmailSentModalContent 
                                  title={Translation.shared.t("login_component|Mail envoyé")}
                                  isPassword
                                />)
                                break;
                            default: 
                              break;
                          }
                        }}
                    />)
  }

  function displaySuccessRegisterModal(challengeData) {
    props.showModal(
      <AttendingOrchesterModalContent 
        challenge={challengeData}
        onCancelAttending={() => displayUnregisterModal(challengeData)}
      />
    )
  }

  function openAgendaModal(challengeData) {
    props.showModal(
      <AgendaModalContent
        topElement={<RegisterTopModalContent challenge={challengeData} />}
        challenge={challengeData}
        onCancelAttending={() => displayUnregisterModal(challengeData)}
      />
    )
  }

  useEffect(() => {
    fetchChallenge(props.challengeId)
    if (window.$crisp)
      window.$crisp.push(['do', 'chat:hide']);
  }, [props.challengeId])

  useEffect(() => {
    if (challenge 
        && props.isLoggued
        && props.user
        && !props.user.settedPassword 
        && props.signup
        && challenge.status === undefined
      )
      props.showModal(<RegisterOrchesterModalContent 
                        challenge={challenge}
                        forgot={props.forgot}
                        page="password"
                        onSubmit={() => {
                          registerUser(props.challengeId)
                        }}
                      />)
    else if (challenge 
      && props.isLoggued
      && props.user
      && props.user.settedPassword
      && props.signup
      && challenge.status === undefined
    ) {
      registerUser(props.challengeId)
      props.history.push(`/e/${props.challengeId}`)
    }
    else if (props.forgot)
      props.showModal(<PasswordUpdateModalContent onSubmit={props.hideModal}/>)         
                        
    if (challenge 
      && props.isLoggued
      && props.user
      && props.cancel 
      && challenge.status === "attending"
    )
      props.showModal(<CancelModalContent 
        topElement={<RegisterTopModalContent challenge={challenge} />}
        challenge={challenge}
        onCancelAttending={_ => unregisterUser(props.challengeId)}
        onClose={_ => props.hideModal()}
      />)
  }, [props.user, challenge, props.isLoggued])

  return (
    <div className="ChallengePage">
      {(isLoading) ? (
        <LoadingPage />
      ) : (error && error.code === "not_found") ? (
        <NotFoundPage />
      ) : (error) ? (
        displayError(error)
      ) : (
        <ChallengePageContent 
          challenge={challenge}
          onClickAgenda={_ => openAgendaModal(challenge)}
          onRegister={() => registerOrchester(props.challengeId)}
          isLoadingBottomBar={isLoadingBottomBar}
          triggerReload={() => fetchChallenge(props.challengeId, false)}
        />
      )}
    </div>
  )
}

export default connect((state) => ({
  isLoggued: state.user && state.user.isLoggued,
  user: state.user && state.user.profile
}), (dispatch) => ({
  showModal: actions.modal.show.bind(null, dispatch),
  hideModal: actions.modal.hide.bind(null, dispatch)
}))(withRouter(ChallengePage))