export const actions = {
  setUser: (dispatch, user) => {
    dispatch(
      {
          type: 'PROFILE',
          items: {
              user
          }
      }
    )
  },
  login: (dispatch, token, refreshToken) => {
      dispatch(
          {
              type: 'LOGIN',
              items: {
                  token: token,
                  refreshToken: refreshToken
              }
          }
      )
  },
  redirectOn401: (dispatch,  isRedirecting) => {
    dispatch(
        {
            type: 'REDIRECT_ON_401',
            items: {
                isRedirecting
            }
        }
    )
    },
  refresh: (dispatch, token, refreshToken) => {
      dispatch(
          {
              type: 'REFRESH_SESSION',
              items: {
                  token: token,
                  refreshToken: refreshToken
              }
          }
      )
  },
  logout: (dispatch) => {
      dispatch({type: 'LOGOUT'})
  }
};

export const reducer = (state = {}, action) => {
let newState = {...state}
switch (action.type) {
  case 'LOGIN':
      newState.isLoggued = true
      newState.token = action.items.token
      newState.refreshToken = action.items.refreshToken
      break;
  case 'PROFILE':
      newState.profile = action.items.user
      break;
  case 'REFRESH_SESSION':
      newState.token = action.items.token
      newState.refreshToken = action.items.refreshToken
      break;
    case 'REDIRECT_ON_401':
        newState.redirectOn401 = action.items.isRedirecting
        break;
  case 'LOGOUT':
      newState.isLoggued = false
      newState.profile = undefined
      newState.token = undefined
      newState.refreshToken = undefined
      if (newState.redirectOn401)
        window.location = "/"
      break;
  default:
      break;
}
return newState
}

export default {
  reducer,
  actions
}