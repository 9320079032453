import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import './ChallengePriceLabel.scss'

import priceIconGrey from '../../../Pictures/icons/icon_ticket_black.png'
import Translation from '../../../Helpers/Translation'
import FormatPrice from '../../../Helpers/FormatPrice'


function ChallengePriceLabel(props) {

  function getPrices(tickets) {
    let lower = undefined
    let higher = undefined
    if (Array.isArray(tickets))
      for (const ticket of tickets) {
        if (ticket.isDeleted) continue
        if (!lower || lower > ticket.price)
          lower = ticket.price
        if (!higher || higher < ticket.price)
          higher = ticket.price
      }
    if (!lower)
      return props.isDisplayingFree ? Translation.shared.t("ticketform_component:Gratuit") : null
    if (lower === higher) 
      return `${FormatPrice(lower)}€`
    return `${FormatPrice(lower)}€ - ${FormatPrice(higher)}€`
  }

  let prices = getPrices(props.tickets)
  if (!prices) return (<Fragment/>)
  return (
    <div className={`ChallengePriceLabel ${props.className || ""} ${props.mini ? "ChallengePriceLabel-mini" : ""}`}>
      <div className="ChallengePriceLabel__price">
        <div className="ChallengePriceLabel__price__image">
          <img src={priceIconGrey} alt="price"/>
        </div>  
        <div>
          {getPrices(props.tickets)}
        </div>
      </div>
    </div>
  )
}

ChallengePriceLabel.propTypes = {
  tickets: PropTypes.array.isRequired
}

export default ChallengePriceLabel