import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'
import TransformErrorCode from '../../../../Helpers/TransformErrorCode'
import UserManager from '../../../../Manager/UserManager'
import LoadingSpinner from '../../../Components/Elements/LoadingSpinner'

import './InvoicesList.scss'

import InvoicesListElement from './InvoicesListElement/InvoicesListElement'

function InvoicesList(props) {
  const size = 10
  const [isLoadingMore, setIsLoadingMore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState()
  const [errorMore, setErrorMore] = useState()
  const [elements, setElements] = useState([])

  async function fetchingInvoices(page) {
    setCurrentPage(page)
    if (page === 1)
      setIsLoading(true)
    else 
      setIsLoadingMore(true)
    try {
      let request = await UserManager.getInvoices(page, size)
      if (request.data) {
        setElements(page === 1 ? request.data.content : [...elements, ...request.data.content])
        setHasMore(request.data.hasMore)
      }
    } catch (err) {
      let errTransformed = TransformErrorCode(err)
      if (page === 1)
        setError(errTransformed)
      else 
        setErrorMore(errTransformed)
    }
    setIsLoading(false)
    setIsLoadingMore(false)
  }

  useEffect(() => {
    fetchingInvoices(currentPage)
  }, [])

  return (
    <div className={`InvoicesList ${props.className}`}>
      {(error) ? (
        <div className="InvoicesList__error">
          {error}
        </div>
      ) : (isLoading) ? (
        <LoadingSpinner mini size="30px" className="l-top"/>
      ) : (!(elements.length > 0)) ? (
        <div className="InvoicesList__empty l-top">
          <Trans ns="income_component">Vous n'avez pas encore de reçu</Trans>
        </div>
      ) : (
        <div className="InvoicesList__list">
          {elements.map((e, index) => <InvoicesListElement key={index} className="s-bottom" element={e}/>)}
          {(errorMore) ? (
            <div className="InvoicesList__list__errorHasMore">
              {errorMore}
            </div>
          ) : (isLoadingMore ? (
            <LoadingSpinner mini size="20px"/>
          ) : hasMore && (
            <div 
              className="InvoicesList__list__hasMore"
              key={elements.length + 1}
              onClick={_=>fetchingInvoices(currentPage + 1)}
            >
              <Trans>Voir plus</Trans>
            </div>
          ))}
        </div>
      )}
      <br/>
      <br/>
    </div>
  )
}

export default InvoicesList