import React from 'react';
import './ChallengeHourRange.scss';

import icoClock from '../../../Pictures/icons/icon_clock.svg'
import moment from 'moment'
import PropsTypes from 'prop-types'
import Translation from '../../../Helpers/Translation';

function ChallengeHourRange(props) {
    var date = moment(props.date)

    function getTimeHTML() {
        if (props.endDate && date) {
            const endDate = moment(props.endDate)
            const hourStart = date.format(Translation.shared.t("dateformat|H[h]"))
            const hourEnd = endDate.format(Translation.shared.t("dateformat|H[h]"))
            const minStart = date.format(Translation.shared.t("dateformat|mm"))
            const minEnd = endDate.format(Translation.shared.t("dateformat|mm"))
            const partStart = (Translation.getLanguage() === "en" && date.format("a")) || ""
            const partEnd = (Translation.getLanguage() === "en" && endDate.format("a")) || ""
            
            return (
                <div className={"ChallengeHourRange__dateContainer " + props.className} >
                    {(!props.noIco) ? (<img src={icoClock} alt="" className="ChallengeHourRange__dateContainer__clockIco"/>) : (null)}
                      <span className="ChallengeHourRange__dateContainer__hours">
                          {hourStart + (parseInt(minStart.replace(":", ""), 10) > 0 ? minStart : partStart)}
                          &nbsp;- {hourEnd + (parseInt(minEnd.replace(":", ""), 10) > 0 ? minEnd : partEnd)}
                      </span>
                </div>
            )
        }
    }

    

  return (
      <div id="ChallengeHourRange" className={props.small ? "small" : ""}>
          {getTimeHTML()}
      </div>
  );
}

ChallengeHourRange.propTypes = {
    date: PropsTypes.string.isRequired,
    endDate: PropsTypes.string,
    noIco: PropsTypes.bool
}

export default ChallengeHourRange;
