import React, {useState, useEffect, useRef} from 'react';
import './TabSelector.scss';
import PropTypes from 'prop-types';

function TabSelector(props) {
    const [tabNumber, _setTabNumber] = useState(0)
    const [isAutoPlay, _setAutoPlay] = useState(!props.noAutoPlay)

    const isAutoPlayRef = useRef(isAutoPlay)
    const setAutoPlay = (value) => {
      isAutoPlayRef.current = value
      _setAutoPlay(value)
    }

    const tabNumberRef = useRef(tabNumber)
    const setTabNumber = (value) => {
      tabNumberRef.current = value
      _setTabNumber(value)
    }


    function onChangeTabNumber(index) {
      setTabNumber(index)
      setAutoPlay(false)
    }

    useEffect(() => {
      let timeout = setInterval(() => {
        if (!isAutoPlayRef.current || !Array.isArray(props.menu)) return
        let nextTabNumber = tabNumberRef.current + 1 < props.menu.length ? tabNumberRef.current + 1 : 0
        setTabNumber(nextTabNumber)
      }, 5000)
      return () => {
        if (timeout)
          clearInterval(timeout)
      }
    }, [])

    return (
        <div className={`TabSelector ${props.className}`} onClick={_=>setAutoPlay(false)}>
            <h2 className="TabSelector__title">{props.title}</h2>
            {Array.isArray(props.menu) && (
              <div className="TabSelector__menu l-top">
                {props.menu.map((element,index)=>(
                  <div key={index} className={`TabSelector__menu__element clickable ${index === tabNumber ? "selected" : ""}`} onClick={_=>onChangeTabNumber(index)}>
                      {element}
                  </div>
                ))}
              </div>
            )}
            {Array.isArray(props.content) && (
              <div className="TabSelector__content">
                {props.content.map((element, index)=>(
                  <div key={index} className={`TabSelector__content__element ${(index === tabNumber ? "visible" : "hidden")}`}>
                      {element}
                  </div>
                ))}
              </div>
            )}
        </div>
    )
}

// TabSelector.defaultProps = {};

TabSelector.propTypes = {
    title: PropTypes.node,
    menu: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.arrayOf(PropTypes.node),
    noAutoPlay: PropTypes.bool
}

export default TabSelector;
