import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { CommunityAction } from '../../../redux/actions'
import './CreateCommunity.scss';
import partenersOffer from '../../../Pictures/illustrations/partenersOffers.png'
import TextField from '../../Components/Forms/TextForm'
import PaymentManager from '../../../Manager/PaymentManager'
import NavBar from './NavBar'
import config from '../../../config.json'
import './CodePage.scss'
import CountDown from '../../Components/Elements/CountDown';
import moment from 'moment'
import PropTypes from 'prop-types'
import 'moment/locale/fr';
import TransformErrorCode from '../../../Helpers/TransformErrorCode';
import LoadingSpinner from '../../Components/Elements/LoadingSpinner';

import rockEmoji from '../../../Pictures/Emojis/emoji_rock.png'
import heartEyesEmoji from '../../../Pictures/Emojis/emoji_heart.png'
import sunGlassesEmoji from '../../../Pictures/Emojis/emoji_sunglasses.png'
import rainbowEmoji from '../../../Pictures/Emojis/emoji_rainbow.png'
import hugEmoji from '../../../Pictures/Emojis/emoji_hug.png'
import ScrollTo from '../../../Helpers/ScrollTo';

class CodePage extends Component {

    static get propTypes() {
        return {
            code: PropTypes.string
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            couponCode: props.code,
            coupon: props.coupon,
            plan: props.plan
        }
        moment.locale('fr')
    }

    UNSAFE_componentWillMount() {
        ScrollTo()
        if (this.state.couponCode)
            this.onSubmitCouponCode()
    }

    onChangeCouponCode(element) {
      this.setState({couponCode: element, error: undefined})
    }
 
    async onSubmitCouponCode() {
        if (this.props.coupon) return
        if (!this.state.couponCode) return this.setState({error: "Le code est obligatoire"})
        this.setState({isLoading: true})
        PaymentManager.notifyCoupon(this.state.couponCode).then()
        try {
            let res = await PaymentManager.getCoupon(this.state.couponCode)
            let coupon = res.data
            let metadata = coupon.metadata
            let planCode = coupon.metadata  && coupon.metadata.subscription ? coupon.metadata.subscription : config.basePlanId
            let userEmail = coupon.metadata && coupon.metadata.email ? coupon.metadata.email : undefined
            let planRes = await PaymentManager.getPlan(planCode)
            let plan = planRes.data
            this.props.savePlan(plan)
            this.props.saveCoupon(coupon)
            if (userEmail)
                this.props.saveEmail(userEmail)
            let community = {}
            if (metadata.url)
                community.name = metadata.url
            if (metadata.name)
                community.displayName = metadata.name
            this.props.saveCommunity(community)
            this.setState({coupon, plan, isLoading: false})
        } catch (err) {
            console.log(err)
            this.setState({error: TransformErrorCode(err, "Le code semble ne plus exister"), isLoading: false})
        }
    }

    onNext() {
        this.props.history.push('/community')
    }

    offeredSentence(coupon) {
        let endMonthCountSentence = (coupon.percent_off === 100) ? (
            <Fragment>
                vous est <span className="CodePage__formBody__offersRow__element__text__blue">offert</span> <img src={heartEyesEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab heart"/>
            </Fragment>
        ) : (
            <Fragment>
                est à <span className="CodePage__formBody__offersRow__element__text__blue">-{coupon.percent_off}%</span> <img src={heartEyesEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab heart"/>
            </Fragment>
        )
        if (coupon.duration_in_months > 1)
            endMonthCountSentence = (coupon.percent_off === 100) ? (
                <Fragment>
                    vous sont <span className="CodePage__formBody__offersRow__element__text__blue">offerts</span> <img src={heartEyesEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab heart"/>
                </Fragment>
            ) : (
                <Fragment>
                     sont à <span className="CodePage__formBody__offersRow__element__text__blue">-{coupon.percent_off}%</span> <img src={heartEyesEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab heart"/>
                </Fragment>
            )

        return (
            <Fragment>
                {this.monthCountSentence(coupon)} {endMonthCountSentence}
            </Fragment>
        )
    }

    monthCountSentence(coupon) {
        if (coupon.duration_in_months > 1)
            return `Les ${coupon.duration_in_months} premiers mois`
        return "Le premier mois"
    }

    newPrice(plan, coupon) {
        let parsedFloat = parseFloat((plan.price/100) - ((plan.price/100) * (coupon.percent_off/100)))
        return parsedFloat.toFixed(2).replace(/[.,]00$/, "");
    }

    render() {
        return (
            <div id="CodePage">
                <NavBar />
                    {(this.state.coupon && this.state.plan) ? (
                        <div className="formBody formBody__bigger">
                            <div className="CodePage__formBody__illustration">
                                <img src={partenersOffer} alt="parteners offers" className="CodePage__formBody__illustration__image"/>
                            </div>
                            <div className="CodePage__formBody__countdownRow">
                                <div className="CodePage__formBody__countdownRow__title">
                                    Votre réduction est activée pour le forfait {this.state.plan.name} !
                                </div>
                                {(this.state.coupon.limitDate) ? (
                                    <div className="CodePage__formBody__countdownRow__countdown">
                                        <div className="CodePage__formBody__countdownRow__countdown__text"><CountDown startDate={this.state.coupon.limitDate}/></div>
                                        <div className="CodePage__formBody__countdownRow__countdown__subText">Valable jusqu'au <span className="CodePage__formBody__countdownRow__countdown__subText__limitDate">{moment(this.state.coupon.limitDate).format('D MMMM YYYY')}</span></div>
                                    </div>
                                ) : (null)}
                            </div>
                            <div className="CodePage__formBody__offersRow">
                                {(this.state.coupon && this.state.coupon.metadata && this.state.coupon.metadata.support) &&  (
                                    <Fragment>
                                        <div className="CodePage__formBody__offersRow__line"/>
                                        <div className="CodePage__formBody__offersRow__element">
                                            <div className="CodePage__formBody__offersRow__element__price">
                                                <div className="CodePage__formBody__offersRow__element__price__true">1500<span className="CodePage__formBody__offersRow__element__price__euroSign">€</span></div>
                                                <div className="CodePage__formBody__offersRow__element__price__after">0<span className="CodePage__formBody__offersRow__element__price__euroSign">€</span></div>
                                            </div>
                                            <div className="CodePage__formBody__offersRow__element__text">
                                                L'accompagnement pour lancer votre Mixlab est <span className="CodePage__formBody__offersRow__element__text__blue">offert</span> <img src={rockEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab rock"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                <div className="CodePage__formBody__offersRow__line"/>
                                <div className="CodePage__formBody__offersRow__element">
                                    <div className="CodePage__formBody__offersRow__element__price">
                                        <div className="CodePage__formBody__offersRow__element__price__true">{this.state.plan.price/100}<span className="CodePage__formBody__offersRow__element__price__euroSign">€</span></div>
                                        <div className="CodePage__formBody__offersRow__element__price__after">{this.newPrice(this.state.plan, this.state.coupon)}<span className="CodePage__formBody__offersRow__element__price__euroSign">€</span></div>
                                    </div>
                                    <div className="CodePage__formBody__offersRow__element__text">
                                        {this.offeredSentence(this.state.coupon)}
                                    </div>
                                </div>
                                {(this.state.coupon && this.state.coupon.metadata && this.state.coupon.metadata.features) &&  (
                                    <Fragment>
                                        <div className="CodePage__formBody__offersRow__line"/>
                                        <div className="CodePage__formBody__offersRow__element">
                                            <div className="CodePage__formBody__offersRow__element__price">
                                                <div className="CodePage__formBody__offersRow__element__price__tick" />
                                            </div>
                                            <div className="CodePage__formBody__offersRow__element__text">
                                                Le dashboard et le trombi sont offerts pendant <span className="CodePage__formBody__offersRow__element__text__blue">{this.state.coupon.metadata.features} mois</span> <img src={rainbowEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab rock"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {(this.state.coupon && this.state.coupon.metadata && this.state.coupon.metadata.limitMembers) &&  (
                                    <Fragment>
                                        <div className="CodePage__formBody__offersRow__line"/>
                                        <div className="CodePage__formBody__offersRow__element">
                                            <div className="CodePage__formBody__offersRow__element__price">
                                                <div className="CodePage__formBody__offersRow__element__price__tick" />
                                            </div>
                                            <div className="CodePage__formBody__offersRow__element__text">
                                                Jusqu'à <span className="CodePage__formBody__offersRow__element__text__blue">{this.state.coupon.metadata.limitMembers} membres</span> dans votre communauté <img src={hugEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab hug"/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                <div className="CodePage__formBody__offersRow__line"/>
                                <div className="CodePage__formBody__offersRow__element">
                                    <div className="CodePage__formBody__offersRow__element__price">
                                        <div className="CodePage__formBody__offersRow__element__price__tick" />
                                    </div>
                                    <div className="CodePage__formBody__offersRow__element__text">
                                        L'abonnement est <span className="CodePage__formBody__offersRow__element__text__blue">sans engagement</span> ! <img src={sunGlassesEmoji} className="CodePage__formBody__offersRow__element__text__emoji" alt="emoji mixlab sunglasses"/>
                                    </div>
                                </div>
                                <div className="CodePage__formBody__offersRow__line"/>
                            </div>
                            <div className="CodePage__formBody__buttonRow">
                                <button className="CodePage__formBody__buttonRow__validate" onClick={this.onNext.bind(this)}>Suivant</button>
                            </div>
                        </div>
                    ) : (
                        <div className="formBody formBody__bigger">
                            <div className="CodePage__formBody__illustration">
                                <img src={partenersOffer} alt="parteners offers" className="CodePage__formBody__illustration__image"/>
                            </div>
                            <TextField
                                isOneLine={true}
                                value={this.state.couponCode}
                                onChange={this.onChangeCouponCode.bind(this)}
                                placeholder="Code"
                                className={`CodePage__formBody__textfield ${this.state.couponCodeErrorHighligth ? "error" : ""}`}
                                onSubmit={this.onSubmitCouponCode.bind(this)}
                            />
                            <div className={`CodePage__formBody__errorGlobal ${this.state.error ? "open" : ""}`}>{this.state.error}</div>
                            <div className="CodePage__formBody__buttonRow">
                                {(this.state.isLoading) ? (
                                    <LoadingSpinner 
                                        size="40px"
                                        mini
                                    />
                                ) : (
                                    <button 
                                        className="CodePage__formBody__buttonRow__validate" 
                                        onClick={this.onSubmitCouponCode.bind(this)}
                                    >
                                        Voir l'offre
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <br/>
                    <br/>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        userEmail: state.community && state.community.userEmail,
        plan: state.community && state.community.plan,
        coupon: state.community && state.community.coupon
    }
}, (dispatch) => {
    return {
        saveEmail: CommunityAction.saveUserEmail.bind(null, dispatch),
        saveCommunity: CommunityAction.saveCommunity.bind(null, dispatch),
        savePlan: CommunityAction.savePlan.bind(null, dispatch),
        saveCoupon: CommunityAction.saveCoupon.bind(null, dispatch)
    }
})(withRouter(CodePage));
