import React from 'react'
import PropTypes from 'prop-types'

import './TicketPriceElement.scss'
import FormatPrice from '../../../../../../Helpers/FormatPrice'



function TicketPriceElement(props) {
  return (
    <div 
      className={`TicketPriceElement ${props.isSelected ? "TicketPriceElement-selected" : "clickable"} ${props.className}`}
      onClick={() => props.onClick(props.ticket)}
    >
      <div className="TicketPriceElement__price">
        {FormatPrice(props.ticket.price)}<span className="TicketPriceElement__price__sign">€</span>
      </div>
      <div className="TicketPriceElement__text">
        <div className="TicketPriceElement__text__title">
          {props.ticket.title}
        </div>
        <div className="TicketPriceElement__text__description">
          {props.ticket.description}
        </div>
      </div>
    </div>
  )
}

TicketPriceElement.propTypes = {
  ticket: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
}

TicketPriceElement.defaultProps = {
  onClick: (() => {}),
  ticket: {}
}

export default TicketPriceElement