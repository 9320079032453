import React, { Component } from 'react';
import './TextForm.scss'

class TextForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            placeholder : props.placeholder ? props.placeholder : "",
            onChange: props.onChange ? props.onChange : ((value) => {}),
            onSubmit: props.onSubmit ? props.onSubmit : (() => {}),
            onFocus: props.onFocus ? props.onFocus : (() => {}),
            onBlur: props.onBlur ? props.onBlur : (() => {}),
            numberLines: props.onChange ? props.onChange : 1,
            limit: props.limit ? props.limit : null,
            minHeight: props.minHeight ? props.minHeight : 200,
            height: props.minHeight ? props.minHeight : 200,
            row: props.row ? props.row : 5,
            value:props.value ? props.value : null,
            isOneLine : props.isOneLine ? props.isOneLine : false,
            theme: props.theme ? props.theme : "light", // Can be dark
            className: props.className,
            focusOnDisplay: props.focusOnDisplay,
            disabled:props.disabled
        }
    }

    componentDidMount(){
        //this.onUpdate(this.refs.textform)
        if (this.refs.textformOneLine && this.state.focusOnDisplay)
            this.refs.textformOneLine.focus()
        if (this.refs.textform && this.state.focusOnDisplay)
            this.refs.textform.focus()
    }

    componentWillReceiveProps(props){
        this.setState({
            onChange: props.onChange ? props.onChange : ((value) => {}),
            placeholder : props.placeholder ? props.placeholder : "",
            numberLines: props.onChange ? props.onChange : 1,
            limit: props.limit ? props.limit : null,
            minHeight: props.minHeight ? props.minHeight : 200,
            value: props.value ? props.value : null,
            isOneLine : props.isOneLine ? props.isOneLine : false,
            theme: props.theme ? props.theme : "light", // Can be dark
            className: props.className,
            disabled:props.disabled
        }, () => {
            if (!this.state.value && this.refs.textform) {
                this.refs.textform.value = null
                this.refs.textform.style.height = 'auto';
                this.refs.textform.style.height = (this.refs.textform.scrollHeight + 2) + 'px';
            }
        })
    }

    onUpdate(e){
        if (!e || this.state.validate) {
            return
        }
        if (this.state.limit && e.value.length > this.state.limit) {
            e.value = this.state.value
            return
        }
        if (!e.style) {
            return
        }
        if (this.state.minHeight) {
            e.style.minHeight = this.state.minHeight + 'px';
        }
        e.style.height = 'auto';
        e.style.height = (e.scrollHeight + 2) + 'px';

        this.state.onChange(e.value)
    }

    onKeyDown(event) {
        if (event.key === "Shift")
            this.setState({shiftDown: true})
        if (this.state.shiftDown && event.key === "Enter") {
            this.setState({validate: true})
            return this.state.onSubmit(event)
        }
    }

    onKeyUp(event) {
        if (this.state.shiftDown && event.key === "Enter") {
            this.setState({validate: false})
        }
        if (event.key === "Shift")
            this.setState({shiftDown: false})
    }

  render() {
    return (
        <div id="TextForm">
            { (this.state.isOneLine) ? (
                <input type={this.props.number ? "number" : this.props.type || "text"}
                       className={this.state.theme + " " + this.props.className + " " + (this.state.disabled ? "disabled" :"")}
                       onChange={(e) => {this.state.onChange(e.target.value)}}
                       placeholder={this.state.placeholder}
                       value={this.state.value ? this.state.value : ""}
                       onFocus={this.state.onFocus}
                       onBlur={this.state.onBlur}
                       disabled={this.state.disabled ? true : false}
                       ref={this.props.ref || "textformOneLine"}
                       onKeyPress={event => {
                           if (event.key === 'Enter') {
                               this.state.onSubmit(event)
                           }
                       }}
                />
            ) : (
                <textarea className={this.state.theme + " " + this.props.className}
                          rows={this.state.row}
                          ref="textform"
                          placeholder={this.state.placeholder}
                          style={{minHeight:this.state.minHeight + "px"}}
                          value={this.state.value ? this.state.value : ""}
                          onBlur={this.state.onBlur}
                          onFocus={this.state.onFocus}
                          onChange={(e) => {this.onUpdate(e.target)}}
                          onKeyDown={this.onKeyDown.bind(this)}
                          onKeyUp={this.onKeyUp.bind(this)}
                >
                </textarea>
            )}
        </div>
    );
  }
}

export default TextForm;
