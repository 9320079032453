import React, {useEffect, useState} from 'react'
import './ProfilePage.scss'
import { connect } from 'react-redux'
import {actions} from '../../../redux'
import {withRouter} from 'react-router-dom'

import UserManager from '../../../Manager/UserManager'
import CommunityManager from '../../../Manager/CommunityManager'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import NavBar from '../../Components/Elements/ChallengeNavBar'

import { Trans } from 'react-i18next'
import Translation from '../../../Helpers/Translation'
import ProfileNavBar from './ProfileNavBar'

function ProfilePage(props) {
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(true)
  
  const [communities, setCommunities] = useState([])

  async function getMe() {
    try {
      let userData = await UserManager.me()
      props.setUser(userData.data)
    } catch (err) {
      console.error(err)
    }
  }

  async function getCommunities() {
    setIsLoadingCommunities(true)
    try {
      let communitiesData = await CommunityManager.me()
      setCommunities(communitiesData.data)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingCommunities(false)
  }
  
  useEffect(() => {
    getMe()
    getCommunities()
    props.redirectOn401(true)
    return () => {
      props.redirectOn401(false)
    }
  }, [])

  let userEmail = (props.user && props.user.email) || "mail inconnu"

  return (
    <div className="ProfilePage">
      <NavBar 
        isProfile
      />
      <div className="ProfilePage__content xxl-top">
        <ProfileNavBar pageName="communities" className="l-bottom"/>
        <div className="ProfilePage__content__communitiesWrapper">
          <div className="ProfilePage__content__title s-bottom"><Trans ns="user_component">Mes communautés</Trans></div>
          <div className="ProfilePage__content__subTitle"><Trans ns="user_component">Retrouve toutes tes communautés pour le compte créé avec <b>{{userEmail}}</b></Trans></div>
          <div className="ProfilePage__content__communitiesWrapper__communities l-top">
          {(isLoadingCommunities) ? (
            <LoadingSpinner mini size="30px" className="l-top"/>
          ) : (Array.isArray(communities) && communities.length > 0) ? (
            communities.map((element, index) => {
              return (
                <div key={index} className={`ProfilePage__content__communitiesWrapper__communities__community ${element.isClosed ? "closed" : ""} ${communities.length - 1 > index ? "bordered" : ""}`}>
                  <div className="ProfilePage__content__communitiesWrapper__communities__community__imageWrapper">
                    <img src={element.imageUrl} alt="" onError={(e) => e.target.style.backgroundColor = "rgb(234, 241, 251)"}/>
                    {(!element.isEnroled) && (
                      <div className="ProfilePage__content__communitiesWrapper__communities__community__imageWrapper__notEnroled"/>
                    )}
                  </div>

                  <div className="ProfilePage__content__communitiesWrapper__communities__community__informations">
                    <div className="ProfilePage__content__communitiesWrapper__communities__community__informations__displayName">{element.displayName}</div>
                    <div className="ProfilePage__content__communitiesWrapper__communities__community__informations__url xs-top">{element.name}.mixlab.fr</div>
                    {element.eventCount >= 1 ? (
                      <div className="ProfilePage__content__communitiesWrapper__communities__community__informations__eventCount xs-top">{Translation.shared.t("user_component|communityEventComing", {count:element.eventCount})}</div>
                    ) : (
                      <div className="ProfilePage__content__communitiesWrapper__communities__community__informations__eventCount xs-top"><Trans ns="user_component">Aucuns ateliers à venir</Trans></div>
                    )}
                  </div>
                  <div className="ProfilePage__content__communitiesWrapper__communities__community__join">
                    <a href={element.url}>
                      {(!element.isEnroled) ? (
                        <button className="blue ProfilePage__content__communitiesWrapper__communities__community__buttonDesktop ProfilePage__content__communitiesWrapper__communities__community__join__notEnroled"><Trans ns="user_component">Rejoindre</Trans></button>
                      ): (
                        <button className="blue ProfilePage__content__communitiesWrapper__communities__community__buttonDesktop"><Trans ns="user_component">Accéder</Trans></button>
                      )}
                      <div className="ProfilePage__content__communitiesWrapper__communities__community__buttonMobile"/>
                    </a>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="ProfilePage__content__empty">
              <Trans ns="user_component">Tu n'as pas encore de communautés</Trans>
            </div>
          )}
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default connect((state) => ({
  user: state.user.profile
}), (dispatch) => ({
  setUser: actions.user.setUser.bind(null, dispatch),
  logout: actions.user.logout.bind(null, dispatch),
  redirectOn401: actions.user.redirectOn401.bind(null, dispatch)
}))(withRouter(ProfilePage))