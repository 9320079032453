import React, {useEffect, useState} from 'react'
import './GodPage.scss'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import LoadingSpinner from '../../Components/Elements/LoadingSpinner'
import GodManager from '../../../Manager/GodManager'
import ReactApexChart from 'react-apexcharts'




function GodPage() {
  const [dataRequest, setDataRequest] = useState({isLoading: true})
  let options = {
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      }
    }
  }
  const [series, setSeries] = useState([{
    name: "test data",
    data: []}])
  
  async function fetchingData() {
    setDataRequest({isLoading: true})
    try {
      let res = await GodManager.data()
      setSeries([{
        name: "login history",
        data: res.data.loginData
      }])
    } catch (err) {
      setDataRequest({error: err})
    }
    setDataRequest({isLoading: false})
  }

  useEffect(() => {
    fetchingData()
  }, [])

  return (
    <div className="GodPage">
      {dataRequest.isLoading ? (
        <LoadingSpinner />
      ) : (
        <ReactApexChart options={options} series={series} type="area" height={350} />
      )}
    </div>
  )
}

export default connect(null, null)(withRouter(GodPage))