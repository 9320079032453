import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import './AttendingConfirmedModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'
import TicketPriceElement from './TicketPriceElement/TicketPriceElement'

function AttendingConfirmedModalContent(props) {

  return (
    <div className="AttendingConfirmedModalContent">
      <TopModalSquare>
        {props.topElement}
      </TopModalSquare>
      <div className="AttendingConfirmedModalContent__content">
        <div className="AttendingConfirmedModalContent__content__sentence l-top">
          <Trans ns="event_component">Ta place est reservée !</Trans>
        </div>
        {props.challenge && props.challenge.attending && props.challenge.attending.ticket && (
          <div className="AttendingConfirmedModalContent__content__ticket l-top">
            <TicketPriceElement ticket={props.challenge.attending.ticket} isSelected />
            <div className="AttendingConfirmedModalContent__content__ticket__subtitle s-top">
                <Trans ns="income_component">Le reçu du paiement sera disponible à la fin de l'événement dans ton <a href="/profile/invoices" className="link">profil</a></Trans>
            </div>
          </div>
        )}
        <button 
          className="AttendingConfirmedModalContent__content__button blue l-top"
          onClick={props.onAddToAgenda}
        >
          <Trans ns="event_component">Ajouter à mon calendrier</Trans>
        </button>
        <button 
          className="AttendingConfirmedModalContent__content__button pinkLight bordered s-top"
          onClick={props.onCancelAttending}
        >
          <Trans ns="event_component">Annuler ma participation</Trans>
        </button>
      </div>
    </div>
  )
}

AttendingConfirmedModalContent.propTypes = {
  onAddToAgenda: PropTypes.func,
  onCancelAttending: PropTypes.func,
  topElement: PropTypes.element,
  challenge: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.shape({
      address: PropTypes.string
    }),
    secretInfos: PropTypes.shape({
      details: PropTypes.string
    })
  })
}

AttendingConfirmedModalContent.defaultProps = {
  onAddToAgenda: (() => {}),
  onCancelAttending: (() => {}),
  challenge: {
    address: {
      location: {}
    },
    secretInfos: {}
  }
}

export default AttendingConfirmedModalContent