import React, { Component } from 'react';
import './cgv.scss'

import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import NavBar from './NavBar'
import CGVtxt from '../../../CGV.txt'
import loading from '../../../Pictures/gif/loading.gif'

class CGV extends Component {

    constructor(props) {
        super(props)
        this.state = {
            textFile: props.textFile || CGVtxt
        }
    }

    componentDidMount() {
        this.readTextFile(this.state.textFile);
    }

    readTextFile(file) {
        this.setState({
            isLoading: true
        });
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    var allText = rawFile.responseText;
                    this.setState({
                        text: allText,
                        isLoading: false
                    });
                }
            }
        };
        rawFile.send(null);
    }

    render() {
        return (
            <div id="CGV">
                <NavBar />
                <div className="content">
                    {(this.state.isLoading) ? (
                        <img src={loading} alt="Mixlab loader"/>
                    ) : (
                        <ReactMarkdown source={this.state.text || ""}/>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(CGV);
