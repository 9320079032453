// Translation
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from "i18next-browser-languagedetector"

import { initReactI18next } from "react-i18next";
import { actions } from '../redux'
import packageJSON from '../../package.json'

class Translation {

    static shared = new Translation()

    setStore(store) {
        this.version = 'v' + packageJSON.version
        this.debug = false
        this.store = store
        this.namespaces = [
            "common",
            "errors",
            "dateformat",
            "home_component",
            "introduction_component",
            "footer_component",
            "login_component",
            "pricing_component",
            "community_component",
            "event_component",
            "user_component",
            "income_component"
        ]
    }

    static getLanguage() {
        let splitLanguage = ["fr"]
        let languageFull = i18next.language || window.localStorage.i18nextLng
        if (languageFull)
            splitLanguage = languageFull.split('-')
        return splitLanguage[0]
    }

    setLanguage(language) {
        actions.translation.setDownloaded(this.store.dispatch, false, false)
        this.component.changeLanguage(language, () => actions.translation.setDownloaded(this.store.dispatch, true, false))
    }

    start(store) {
        if (!store)
            throw Error("Need store to translate")
        this.setStore(store)
        i18next
            .use(LanguageDetector)
            .use(initReactI18next)
            .use(Backend)
            .init({
                backend: {
                    backends: [
                        LocalStorageBackend,
                        XHR
                    ],
                    backendOptions: [{
                        versions: {
                            en: this.debug ? 'v' + Math.random() : this.version,
                            fr: this.debug ? 'v' + Math.random()  : this.version
                        },
                        prefix: 'i18next_mixlab_'
                    }, {
                        allowMultiLoading: false,
                        loadPath: process.env.PUBLIC_URL + '/translations/{{lng}}/{{ns}}.json'
                    }]
                },
                load: 'languageOnly',
                fallbackLng: 'en',
                nsSeparator: "|",
                debug: this.debug,
                ns: this.namespaces,
                defaultNS: "common",
                partialBundledLanguages:true,
                keySeparator: false, // we use content as keys
                escapeInterpolation: false,
                interpolation: {
                    escapeValue: false
                },
                preload: ["en", "fr"],
                saveMissing: this.debug,
                missingKeyHandler: ((lng, ns, key, fallbackValue) => {
                    console.log("Missing key :", key)
                })
            }, (err) => {
                if (err) {
                    console.log("Error downloading language :", err)
                    setTimeout(() => {
                        this.setLanguage("en")
                    }, 1000)
                } else
                    actions.translation.setDownloaded(this.store.dispatch, true, false)
            });
        this.component = i18next
        this.t = i18next.t
        this.loadNamespaces = i18next.loadNamespaces
        this.loadNamespaces = this.loadNamespaces.bind(i18next)
        this.t = this.t.bind(i18next)
    }
}

export default Translation