import React from 'react'
import './LanguageDetector.scss'

import flagFR from '../../../../Pictures/flags/flag_french.png'
import flagEN from '../../../../Pictures/flags/flag_english.png'
import Translation from '../../../../Helpers/Translation'

function LanguageDetector() {

  function changeLanguage(language:string):void {
    Translation.shared.setLanguage && Translation.shared.setLanguage(language)
  }

  return (
    <div className="LanguageDetector">
      <div className="LanguageDetector__element">
        <img src={flagFR} alt="french mixlab"  onClick={() => changeLanguage("fr-Fr")}/>
      </div>
      <div className="LanguageDetector__element" onClick={() => changeLanguage("en-EN")}>
        <img src={flagEN} alt="english mixlab"/>
      </div>
    </div>
  )
}

export default LanguageDetector