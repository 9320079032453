import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import './AgendaModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'
import AgendaFiles from '../../../Elements/Agenda/AgendaFiles'
import TicketPriceElement from './TicketPriceElement/TicketPriceElement'

function AgendaModalContent(props) {
  return (
    <div className="AgendaModalContent">
      <TopModalSquare>
        {props.topElement}
      </TopModalSquare>
      <div className="AgendaModalContent__content">
        {props.challenge && props.challenge.attending && props.challenge.attending.ticket && (
          <div className="AttendingConfirmedModalContent__content__ticket xl-top">
            <TicketPriceElement ticket={props.challenge.attending.ticket} isSelected />
            <div className="AttendingConfirmedModalContent__content__ticket__subtitle s-top">
                <Trans ns="income_component">Le reçu du paiement sera disponible à la fin de l'événement dans ton <a href="/profile/invoices" className="link">profil</a></Trans>
            </div>
          </div>
        )}
        <div className={`AgendaModalContent__content__sentence l-top`}>
          <Trans ns="event_component">Ajoute l'événement à ton calendrier</Trans>
        </div>
        <AgendaFiles 
          className="AgendaModalContent__content__agendaModule m-top"
          challenge={props.challenge}
        />
        {props.challenge.status !== "organize" ? (
          <button 
            className="AgendaModalContent__content__button pinkLight bordered l-top"
            onClick={props.onCancelAttending}
          >
            <Trans ns="event_component">Annuler ma participation</Trans>
          </button>
        ) : (null)}
        {props.canGoBack && (
          <button 
            className="AgendaModalContent__content__button back s-top"
            onClick={props.onGoBack}
          >
            <Trans>Retour</Trans>
          </button>
        )}
      </div>
    </div>
  )
}

AgendaModalContent.propTypes = {
  canGoBack: PropTypes.bool,
  onGoBack: PropTypes.func,
  topElement: PropTypes.element,
  onCancelAttending: PropTypes.func,
  challenge: PropTypes.shape({
    status: PropTypes.string,
    options: PropTypes.shape({
      public: PropTypes.shape({
        id: PropTypes.string
      })
    })
  })
}

AgendaModalContent.defaultProps = {
  onGoBack: (() => {}),
  onCancelAttending: (() => {}),
  challenge: {
    options: {
      public: {}
    }
  }
}

export default AgendaModalContent