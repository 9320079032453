import React from 'react'
import PropTypes from 'prop-types'

import './CancelModalContent.scss'

import TopModalSquare from '../../../Elements/TopModalSquare'

function CancelModalContent(props) {
  return (
    <div className="CancelModalContent">
      <TopModalSquare>
        {props.topElement}
      </TopModalSquare>
      <div className="CancelModalContent__content">
        <div className="CancelModalContent__content__sentence l-top">
          Souhaites-tu annuler ta participation à cet événement ?
        </div>
        <div className="CancelModalContent__content__title s-top">
          « {props.challenge.title} »
        </div>
        <button 
          className="CancelModalContent__content__button pinkLight bordered l-top"
          onClick={props.onCancelAttending}
        >
          Annuler ma participation
        </button>
        <button 
          className="CancelModalContent__content__button back s-top"
          onClick={props.onClose}
        >
          Non, je veux participer
        </button>
      </div>
    </div>
  )
}

CancelModalContent.propTypes = {
  topElement: PropTypes.element,
  onClose: PropTypes.func,
  onCancelAttending: PropTypes.func,
  challenge: PropTypes.shape({
    title: PropTypes.string
  })
}

CancelModalContent.defaultProps = {
  onClose: (() => {}),
  onCancelAttending: (() => {}),
  challenge: {}
}

export default CancelModalContent