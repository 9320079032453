import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { actions } from '../../../../../redux'

import './PasswordModalContent.scss'

import PasswordForm from '../../../../Pages/User/PasswordForm'
import TopModalSquare from '../../../Elements/TopModalSquare'

import TransformErrorCode from '../../../../../Helpers/TransformErrorCode'

import UserManager from '../../../../../Manager/UserManager'
import { Trans } from 'react-i18next'
import Translation from '../../../../../Helpers/Translation'

function checkErrors(data, force) {
  let errorsNode = []

  if (!data.password)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Le mot de passe est obligatoire !</Trans></div>)
  else if (data.password && force < 25)
    errorsNode.push(<div key="passwordError"><Trans ns="errors">Ton mot de passe est trop court</Trans></div>)

  return errorsNode
}


function PasswordModalContent(props) {
  const [errors, setErrors] = useState(props.error ? <div>{props.error}</div> : null)
  const [hasErrors, setHasErrors] = useState(!!(props.error))
  const [isLoading, setIsLoading] = useState()

  function updateUserState() {
    let user = {...props.user}
    if (user) {
      user.settedPassword = true
      props.setUser(user)
    }
  }

  async function onSubmit(data, force) {
    let errorsNode = checkErrors(data, force)
    if (errorsNode.length > 0) {
      setHasErrors(true)
      return setErrors(errorsNode)
    }
    setIsLoading(true)
    try {
      await UserManager.password(data.password)
      updateUserState()
      props.onSubmit()
    } catch (err) {
      setErrors(TransformErrorCode(err))
    }
    setIsLoading(false)
  }

  function onChange() {
    setHasErrors(false)
  }

  return (
    <div className="PasswordModalContent">
      <TopModalSquare>
        {props.topElement}
      </TopModalSquare>
      <div className="PasswordModalContent__content">
        <div className="PasswordModalContent__content__sentence s-top">
          {props.forgot ? (
            Translation.shared.t("login_component|Ton mot de passe sera le même pour toutes tes interactions avec Mixlab !")
          ) : (
            Translation.shared.t("login_component|Inscris-toi aux prochains événements sans remplir tes informations grâce à ton compte Mixlab")
          )}
        </div>
        <div className="PasswordModalContent__content__loginForm l-top">
          <PasswordForm 
            onSubmit={onSubmit.bind(this)}
            hasErrors={hasErrors}
            errorNode={errors}
            onChange={onChange.bind(this)}
            isLoading={isLoading}
            userEmail={props.userEmail}
            isCreate={!props.forgot}
          />
          {!props.forgot && (
            <button onClick={props.onSubmit} className="PasswordModalContent__content__loginForm__ignore s-top grey text">
              <Trans ns="login_component">Ignorer cette étape</Trans>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

PasswordModalContent.propTypes = {
  onSubmit: PropTypes.func,
  onRegister: PropTypes.func,
  onLostPassword: PropTypes.func,
  topElement: PropTypes.element,
  error: PropTypes.string,
  forgot: PropTypes.bool
}

PasswordModalContent.defaultProps = {
  onSubmit: (() => {}),
  onRegister: (() => {}),
  onLostPassword: (() => {})
}

export default connect((state) => ({
  userEmail: state.user && state.user.profile && state.user.profile.email,
  user: state.user && state.user.profile
}), (dispatch) => ({
  setToken: actions.user.login.bind(null, dispatch),
  setUser: actions.user.setUser.bind(null, dispatch)
}))(PasswordModalContent)