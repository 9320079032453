function PasswordForceHelper(element) {
  if (!element) return 0

    switch (element.toLowerCase()) {
        case "12345":
        case "123456":
        case "1234567":
        case "12345678":
        case "123456789":
        case "1234567890":
        case "09876":
        case "098765":
        case "0987654":
        case "09876543":
        case "098765432":
        case "0987654321":
        case "azertyui":
        case "azertyuio":
        case "azertyuiop":
        case "qwertyui":
        case "qwertyuio":
        case "qwertyuiop":
        case "motdepasse":
        case "password":
            return 1
        default:
            break;
    }


    let force = 0
    const passwordLength = element.length
    const numberLength = element.match(/[1-9]/g) ? element.match(/[1-9]/g).length : 0
    const upperLength = element.match(/[A-Z]/g) ? element.match(/[A-Z]/g).length : 0
    const specialLength = element.match(/[^a-z0-9]/ig) ? element.match(/[^a-z0-9]/g).length : 0

    force += (passwordLength * 3.5)
    force += upperLength ? ((passwordLength - (passwordLength - upperLength))*2) : 0
    force += numberLength ? ((passwordLength - (passwordLength - numberLength))*2) : 0
    force += specialLength ? ((passwordLength - (passwordLength - specialLength))*5) : 0

    return force
}

export default PasswordForceHelper