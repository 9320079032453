import React, { Component } from 'react';
import './Introduction.scss';
import mixlabImg from '../../../Pictures/illustrations/illustrationCover.png'
import { Trans } from 'react-i18next';
import Translation from '../../../Helpers/Translation';

class Introduction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.name
        }
    }

    render() {
        return (
            <div id="Introduction">
                <div className="content">
                    <div className="insideContent">
                        <div className="textContainer">
                            <h1 className="text">
                                <Trans ns="introduction_component">Animez votre communauté en toute simplicité</Trans>
                            </h1>
                            <p className="Introduction__content__insideContent__textContainer__subText">
                                <Trans ns="introduction_component">Mixlab est la solution pour créer des <span className="important">événements virtuels</span> et automatiser l'animation de votre communauté</Trans>
                            </p>
                            <a href={Translation.getLanguage() === "fr" ? "https://mixlab.typeform.com/to/bmMzoE" : "https://mixlab.typeform.com/to/H6CAAL"} target="_blank" rel="noopener nofollow noreferrer" className="Introduction__content__insideContent__textContainer__button__link l-top">
                                <button className="Introduction__content__insideContent__textContainer__button blue big">
                                    <Trans>Demander une démo</Trans>
                                </button>
                            </a>
                        </div>
                        <div className="exampleWebSite">
                            <img className="mixlabExample" src={mixlabImg} alt="Mixlab example" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Introduction;
