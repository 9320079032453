/**
 * Created by larry on 02/04/2018.
 */
import ApiManager from './ApiManager'

class GodManager extends ApiManager {

  static data(){
      return ApiManager.requester('get', '/public/god/data')
  }
}

export default GodManager;